<!-- search-box.component.html -->

<div class="input-group">
	<button
		*ngIf="showBackButton"
		class="btn btn-outline chevron-left-btn"
		(click)="handleBackButton()"
	>
		<i class="bi bi-chevron-left"></i>
	</button>
	<input
		type="text"
		(focus)="showDropdown = true"
		(blur)="handleOnBlur()"
		class="form-control input-group input"
		type="text"
		[(ngModel)]="query"
		placeholder="{{ 'search.placeholder' | translate }}"
		(input)="onChangeInput()"
		(keyup)="onKeyUp($event)"
	/>
	<div class="input-group-append">
		<button
			*ngIf="query"
			class="btn btn-outline"
			(click)="handleResetQuery()"
			type="button"
		>
			<i class="bi bi-x"></i>
		</button>
		<button
			class="btn search-btn"
			type="button"
			(click)="handlePressSearchButton()"
		>
			<img src="./assets/icons/search.png" alt="search-icon" />
		</button>
		<div *ngIf="showDropdown" class="dropdown">
			<ng-content></ng-content>
		</div>
	</div>
</div>
