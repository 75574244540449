<div class="container-fluid root-container" role="main">
	<!-- Transplant Journey -->
	<div class="justify-content-start content">
		<div class="col text-center remove-padding">
			<h2 class="title">{{ 'journey.title' | translate }}</h2>

			<app-info-card>
				<span [innerHTML]="getDescription()"></span>
			</app-info-card>
		</div>
	</div>

	<!-- Journey Card -->
	<app-box>
		<div class="content">
			<div class="col" #itemList>
				<ng-container *ngFor="let item of steps; index as i">
					<app-details-step-grid
						[disabledSeparator]="true"
						textButton="{{ item.textButton }}"
						(mouseenter)="onHover(i + 1)"
						(mouseleave)="onHover(-1)"
						title="{{ item.title }}"
						quote="{{ item.quote }}"
						[step]="i + 1"
						[isBig]="true"
						[isComplete]="i + 1 < journeyStage"
						[isCurrent]="getCurrent(i + 1)"
						[currentJourneyStep]="journeyStage === i + 1"
						[isLast]="steps.length - 1 === i"
						imageUrl="{{ item.imageURL }}"
						(pressButton)="item.redirectToPageProps()"
					>
					</app-details-step-grid>
				</ng-container>
			</div>
		</div>
	</app-box>
</div>
