import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { Patient } from '@models/patient';
import { Center } from '@models/center';
import { Registration } from '@models/registration';
import { History } from '@models/history';

@Injectable({
	providedIn: 'root',
})
export class UnosService {
	constructor(private http: HttpClient, private auth: AuthService) {}

	getClientInfo(): Observable<Patient> {
		return this.http.get<Patient>(`${environment.unosApiBase}/patient`, {
			headers: this.auth.getUnosAuth(),
		});
	}

	getCenters(): Observable<Center[]> {
		return this.http.get<Center[]>(
			`${environment.unosApiBase}/transplantCenter`,
			{
				headers: this.auth.getUnosAuth(),
			},
		);
	}

	getCenterById(id: string): Observable<Center> {
		return this.http.get<Center>(
			`${environment.unosApiBase}/transplantCenter/${id}`,
			{
				headers: this.auth.getUnosAuth(),
			},
		);
	}

	getRegistrationById(regId: number): Observable<Registration> {
		return this.http.get<Registration>(
			`${environment.unosApiBase}/registration/${regId}`,
			{
				headers: this.auth.getUnosAuth(),
			},
		);
	}

	getRegistrationHistory(regId: number): Observable<History[]> {
		return this.http.get<History[]>(
			`${environment.unosApiBase}/registration/${regId}/history`,
			{
				headers: this.auth.getUnosAuth(),
			},
		);
	}
}
