<div class="content">
	<div class="icon">
		<img
			src="../../../assets/icons/Total wait time.svg"
			alt="Total Wait Time"
		/>
	</div>
	<div class="info">
		<h4>{{ 'myWaitTime.totalTimeTitle' | translate }}</h4>
		<span>{{ institute }}</span>
		<div class="content-time">
			<div class="row">
				<div class="col text-center remove-padding">
					<h2>{{ years }}</h2>
				</div>
				<div class="col text-center remove-padding"><h2>:</h2></div>
				<div class="col text-center remove-padding">
					<h2>{{ months }}</h2>
				</div>
				<div class="col text-center remove-padding"><h2>:</h2></div>
				<div class="col text-center remove-padding">
					<h2>{{ days }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col text-center remove-padding">
					<h5>{{ 'years' | translate }}</h5>
				</div>
				<div class="col text-center remove-padding"></div>
				<div class="col text-center remove-padding">
					<h5>{{ 'months' | translate }}</h5>
				</div>
				<div class="col text-center remove-padding"></div>
				<div class="col text-center remove-padding">
					<h5>{{ 'days' | translate }}</h5>
				</div>
			</div>
		</div>
	</div>
</div>
