/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Component,
	ViewChild,
	Renderer2,
	ElementRef,
	OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/services/auth.service';
import { AlertsService } from '@services/alerts.service';
import { mainRoutes } from 'src/app/utils/constants';
import { TranslatorService } from '@services/translator.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})

/**
 * Display Header Component
 * @returns Header Component
 */
export class HeaderComponent implements OnInit {
	public isMenuActive = false;
	public isSupportActive = false;
	public isProfileActive = false;
	public profileAvatar = '';
	public notificationsAmount = 0;
	routes = mainRoutes;
	@ViewChild('dropdownDesktop') dropdownDesktop: ElementRef | undefined;

	constructor(
		private router: Router,
		private utilsService: UtilsService,
		private renderer: Renderer2,
		private authService: AuthService,
		private alertService: AlertsService,
		private translator: TranslatorService,
	) {
		this.renderer.listen('window', 'click', (e: Event) => {
			if (
				this.dropdownDesktop &&
				e.target !== this.dropdownDesktop.nativeElement
			) {
				this.isProfileActive = false;
			}
		});
	}

	ngOnInit(): void {
		setTimeout(() => {
			const tempName: any = localStorage.getItem('profileName');
			this.profileAvatar = this.getInitials(tempName);
		}, 500);

		this.alertService.getAlertsAmount().subscribe((newValue: any) => {

			this.notificationsAmount = newValue;
		});
	}

	redirectToPage(url: string) {
		this.router.navigate([url]);
	}

	onTriggerCall(phoneNumber: string): void {
		this.utilsService.onTriggerCall(phoneNumber);
	}

	onSendEmail(emailAddress: string) {
		const mailtoLink = `mailto:${emailAddress}`;
		window.location.href = mailtoLink;
	}

	toggleMenuClass() {
		this.isMenuActive = !this.isMenuActive;
		this.isSupportActive = false;
	}

	toggleSupportClass() {
		this.isSupportActive = !this.isSupportActive;
		this.isMenuActive = false;
	}

	toggleProfile() {
		this.isProfileActive = !this.isProfileActive;
	}

	rotateFakeUser() {
		this.authService.rotateFakeUserId();
		location.reload();
	}

	getInitials(name: any) {
		const words = name.trim().split(' ');
		const initials = [];

		for (let i = 0; i < words.length; i++) {
			if (words[i]) {
				initials.push(words[i][0].toUpperCase());
			}
		}

		return initials.join('');
	}
	getTranslationLabel() {
		return this.translator.get(this.translator.getNextLng());
	}
	changeLang() {
		return this.translator.changeLang();
	}

	logout() {
		this.authService.logout().then(() => {
			this.router.navigate(['login']);
		});
	}
}
