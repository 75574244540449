import { Component } from '@angular/core';
import { mainRoutes } from 'src/app/utils/constants';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})

/**
 * Display NavBar Component
 * @returns NavBar Component
 */
export class NavbarComponent {
	routes = mainRoutes;
}
