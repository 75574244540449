/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';
import { TranslatorService } from '@services/translator.service';
import { UtilsService } from '@services/utils.service';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.component.html',
	styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
	public registerForm: FormGroup;

	constructor(
		public translator: TranslatorService,
		private state: OnboardingService,
		private utilsService: UtilsService,
	) {
		this.registerForm = new FormGroup({
			username: new FormControl('AnnaBanana92', [Validators.required, Validators.minLength(8)]),
			password: new FormControl('Unos2023!', [Validators.required]),
			confirmPassword: new FormControl('Unos2023!', [Validators.required]),
		});
	}

	get inputUsername() {
		return this.registerForm.get('username');
	}

	get inputPassword() {
		return this.registerForm.get('password');
	}

	get inputConfirmPassword() {
		return this.registerForm.get('confirmPassword');
	}

	validatePassword() {
		const pass = this.registerForm.get('password')?.value;

		return this.utilsService.validPassword(pass);
	}

	confirmPassword() {
    const pass = this.registerForm.get('password')?.value;
    const passConfirm = this.registerForm.get('confirmPassword')?.value;

    if(pass && (pass === passConfirm)) {
      return true;
    } else {
      return false;
    }
  }

	// TODO: Pending implementation of validator in form group
	passwordsMatch(pass1: string, pass2: string) {
    return(formGroup: FormGroup) => {
      const passControl: any = formGroup.get(pass1);
      const passConfirmControl: any = formGroup.get(pass2);

      if(passControl.value === passConfirmControl.value) {
        passConfirmControl.setErrors(null);
      } else {
        passConfirmControl.setErrors({noMatch: true});
      }
    }
  }

	navigateToNextPage() {
		this.state.setStep(SCREEN_NAME.STEPS);
	}
	navigateToWelcome() {
		this.state.setStep(SCREEN_NAME.WELCOME);
	}
}
