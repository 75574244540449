/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-nav-card',
	templateUrl: './nav-card.component.html',
	styleUrls: ['./nav-card.component.scss'],
})

/**
 * Display Nav Card Component
 * @param {string} title component title
 * @param {any[]} routes Array of routes to navigate
 * @returns Nav Card Component
 */
export class NavCardComponent {
	@Input() title = '';
	@Input() showNumbers = false;
	@Input() routes: any = [];
	getRoutesLength() {
		return this.routes.length;
	}
	isLastElement(index: number) {
		return this.getRoutesLength() - 1 === index;
	}
}
