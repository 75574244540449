import { Component } from '@angular/core';

@Component({
	selector: 'app-info-card',
	templateUrl: './info-card.component.html',
	styleUrls: ['./info-card.component.scss'],
})

/**
 * Display Info Card Component
 * @param {element} ng-content child elements that the component will contain
 * @returns Info Card Component
 */

export class InfoCardComponent {}
