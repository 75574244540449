import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-onboarding-details-card',
  templateUrl: './onboarding-details-card.component.html',
  styleUrls: ['./onboarding-details-card.component.scss']
})
export class OnboardingDetailsCardComponent {
  @Input() details = '';
}
