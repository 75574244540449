<div
	class="container-fluid relative grid-container"
	[class.remove-padding]="isBig"
>
	<div class="position-relative grid-circle">
		<div class="text-center remove-padding" *ngIf="isBig">
			<div class="vertical-hr relative" [class.complete]="isComplete"></div>
			<app-circular-step
				[isCurrent]="isCurrent"
				[isComplete]="isComplete"
				[isInactive]="isInactive"
				[isBig]="isBig"
				class="removed-circle"
				[showNumber]="showNumber"
				[currentJourneyStep]="currentJourneyStep"
			>
				{{ step }}
			</app-circular-step>
		</div>
	</div>
	<div
		class="text-center remove-padding grid-separator"
		attr.aria-hidden="{{ isLast }}"
		*ngIf="isBig"
	>
		<div class="vertical-hr" [class.complete]="isComplete"></div>
	</div>

	<div class="relative grid-body">
		<div
			class="col text-center relative reduce-left-padding reduce-right-padding grid-content"
		>
			<div class="relative title remove-padding" style="grid-area: title">
				<h2>{{ title | translate }}</h2>
			</div>
			<div
				*ngIf="quote"
				class="relative title remove-padding mt-16"
				style="grid-area: subtitle"
			>
				<span>{{ quote | translate }}</span>
			</div>
			<figure style="grid-area: image; margin: 0 !important" class="d-flex">
				<img
					class="step-image considering-img"
					src="{{ imageUrl }}"
					alt="{{ imageAlt }}"
				/>
			</figure>

			<button
				style="grid-area: button"
				type="button"
				class="btn btn-primary custom-button"
				(click)="handlePressButton()"
			>
				{{ textButton | translate }}
			</button>
		</div>
	</div>
	<div *ngIf="!isLast" class="row footer grid-footer"></div>
</div>
