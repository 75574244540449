import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// Components
import { MyInfoComponent } from './my-info.component';
import { StatusComponent } from './status/status.component';
import { TasksComponent } from './tasks/tasks.component';
import { WaitTimeComponent } from './wait-time/wait-time.component';
import { MedicalHistoryComponent } from './medical-history/medical-history.component';

// Routes
const routes: Routes = [
	{
		path: 'my-info',
		component: MyInfoComponent,
		children: [
			{
				path: '',
				component: StatusComponent,
				data: { breadcrumb: 'myInfo.title' },
			},
			{
				path: 'tasks',
				component: TasksComponent,
				data: { breadcrumb: 'myInfo.myTask' },
			},
			{
				path: 'wait-time',
				component: WaitTimeComponent,
				data: { breadcrumb: 'myInfo.myWaitTime' },
			},
			{
				path: 'medical-history',
				component: MedicalHistoryComponent,
				data: { breadcrumb: 'myInfo.myMedicalHistory' },
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class MyInfoRoutingModule {}
