/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import { UnosService } from '@services/unos.service';
import { Patient } from '@models/patient';
import { Registration } from '@models/registration';
import { Center } from '@models/center';
import { TranslatorService } from '@services/translator.service';
import { myInfoRoutes } from 'src/app/utils/constants';

@Component({
	selector: 'app-medical-history',
	templateUrl: './medical-history.component.html',
	styleUrls: ['./medical-history.component.scss'],
})
export class MedicalHistoryComponent implements OnInit {
	public lastUpdate = '';
	public patient: Patient | undefined;
	public registration: Registration | undefined;
	public center: Center | undefined;
	public myInfoRoutes = myInfoRoutes();

	constructor(
		private utilsService: UtilsService,
		private unosService: UnosService,
		private translator: TranslatorService,
	) {
		translator.addLanguageChangeAction(() => {
			this.getLastUpdatedLabel();
		});
	}

	ngOnInit(): void {
		this.utilsService.enableTooltip();

		this.unosService.getClientInfo().subscribe(patient => {
			this.patient = patient;
			this.patient.firstname = this.utilsService.extractFirstName(patient.name);
			localStorage.setItem('profileName', patient.name);
			// get registration based on patient into
			if (this.patient.registrations.length) {
				const regId = this.utilsService.getRandomArrayValue(
					this.patient.registrations,
				);
				this.unosService.getRegistrationById(regId).subscribe(reg => {
					const tempDate = this.utilsService.formatDate(
						reg.lastUpdatedDate,
						'MM/DD/YY hh:mma [EST]',
					);
					this.lastUpdate = tempDate;
					this.registration = reg;
					// after getting a registration, then we get the center and history
					this.unosService
						.getCenterById(reg.transplantCenterId)
						.subscribe(center => {
							this.center = center;
						});
				});
			}
		});
	}

	onTriggerCall(phoneNumber: string = ''): void {
		this.utilsService.onTriggerCall(phoneNumber);
	}

	cmToFnI(lengthCentimeters: number = 0): string {
		// Convert Centimeters To FeetInches
		const totalInches = lengthCentimeters / 2.54;
		const feet = Math.floor(totalInches / 12);
		const remainingInches = Math.round(totalInches % 12);
		return `${feet} ft ${remainingInches} in`;
	}
	getLastUpdatedLabel() {
		return this.translator.get('lastUpdated', { date: this.lastUpdate });
	}
}
