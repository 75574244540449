<app-onboarding-details-card details="{{ 'onboarding.signup.details' | translate }}"/>

<form *ngIf="registerForm" [formGroup]="registerForm" (ngSubmit)="navigateToNextPage()">
	<div class="mb-3">
		<label for="username" class="form-label label">{{ 'onboarding.signup.username.label' | translate }}<span>*</span></label>
		<div class="input-group">
			<input
				type="text"
				class="form-control input"
				id="username"
				formControlName="username"
				placeholder="{{ 'onboarding.signup.username.placeholder' | translate }}"
			>
			<img *ngIf="inputUsername?.valid && (inputUsername?.dirty || inputUsername?.touched)" src="assets/icons/Complete Check Green.png" alt="Correct">
			<span *ngIf="inputUsername?.invalid && (inputUsername?.dirty || inputUsername?.touched)">x</span>
		</div>
	</div>
	
	<div class="mb-3">
		<label for="password" class="form-label label">{{ 'onboarding.signup.password.label' | translate }}<span>*</span></label>
		<div class="input-group">
			<input
				type="password"
				class="form-control input"
				id="password"
				formControlName="password"
				placeholder="{{ 'onboarding.signup.password.placeholder' | translate }}"
			>
			<img *ngIf="validatePassword()" src="assets/icons/Complete Check Green.png" alt="Correct">
			<span *ngIf="!validatePassword()">x</span>
		</div>
	</div>
	
	<div class="mb-3">
		<label for="confirmPassword" class="form-label label">{{ 'onboarding.signup.re-password.label' | translate }}<span>*</span></label>
		<div class="input-group">
			<input
				type="password"
				class="form-control input"
				id="confirmPassword"
				formControlName="confirmPassword"
				placeholder="{{ 'onboarding.signup.re-password.label' | translate }}"
			>
			<img *ngIf="confirmPassword()" src="assets/icons/Complete Check Green.png" alt="Correct">
			<span *ngIf="!confirmPassword()">x</span>
		</div>
	</div>

	<button
		type="submit"
		class="btn btn-lg main-button base-button"
	>
		{{ 'onboarding.signup.button' | translate }}
	</button>
</form>

<button
	type="button"
	class="btn btn-text btn-lg text-button base-button"
	(click)="navigateToWelcome()"
>
	{{ 'cancel' | translate }}
</button>
