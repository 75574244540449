import { Component, OnInit } from '@angular/core';
import Fuse from 'fuse.js';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SearchItemType, SuggestionItemType } from '@models/searchItem';
import { IndexedSearchService } from '@services/indexed-search.service';
import { PaginationService } from '@services/pagination.service';
import { TranslatorService } from '@services/translator.service';
import { stripHtmlTags } from 'src/app/utils/search';

const MIN_SEARCH_RECENT_WORDS = 5;
@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
	query = '';
	showAll = false;
	pressedSearch = false;
	deviceInfo = {
		isMobile: false,
		isDesktop: true,
		isTablet: false,
		isLandscape: false,
	};
	suggestions: Fuse.FuseResult<SuggestionItemType>[] = [];
	constructor(
		private dataService: IndexedSearchService,
		public paginationService: PaginationService<
			Fuse.FuseResult<SearchItemType>
		>,
		private deviceService: DeviceDetectorService,
		private translator: TranslatorService,
	) {
		this.getDevice();
		this.translator.addLanguageChangeAction(() => {
			this.getPagePaginationLabel();
			/**
			 * If the language is changed THEN app updates the search list and perform the previous search but in the new language
			 */
			this.dataService.fetchCMSData().then(() => {
				this.onSearch();
				this.handleTranslationSuggestions();
			});
		});
	}
	private onPressedSearch() {
		this.pressedSearch = true;
	}
	private resetPressedSearch() {
		this.pressedSearch = false;
		this.paginationService.setItems([]);
		this.suggestions = [];
	}
	private getDevice() {
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		/**
		 * the ngx-device-detector detecs an ipad as tablet and desktop so,
		 * we check if the device is tablet then is not a desktop device
		 * */

		const isDesktopDevice = this.deviceService.isDesktop() && !isTablet;
		const isLandscape = this.deviceService.orientation === 'landscape';

		this.deviceInfo = {
			isDesktop: isDesktopDevice,
			isMobile,
			isTablet,
			isLandscape,
		};
	}
	ngOnInit(): void {
		this.dataService.fetchCMSData();
	}
	onChangeQuery(event: string) {
		if (event === '') {
			this.resetPressedSearch();
		}
		this.query = event.trim();
		this.suggestions = this.dataService.searchSuggestion(this.query);
	}
	handleTranslationSuggestions() {
		this.suggestions = this.dataService.searchSuggestion(this.query);
	}
	getFomatedQuery() {
		return this.query.trim();
	}

	onSearch() {
		if (this.getFomatedQuery() === '') {
			this.resetPressedSearch();
			return;
		}
		this.paginationService.setItems(this.dataService.search(this.query));
		this.onPressedSearch();
	}
	getShowButton() {
		return (
			this.dataService.recentSearchList.length > MIN_SEARCH_RECENT_WORDS &&
			!this.showAll
		);
	}

	getRecentSearch() {
		const list = this.dataService.recentSearchList;
		return this.showAll ? list : list.slice(0, MIN_SEARCH_RECENT_WORDS);
	}

	setQuery(newQuery: string) {
		const formatedQuery = newQuery.trim();
		if (formatedQuery === '') {
			this.resetPressedSearch();
			return;
		}
		this.query = formatedQuery;
		this.onPressedSearch();
		this.onSearch();
	}
	onShowAll() {
		this.showAll = true;
	}
	isHTML(content: string): boolean {
		const element = document.createElement('div');
		element.innerHTML = content;
		return element.querySelector('*') !== null;
	}
	openOnNewTab(isExternalLink?: boolean) {
		return isExternalLink ? '_blank' : 'self';
	}
	getCurrentPageItemsLength() {
		return this.paginationService.getCurrentPageItems().length;
	}
	showBackButton() {
		return (
			this.getCurrentPageItemsLength() >= 0 &&
			!!this.query &&
			!this.deviceInfo.isDesktop &&
			this.pressedSearch
		);
	}
	showSearchResult() {
		if (this.deviceInfo.isDesktop) {
			return true;
		}
		return this.getCurrentPageItemsLength() >= 0 && !!this.query;
	}
	onPressBack() {
		this.onChangeQuery('');
	}
	showResultsSection() {
		if (this.deviceInfo.isDesktop) {
			return true;
		}
		return this.pressedSearch && this.getCurrentPageItemsLength() >= 0;
	}
	showRecentSection() {
		if (this.deviceInfo.isDesktop) {
			return true;
		}
		return !this.pressedSearch;
	}
	IsSearchEmpty() {
		return this.pressedSearch && this.getCurrentPageItemsLength() === 0;
	}
	getPagePaginationLabel() {
		return this.translator.get('pagination.page', {
			current: this.paginationService.currentPage,
			total: this.paginationService.totalPages,
		});
	}

	onSelectSuggestion(suggestion: string) {
		this.query = stripHtmlTags(suggestion);
		this.onSearch();
	}
	formatText(text: string) {
		const keywordList = this.query.split(' ').map(keyword => keyword.trim());
		const regex = new RegExp(`(${keywordList.join('|')})`, 'gi');
		const match = text.match(regex);

		if (match) {
			const firstMatchIndex = text.indexOf(match[0]);
			const start = Math.max(0, firstMatchIndex - 100);
			const end = Math.min(
				text.length,
				firstMatchIndex + match[0].length + 100,
			);

			const prefix = start > 0 ? '...' : '';
			const suffix = end < text.length ? '...' : '';

			const highlightedText = text
				.substring(start, end)
				.replace(regex, '<strong>$1</strong>');

			return `${prefix}${highlightedText}${suffix}`;
		} else {
			return text.substring(0, 100);
		}
	}
	formatRecentSearch(text: string) {
		if (text.length > 100) {
			return text.substring(0, 100) + '...';
		}
		return text;
	}
}
