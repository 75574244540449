import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslatorService } from './translator.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(private http: HttpClient, private translator: TranslatorService) {
		this.translator.addLanguageChangeAction(() => {
			location.reload();
		});
	}
	private fakeUserIds = ['1111', '2222', '3333'];

	getUnosAuth(): HttpHeaders {
		const lang = this.translator.getCurrentLang();
		let languageObj = {
			id: 'en-US',
			name: 'en',
		};
		if (lang === 'es') {
			languageObj = {
				id: 'es-ES',
				name: 'es',
			};
		}
		const headers = new HttpHeaders({
			...environment.extraHeaders,
			'X-Authorization': this.getFakeUserId(),
			'accept-language': `${languageObj.id},${languageObj.name};q=0.9`,
		});
		return headers;
	}

	getCmsAuth(): HttpHeaders {
		const authHeader = `Basic ${btoa(
			`${environment.cmsAppUser}:${environment.cmsAppKey}`,
		)}`;
		const headers = new HttpHeaders({
			Authorization: authHeader,
		});
		return headers;
	}

	// get fake user ID
	getFakeUserId(): string {
		const fakeUserIdx = localStorage.getItem('fakeUserIdx') || '';
		const currentIndex = parseInt(fakeUserIdx) || 0;
		return this.fakeUserIds[currentIndex];
	}
	// rotate fake user ID
	rotateFakeUserId(): void {
		const fakeUserIdx = localStorage.getItem('fakeUserIdx') || '';
		const currentIndex = parseInt(fakeUserIdx) || 0;
		const nextIndex = (currentIndex + 1) % this.fakeUserIds.length;
		localStorage.setItem('fakeUserIdx', nextIndex.toString());
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	login(username: string, password: string) {
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				localStorage.setItem('username', username);
				localStorage.setItem('isLogin', JSON.stringify(true));
				this.rotateFakeUserId();

				resolve();
			}, 2000);
		});
	}

	logout() {
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				localStorage.setItem('username', '');
				localStorage.setItem('isLogin', JSON.stringify(false));

				resolve();
			}, 2000);
		});
	}
}
