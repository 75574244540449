/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import { UnosService } from '@services/unos.service';
import { Patient } from '@models/patient';
import { Registration, ChronometerData } from '@models/registration';
import { Center } from '@models/center';
import { History } from '@models/history';
import { myInfoRoutes } from 'src/app/utils/constants';
import { TranslatorService } from '@services/translator.service';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
	selector: 'app-wait-time',
	templateUrl: './wait-time.component.html',
	styleUrls: ['./wait-time.component.scss'],
})
export class WaitTimeComponent implements OnInit {
	public lastUpdate = '';
	public currentPage = 1;
	public pageSize = 4;
	public totalPages = 1;
	public organWithInstitute = 'Transplant Hospital';
	public patient: Patient | undefined;
	public registration: Registration | undefined;
	public center: Center | undefined;
	public history: History[] | undefined;
	public myInfoRoutes = myInfoRoutes();
	public listingTime: ChronometerData = {
		years: '00',
		months: '00',
		days: '00',
		hours: 0,
		minutes: 0,
		seconds: 0,
		milliseconds: 0,
	};
	public startListingDate = '';
	public activeChronometerData = {
		years: '00',
		months: '02',
		days: '11',
	};
	public inactiveChronometerData = {
		years: '00',
		months: '00',
		days: '29',
	};

	constructor(
		private utilsService: UtilsService,
		private unosService: UnosService,
		private translator: TranslatorService,
	) {
		translator.addLanguageChangeAction(() => {
			this.getDescription();
			this.getLastUpdatedLabel();
			this.getLogsStatus();
			this.getTotalPaginationLabel();
			this.getTotalTimeNote();
			this.getTotalPaginationLabel();
			this.getPagePaginationLabel();
		});
	}

	ngOnInit(): void {
		this.unosService.getClientInfo().subscribe(patient => {
			this.patient = patient;
			this.patient.firstname = this.utilsService.extractFirstName(patient.name);
			localStorage.setItem('profileName', patient.name);
			// get registration based on patient into
			if (this.patient.registrations.length) {
				const regId = this.utilsService.getRandomArrayValue(
					this.patient.registrations,
				);
				this.unosService.getRegistrationById(regId).subscribe(reg => {
					const tempDate = this.utilsService.formatDate(
						reg.lastUpdatedDate,
						'MM/DD/YY hh:mma [EST]',
					);
					this.lastUpdate = tempDate;
					this.registration = reg;
					this.listingTime = this.getChronometerData(reg.listingTime);

					// after getting a registration, then we get the center and history
					this.unosService
						.getCenterById(reg.transplantCenterId)
						.subscribe(center => {
							this.center = center;

							this.organWithInstitute = `${reg.organ} - ${center.entireName}`;
						});

					this.unosService.getRegistrationHistory(regId).subscribe(history => {
						this.history = _.orderBy(history, ['updateDate'], ['desc']);
						let activeDays = 0,
							inactiveDays = 0;
						_.map(history, (item: any) => {
							if (item.active) {
								activeDays += this.getChronometerDataBeetweenDates(
									item.updateDate,
								);
							} else {
								inactiveDays += this.getChronometerDataBeetweenDates(
									item.updateDate,
								);
							}
						});

						// TODO: Uncomment when correct updated information is obtained
						// this.activeChronometerData =
						// 	this.getChronometerDataByDays(activeDays);
						// this.inactiveChronometerData =
						// 	this.getChronometerDataByDays(inactiveDays);
						this.totalPages = Math.ceil(
							this.getLogsStatus().length / this.pageSize,
						);
					});
				});
			}
		});
	}

	getLogs() {
		return [
			{
				title: 'active',
				years: this.activeChronometerData.years,
				months: this.activeChronometerData.months,
				days: this.activeChronometerData.days,
			},
			{
				title: 'activeUrg',
				years: '00',
				months: '00',
				days: '15',
			},
			{
				title: 'inactive',
				years: this.inactiveChronometerData.years,
				months: this.inactiveChronometerData.months,
				days: this.inactiveChronometerData.days,
			},
		];
	}

	getLogsStatus() {
		const tempData: any = [];
		this.history?.map((item: any) => {
			const tempDate = this.utilsService.formatDate(
				item.updateDate,
				'MM/DD/YY hh:mma [EST]',
			);
			tempData.push({
				active: item.active,
				status: item.statusDescription,
				reason: item.statusReason,
				lastUpdate: tempDate,
			});
		});
		return tempData;
	}
	getDescription() {
		return this.translator.get('myWaitTime.description');
	}
	getLastUpdatedLabel() {
		return this.translator.get('lastUpdated', { date: this.lastUpdate });
	}
	getTotalTimeNote() {
		return this.translator.get('myWaitTime.totalTimeNote', {
			organ: this.registration?.organ || 'Kidney',
		});
	}
	getTotalPaginationLabel() {
		return this.translator.get('myWaitTime.totalPagination', {
			total: this.getLogsStatus().length,
		});
	}
	getPagePaginationLabel() {
		return this.translator.get('pagination.page', {
			current: this.currentPage,
			total: this.totalPages,
		});
	}

	getChronometerData(timeFormat: any): ChronometerData {
		// Separate the days and the time part
		const [daysPart, timePart] = timeFormat.split('.');
		// Get total duration in days
		const totalDays = parseInt(daysPart, 10);
		// Calculate the start date by subtracting the total duration of days from the current date
		const startDate = moment().subtract(totalDays, 'days');
		// Create a time duration
		const duration = moment.duration(timePart);
		// Get duration components (hours, minutes, seconds, milliseconds)
		const hours = duration.hours();
		const minutes = duration.minutes();
		const seconds = duration.seconds();
		const milliseconds = duration.milliseconds();
		// Calculate years, months and days based on the total duration in days
		const years = Math.floor(totalDays / 365)
			.toString()
			.padStart(2, '0');
		const months = Math.floor((totalDays % 365) / 30)
			.toString()
			.padStart(2, '0');
		const days = ((totalDays % 365) % 30).toString().padStart(2, '0');
		this.startListingDate = startDate.format('YYYY-MM-DD');

		return {
			years,
			months,
			days,
			hours,
			minutes,
			seconds,
			milliseconds,
		};
	}

	getChronometerDataByDays(totalDays: number) {
		// Calculate years, months and days based on the total duration in days
		const years = Math.floor(totalDays / 365)
			.toString()
			.padStart(2, '0');
		const months = Math.floor((totalDays % 365) / 30)
			.toString()
			.padStart(2, '0');
		const days = ((totalDays % 365) % 30).toString().padStart(2, '0');

		return {
			years,
			months,
			days,
		};
	}

	getChronometerDataBeetweenDates(lastUpdate: any) {
		const startDate = moment(this.startListingDate, 'YYYY-MM-DD');
		const endDate = moment(lastUpdate, moment.ISO_8601);
		// Calculate the difference between the dates
		const days = endDate.diff(startDate, 'days');

		return days;
	}
	handlePrevPage() {
		this.currentPage - +1;
	}
	handleNextPage() {
		this.currentPage += 1;
	}
	handleLastPage() {
		this.currentPage = this.totalPages;
	}
	handleFirstPage() {
		this.currentPage = 1;
	}
}
