import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-progress-bar',
	templateUrl: './progress-bar.component.html',
	styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
	@Input() percentage = 0;
	@Input() maxValue = 100;

	getProgressBarWidth(): string {
		return (this.percentage / this.maxValue) * 100 + '%';
	}
}
