<header>
	<i>{{ 'transplantNavigator' | translate }}</i>
</header>
<div class="root">
	<app-welcome *ngIf="isWelcomePage()"></app-welcome>
	<app-language *ngIf="isLanguagePage()"></app-language>
	<app-sign-up *ngIf="isSignUpPage()"></app-sign-up>
	<app-steps *ngIf="isStepPage()"></app-steps>
	<app-complete *ngIf="isFinalPage()"></app-complete>
</div>
<app-footer></app-footer>
