import { Component } from '@angular/core';

@Component({
	selector: 'app-my-info',
	templateUrl: './my-info.component.html',
	styleUrls: ['./my-info.component.css'],
})
export class MyInfoComponent {
	public myInfoRoutes = [
		{
			title: 'My status',
			link: '/my-info',
		},
		{
			title: 'My tasks',
			link: '/my-info/tasks',
		},
		{
			title: 'My wait time',
			link: '/my-info/wait-time',
		},
		{
			title: 'My medical history',
			link: '/my-info/medical-history',
		},
	];
}
