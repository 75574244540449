import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// Components
import { ConnectComponent } from './connect.component';
import { PeerSupportComponent } from './peer-support/peer-support.component';
import { LivingItForwardComponent } from './living-it-forward/living-it-forward.component';
import { ShareYourStoryComponent } from './share-your-story/share-your-story.component';

// Routes
const routes: Routes = [
	{
		path: 'connect',
		component: ConnectComponent,
		children: [
			{
				path: '',
				component: PeerSupportComponent,
				data: { breadcrumb: 'connect.title' },
			},
			{
				path: 'living-it-forward',
				component: LivingItForwardComponent,
				data: { breadcrumb: 'connect.livingForward' },
			},
			{
				path: 'share-your-story',
				component: ShareYourStoryComponent,
				data: { breadcrumb: 'connect.shareYourStory' },
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ConnectRoutingModule {}
