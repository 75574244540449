import { get } from 'lodash';

export const mainRoutes = [
	{
		title: 'myInfo.title',
		alternativeTitle: 'myInfo.alternativeTitle',
		routerLink: '/my-info',
		subRoutes: [
			{
				title: 'myInfo.myStatus',
				alternativeTitle: 'myInfo.myStatusAlternativeTitle',
				routerLink: '/my-info',
			},
			{
				title: 'myInfo.myTask',
				routerLink: '/my-info/tasks',
			},
			{
				title: 'myInfo.myWaitTime',
				routerLink: '/my-info/wait-time',
			},
			{
				title: 'myInfo.myMedicalHistory',
				routerLink: '/my-info/medical-history',
			},
		],
	},
	{
		title: 'learn.title',
		routerLink: '/learn',
		bulletsEnabled: true,
		subRoutes: [
			{
				title: 'learn.journey',
				alternativeTitle: 'journey.alternativeTitle',
				routerLink: '/learn',
			},
			{
				title: 'learn.consideringTransplant',
				routerLink: '/learn/considering-transplant',
			},
			{
				title: 'learn.choosingHospital',
				routerLink: '/learn/choosing-a-hospital',
			},
			{
				title: 'learn.gettingEvaluated',
				routerLink: '/learn/getting-evaluated',
			},
			{
				title: 'learn.waitingOrgan',
				routerLink: '/learn/waiting-for-an-organ-offer',
			},
			{
				title: 'learn.consideringDonor',
				routerLink: '/learn/considering-a-living-donor',
			},
			{
				title: 'learn.acceptingOffer',
				routerLink: '/learn/accepting-an-offer-having-surgery',
			},
			{
				title: 'learn.navigatingLife',
				routerLink: '/learn/navigating-life-post-transplant',
			},
		],
	},
	{
		title: 'connect.title',
		routerLink: '/connect',
		subRoutes: [
			{
				title: 'connect.peerSupport',
				routerLink: '/connect',
			},
			{
				title: 'connect.livingForward',
				routerLink: '/connect/living-it-forward',
			},
			{
				title: 'connect.shareStories',
				routerLink: '/connect/share-your-story',
			},
		],
	},
];

export const alternRoutes = [
	{
		title: 'alerts.title',
		routerLink: '/alerts',
		subRoutes: [
			{
				title: 'alerts.title',
				routerLink: '/alerts',
			},
			{
				title: 'myInfo.title',
				routerLink: '/my-info',
			},
			{
				title: 'learn.title',
				routerLink: '/learn',
			},
			{
				title: 'connect.title',
				routerLink: '/connect',
			},
		],
	},
];

export const myInfoRoutes = () => {
	return mainRoutes[0].subRoutes.map((item, index) => ({
		...item,
		link: item.routerLink,
		exact: index === 0,
	}));
};

export const learRoutes = () => {
	return mainRoutes[1].subRoutes.map((item, index) => ({
		...item,
		link: item.routerLink,
		exact: index === 0,
	}));
};

export const connectRoutes = () => {
	return mainRoutes[2].subRoutes.map((item, index) => ({
		...item,
		link: item.routerLink,
		exact: index === 0,
	}));
};

export const alertsRoutes = () => {
	return alternRoutes[0].subRoutes.map((item, index) => ({
		...item,
		link: item.routerLink,
		exact: index === 0,
	}));
};

export const JOURNEY_IMGS = {
	'considering-transplant':
		'./assets/images/Transplant journey illustrations/Considering transplant.svg',
	'choosing-a-hospital':
		'./assets/images/Transplant journey illustrations/Choosing a hospital.png',
	'getting-evaluated':
		'./assets/images/Transplant journey illustrations/Evaluated.svg',
	'waiting-for-an-organ-offer':
		'./assets/images/Transplant journey illustrations/Waiting.svg',
	'considering-a-living-donor':
		'./assets/images/Transplant journey illustrations/Living Donor.svg',
	'accepting-an-offer-having-surgery':
		'./assets/images/Transplant journey illustrations/Ready.svg',
	'navigating-life-post-transplant':
		'./assets/images/Transplant journey illustrations/Post-transplant.svg',
};

export const learnBreadcrumb = {
	1: 'learn.consideringTransplant',
	2: 'learn.choosingHospital',
	3: 'learn.gettingEvaluated',
	4: 'learn.waitingOrgan',
	5: 'learn.consideringDonor',
	6: 'learn.acceptingOffer',
	7: 'learn.navigatingLife',
};

export const getJourneyImg = (slug: keyof typeof JOURNEY_IMGS) => {
	return get(JOURNEY_IMGS, slug, '');
};
