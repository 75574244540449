import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// Components
import { LearnComponent } from './learn.component';
import { JourneyComponent } from './journey/journey.component';
import { QAndAComponent } from './q-and-a/q-and-a.component';

// Routes
const routes: Routes = [
	{
		path: 'learn',
		component: LearnComponent,
		children: [
			{
				path: '',
				component: JourneyComponent,
				data: { breadcrumb: 'learn.title' },
			},
			{
				path: ':slug',
				component: QAndAComponent,
				data: { breadcrumb: 'Test QnA' },
			},
		],
	},
];

@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class LearnRoutingModule {}
