import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-bell',
  templateUrl: './alert-bell.component.html',
  styleUrls: ['./alert-bell.component.scss']
})
export class AlertBellComponent implements OnInit, OnChanges {
  // Inputs
	@Input() notifications = 0;
	@Input() title = '';
  public activeAlert = false;
  public instantAlert = false;
  public noTitle = false;

  constructor(private router: Router,) {}
  ngOnInit(): void {
    this.noTitle = this.title === '' ? true : false; 
    this.activeAlert = this.notifications === 0 ? false : true;
  }

  ngOnChanges(changes: SimpleChanges): void {
		// If there is a change in the current element, change the class
		if (changes['notifications'] && changes['notifications'].currentValue > 0) {
      this.notifications = changes['notifications'].currentValue;
			this.activeAlert = true;
      this.instantAlert = true;
      setTimeout(() => {
        this.instantAlert = false;
      }, 4000);
		} else {
      this.notifications = 0;
      this.activeAlert = false;
      this.instantAlert = false;
    }
	}

  redirectToPage(url: string) {
		this.router.navigate([url]);
	}
}
