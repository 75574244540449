import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SearchPageComponent } from './search-page.component';
import { SearchComponent } from './search/search.component';

// Routes
const routes: Routes = [
	{
		path: 'search',
		component: SearchPageComponent,
		children: [{ path: '', component: SearchComponent }],
	},
];
@NgModule({
	declarations: [],
	imports: [CommonModule, RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SearchPageRoutingModule {}
