<div class="container-fluid root-container" role="main">
	<div class="row">
		<div class="remove-padding container">
			<h3 class="greeting">
				{{ generateGreeting() | translate }},
				<Span>{{ patient?.firstname }}!</Span>
			</h3>

			<!-- Status Card -->
			<app-card-with-title
				class="status-card"
				title="myInfo.myStatus"
				[subTitle]="getLastUpdatedLabel()"
			>
				<div class="row content">
					<div class="col remove-padding">
						<div class="row">
							<div class="col">
								<img
									class="statusImg"
									[attr.src]="getStatusImg()"
									[attr.alt]="statusDescription"
								/>
							</div>
						</div>

						<div class="row">
							<div class="col status">
								<span [attr.class]="getClass()">
									{{ statusDescription }}
									<button
										type="button"
										class="btn button-tooltip"
										data-bs-toggle="tooltip"
										data-bs-html="true"
										data-bs-placement="top"
										data-bs-custom-class="custom-tooltip"
										[title]="tooltipMessage"
									>
										<img
											src="../../../../assets/icons/Tooltip.svg"
											alt="Tooltip"
										/>
									</button>
								</span>
							</div>
						</div>

						<div class="row text-center">
							<div class="col text-center">
								<span class="info-organ"
									><b>{{
										'myInfo.waitingPool' | translate : { organ: statusDetails }
									}}</b></span
								>
							</div>
						</div>

						<div class="row text-center">
							<div class="col text-center">
								<span class="info-hospital">{{ transplantCenter }}</span>
							</div>
						</div>

						<br />

						<div class="row text-center">
							<div class="col text-center">
								<button
									type="button"
									class="btn btn-primary custom-button"
									(click)="redirectToPage('/my-info/tasks')"
								>
									{{ 'myInfo.takeAction' | translate }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</app-card-with-title>

			<!-- Journey Card -->
			<app-card-with-title
				class="journey-card"
				title="learn.myJourney"
				[subTitle]="getLastUpdatedLabel()"
			>
				<div class="row content">
					<div class="col remove-padding">
						<ng-container *ngFor="let step of getSteps(); let lastItem = last">
							<ng-container
								*ngIf="step.isCurrent; then detailsStep; else simpleStep"
							></ng-container>

							<ng-template #simpleStep>
								<app-simple-step
									[title]="step.title"
									[step]="step.step"
									[isComplete]="step.isComplete"
									[isInactive]="step.isInactive"
									[isCurrent]="step.isCurrent"
									[isLast]="lastItem"
								>
								</app-simple-step>
							</ng-template>

							<ng-template #detailsStep>
								<app-details-step
									[title]="step.title"
									[step]="step.step"
									[currentJourneyStep]="step.isCurrent"
									[isLast]="lastItem"
								>
									<img
										class="step-image"
										[src]="step.details.image"
										[alt]="step.title"
										*ngIf="step.details?.image"
									/>

									<br />

									<button
										type="button"
										class="btn btn-primary custom-button"
										(click)="redirectToPage('/learn')"
										*ngIf="step.details?.textButton"
									>
										{{ step.details.textButton ?? '' | translate }}
									</button>
								</app-details-step>
							</ng-template>
						</ng-container>
					</div>
				</div>
			</app-card-with-title>

			<!-- Nav Card -->
			<app-nav-card
				class="nav-card"
				title="{{ 'myInfo.title' }}"
				[routes]="myInfoRoutes"
			/>
		</div>
	</div>
</div>
