<!-- TODO: Custom Header-->
<app-header *ngIf="!isLogouted"></app-header>
<div *ngIf="!isLogouted" class="body-content">
	<router-outlet></router-outlet>
</div>
<div *ngIf="isLogouted">
	<router-outlet></router-outlet>
</div>

<a href="/onboarding" *ngIf="showOnboardingButton()" class="btn floating-button"
	>Onboarding</a
>
