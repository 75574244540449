import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IntersectionObserverModule } from 'ngx-intersection-observer';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { MyInfoRoutingModule } from './screens/my-info/my-info.routing';
import { LearnRoutingModule } from './screens/learn/learn.routing';
import { ConnectRoutingModule } from './screens/connect/connect.routing';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LearnComponent } from './screens/learn/learn.component';
import { ConnectComponent } from './screens/connect/connect.component';
import { MyInfoComponent } from './screens/my-info/my-info.component';
import { TasksComponent } from './screens/my-info/tasks/tasks.component';
import { StatusComponent } from './screens/my-info/status/status.component';
import { CardComponent } from './components/card/card.component';
import { BoxComponent } from './components/box/box.component';
import { CardWithTitleComponent } from './components/card-with-title/card-with-title.component';
import { CircularStepComponent } from './components/circular-step/circular-step.component';
import { SimpleStepComponent } from './components/simple-step/simple-step.component';
import { DetailsStepComponent } from './components/details-step/details-step.component';
import { NavCardComponent } from './components/nav-card/nav-card.component';
import { TaskComponent } from './components/task/task.component';
import { WaitTimeComponent } from './screens/my-info/wait-time/wait-time.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { TotalWaitTimeComponent } from './components/total-wait-time/total-wait-time.component';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { MedicalHistoryComponent } from './screens/my-info/medical-history/medical-history.component';
import { HistoryCardComponent } from './components/history-card/history-card.component';
import { JourneyComponent } from './screens/learn/journey/journey.component';
import { QAndAComponent } from './screens/learn/q-and-a/q-and-a.component';
import { QnaCardComponent } from './components/qna-card/qna-card.component';
import { PeerSupportComponent } from './screens/connect/peer-support/peer-support.component';
import { LivingItForwardComponent } from './screens/connect/living-it-forward/living-it-forward.component';
import { ShareYourStoryComponent } from './screens/connect/share-your-story/share-your-story.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { LoadingComponent } from './components/loading/loading.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { SearchComponent } from './screens/search-page/search/search.component';
import { SearchPageComponent } from './screens/search-page/search-page.component';
import { DetailsStepGridComponent } from './components/details-step-grid/details-step-grid.component';
import { AlertBellComponent } from './components/alert-bell/alert-bell.component';
import { AlertsComponent } from './screens/alerts/alerts.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { OnboardingComponent } from './screens/onboarding/onboarding.component';
import { OnboardingCardComponent } from './components/onboarding-card/onboarding-card.component';
import { WelcomeComponent } from './screens/onboarding/components/welcome/welcome.component';
import { LanguageComponent } from './screens/onboarding/components/language/language.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { StepsComponent } from './screens/onboarding/components/steps/steps.component';
import { SignUpComponent } from './screens/onboarding/components/sign-up/sign-up.component';
import { OnboardingDetailsCardComponent } from './components/onboarding-details-card/onboarding-details-card.component';
import { LoginComponent } from './screens/login/login.component';
import { SsnFormatDirective } from './ssn-format.directive';
import { CompleteComponent } from './screens/onboarding/components/complete/complete.component';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		NavbarComponent,
		LearnComponent,
		ConnectComponent,
		MyInfoComponent,
		TasksComponent,
		StatusComponent,
		CardComponent,
		BoxComponent,
		CardWithTitleComponent,
		CircularStepComponent,
		SimpleStepComponent,
		DetailsStepComponent,
		NavCardComponent,
		TaskComponent,
		WaitTimeComponent,
		InfoCardComponent,
		TotalWaitTimeComponent,
		StatusCardComponent,
		MedicalHistoryComponent,
		HistoryCardComponent,
		JourneyComponent,
		QAndAComponent,
		QnaCardComponent,
		PeerSupportComponent,
		LivingItForwardComponent,
		ShareYourStoryComponent,
		ContentCardComponent,
		LoadingComponent,
		SearchBoxComponent,
		SearchComponent,
		SearchPageComponent,
		DetailsStepGridComponent,
		AlertBellComponent,
		AlertsComponent,
		PaginationComponent,
		OnboardingComponent,
		OnboardingCardComponent,
		WelcomeComponent,
		LanguageComponent,
		ProgressBarComponent,
		StepsComponent,
		SignUpComponent,
		OnboardingDetailsCardComponent,
		SsnFormatDirective,
		CompleteComponent,
		LoginComponent,
	],
	imports: [
		BrowserModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000',
		}),
		IntersectionObserverModule,
		AppRoutingModule,
		MyInfoRoutingModule,
		LearnRoutingModule,
		ConnectRoutingModule,
		BreadcrumbModule,
		HttpClientModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		YouTubePlayerModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpTranslateLoader,
				deps: [HttpClient],
			},
		}),
		ReactiveFormsModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
export function httpTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
