<div class="root-container">
	<xng-breadcrumb separator=" ">
		<ng-container *xngBreadcrumbItem="let breadcrumb">
			<ng-container>{{ breadcrumb | translate }}</ng-container>
		</ng-container>
	</xng-breadcrumb>
	<div class="container-fluid share-your-story grid-container" role="main">
		<div class="left-section">
			<div class="row">
				<div class="col remove-padding">
					<!-- Peer Support -->
					<div class="row justify-content-start content">
						<div class="col text-center">
							<h2 class="title">{{ 'connect.shareStories' | translate }}</h2>
						</div>
					</div>
					<app-info-card>
						<span [innerHTML]="getDescription()"></span>
					</app-info-card>
				</div>
			</div>
			<br />

			<!-- Posts -->
			<div class="row">
				<div class="col remove-padding">
					<img
						class="connect-image-header"
						src="./assets/images/Stories_Logo.png"
						alt="Share your story"
					/>
					<ng-container *ngFor="let post of posts; index as i">
						<app-content-card
							[headerImg]="post.acf.headerimage"
							[altText]="getTranslationByKey('title', post)"
							[title]="getTranslationByKey('title', post)"
							[description]="getTranslationByKey('description', post)"
							[videoUrl]="post.acf.video_url"
							[cta_one]="getTranslationByKey('cta_one', post)"
							[cta_one_url]="post.acf.cta_one_url"
							[cta_two]="getTranslationByKey('cta_two', post)"
							[cta_two_url]="post.acf.cta_two_url"
						/>
					</ng-container>
				</div>
			</div>

			<br />
		</div>
		<!-- Nav Card -->
		<div class="right-section">
			<app-nav-card title="connect.title" [routes]="peerSupportRoutes" />
		</div>
	</div>
</div>
