<h1>Welcome!</h1>
<h1><i>¡Bienvenido!</i></h1>

<app-onboarding-card
	title="onboarding.welcome.title"
	description="onboarding.welcome.description"
></app-onboarding-card>

<button
	type="button"
	class="btn btn-lg main-button base-button"
	(click)="nextStep()"
>
	{{ 'onboarding.welcome.letsBegin' | translate }}
</button>
<a
	routerLink="/login" 
	type="button"
	class="btn btn-lg outline-button base-button"
>
	{{ 'onboarding.welcome.alreadyAccount' | translate }}
</a>
