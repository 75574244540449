<div class="progress" role="progressbar" aria-label="progress bar container">
	<div
		class="progress-bar"
		role="progressbar"
		aria-label="progress bar"
		[style.width]="getProgressBarWidth()"
		[attr.aria-valuenow]="percentage"
		[attr.aria-valuemin]="0"
		[attr.aria-valuemax]="maxValue"
	></div>
</div>
