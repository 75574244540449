/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CmsService } from '@services/cms.service';
import { ConnectPost } from '@models/connect';
import { connectRoutes } from 'src/app/utils/constants';
import { TranslatorService } from '@services/translator.service';

@Component({
	selector: 'app-peer-support',
	templateUrl: './peer-support.component.html',
	styleUrls: ['./peer-support.component.scss'],
})
export class PeerSupportComponent implements OnInit {
	public posts: ConnectPost[] = [];
	public gridPosts: ConnectPost[] = [];
	public lastPost: ConnectPost | null = null;
	public peerSupportRoutes = connectRoutes();
	isLoading = true;
	fetchError = '';
	fieldsToTranslate = {
		title: 'title',
		description: 'description',
		cta_one: 'cta_one',
		cta_two: 'cta_two',
	};
	constructor(
		private cmsService: CmsService,
		private translator: TranslatorService,
	) {
		const keyToUpdate = Object.values(this.fieldsToTranslate);
		this.translator.addLanguageChangeAction(() => {
			this.getDescription();
		});
		this.posts.map(post => {
			keyToUpdate.map(key => this.getTranslationByKey(key, post));
		});
	}

	ngOnInit(): void {
		this.cmsService.getConnectPosts().subscribe(
			posts => {
				const _post = _.filter(
					posts,
					(item: any) => item.acf.section.slug === 'peer-support',
				);
				this.posts = _post;
				this.setPosts(_post);
				this.isLoading = false;
			},
			(e: Error) => (this.fetchError = e?.message || 'Unexpected Error'),
		);
	}
	getDescription() {
		return this.translator.get('peerSupport.description');
	}
	getTranslationByKey(key: string, post: ConnectPost) {
		const currentLang = this.translator.getCurrentLang();
		return _.get(post.acf, `${key}_${currentLang}`, key) || '';
	}
	setPosts(posts: ConnectPost[]) {
		const directoryText = 'groups directory';
		const _gridPost: ConnectPost[] = [];
		let _lastPost: ConnectPost | null = null;
		posts.forEach(item => {
			if (
				!_lastPost &&
				(item.acf.cta_one_en.includes(directoryText) ||
					item.acf.cta_two_en.includes(directoryText))
			) {
				_lastPost = item;
			} else {
				_gridPost.push(item);
			}
		});
		this.gridPosts = _gridPost;
		this.lastPost = _lastPost;
	}
}
