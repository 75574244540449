<div class="root-container">
	<xng-breadcrumb separator=" ">
		<ng-container *xngBreadcrumbItem="let breadcrumb">
			<ng-container>{{ breadcrumb | translate }}</ng-container>
		</ng-container>
	</xng-breadcrumb>

	<div class="container-fluid" role="main">
		<!-- My Wait Time -->
		<div class="row justify-content-start content">
			<div class="col text-center remove-padding">
				<h2 class="title">{{ 'myInfo.myWaitTime' | translate }}</h2>
				<span class="subTitle">{{ getLastUpdatedLabel() }}</span>

				<br />

				<app-info-card>
					<span [innerHTML]="getDescription()"></span>
				</app-info-card>
			</div>
		</div>

		<br />

		<!-- Total Wait Time -->
		<div class="row">
			<div class="col remove-padding">
				<app-total-wait-time
					[institute]="organWithInstitute"
					years="00"
					months="02"
					days="11"
				/>

				<app-box>
					<div class="total-time-content">
						<h5>{{ 'myWaitTime.totalTime' | translate }}</h5>

						<div class="row header">
							<div class="col-4 remove-padding">
								<span>{{ 'status' | translate }}</span>
							</div>

							<div class="col-2 text-center remove-padding">
								<span>{{ 'years' | translate }}</span>
							</div>

							<div class="col-1 text-center remove-padding"></div>

							<div class="col-2 text-center remove-padding">
								<span>{{ 'months' | translate }}</span>
							</div>

							<div class="col-1 text-center remove-padding"></div>

							<div class="col-2 text-center remove-padding">
								<span>{{ 'days' | translate }}</span>
							</div>
						</div>

						<ng-container *ngFor="let log of getLogs(); index as i">
							<div class="row" [class.gray]="i % 2 === 0">
								<div class="col-4 remove-padding">
									<span>{{ log.title | translate }}</span>
								</div>

								<div class="col-2 text-center remove-padding bold">
									<span>{{ log.years }}</span>
								</div>

								<div class="col-1 text-center remove-padding bold">
									<span>:</span>
								</div>

								<div class="col-2 text-center remove-padding bold">
									<span>{{ log.months }}</span>
								</div>

								<div class="col-1 text-center remove-padding bold">
									<span>:</span>
								</div>

								<div class="col-2 text-center remove-padding bold">
									<span>{{ log.days }}</span>
								</div>
							</div>
						</ng-container>

						<p [innerHTML]="getTotalTimeNote()"></p>
					</div>
				</app-box>
			</div>
		</div>

		<br />

		<!-- My status history -->
		<div class="row justify-content-start content">
			<div class="col text-center">
				<h2 class="title">{{ 'myWaitTime.myStatusHistory' | translate }}</h2>
				<span class="subTitle">{{ getLastUpdatedLabel() }}</span>

				<br />
			</div>
		</div>

		<hr />

		<div class="row">
			<div class="col text-center remove-padding">
				<ng-container
					*ngFor="
						let log of getLogsStatus().slice(
							(currentPage - 1) * pageSize,
							currentPage * pageSize
						)
					"
				>
					<app-status-card
						[active]="log.active"
						[status]="log.status"
						[reason]="log.reason"
						[lastUpdate]="log.lastUpdate"
					/>
				</ng-container>
			</div>
		</div>

		<!-- Pagination -->
		<app-pagination
			*ngIf="totalPages > 1"
			class="remove-padding mb-4"
			[currentPage]="currentPage"
			[totalPages]="totalPages"
			[hasMoreItems]="true"
			[hideTotalResults]="true"
			[paginationInfoLabel]="getPagePaginationLabel()"
			(pressFirstPage)="handleFirstPage()"
			(pressPrevPage)="handlePrevPage()"
			(pressNextPage)="handleNextPage()"
			(pressLastPage)="handleLastPage()"
		></app-pagination>

		<!-- Nav Card -->
		<app-nav-card title="myInfo.title" [routes]="myInfoRoutes" />

		<br />
	</div>
</div>
