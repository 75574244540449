import { SEARCH_TYPE, SearchItemType } from '@models/searchItem';

// TODO: Update description on each element
export const defaultSearchList: SearchItemType[] = [
	{
		title: 'My status',
		href: '/my-info',
		description:
			'Excepturi architecto laudantium consequatur aliquam beatae expedita dolor consequatur ut. Delectus ut pariatur voluptate cumque recusandae. Dolor quo dignissimos tenetur corporis laborum. Quam vel voluptas sint. Quia corrupti et eligendi facilis esse. Qui hic totam distinctio in.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'My status',
			title_es: 'Mi estado',
			description_en:
				'Excepturi architecto laudantium consequatur aliquam beatae expedita dolor consequatur ut. Delectus ut pariatur voluptate cumque recusandae. Dolor quo dignissimos tenetur corporis laborum. Quam vel voluptas sint. Quia corrupti et eligendi facilis esse. Qui hic totam distinctio in.',
			description_es:
				'Excepturi architecto laudantium consequatur aliquam beatae expedita dolor consequatur ut. Delectus ut pariatur voluptate cumque recusandae. Dolor quo dignissimos tenetur corporis laborum. Quam vel voluptas sint. Quia corrupti et eligendi facilis esse. Qui hic totam distinctio in.',
		},
	},
	{
		title: 'My tasks',
		href: '/my-info/tasks',
		description:
			'Sit est eaque cupiditate omnis maiores. Officia esse quibusdam consequatur magni voluptatem et.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'My tasks',
			title_es: 'Mis tareas',
			description_en:
				'Sit est eaque cupiditate omnis maiores. Officia esse quibusdam consequatur magni voluptatem et.',
			description_es: '',
		},
	},
	{
		title: 'My wait time',
		href: '/my-info/wait-time',
		description: 'Distinctio iusto in error. Ut eligendi ipsa atque.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'My wait time',
			title_es: 'Mi tiempo de espera',
			description_en: 'Distinctio iusto in error. Ut eligendi ipsa atque.',
			description_es: 'Distinctio iusto in error. Ut eligendi ipsa atque.',
		},
	},
	{
		title: 'My medical history',
		href: '/my-info/medical-history',
		description:
			'Quae tempore et accusantium non id velit. Voluptates voluptatem sed dolor. Et omnis nisi et est saepe nemo.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'My medical history',
			title_es: 'Mi historial médico',
			description_en:
				'Quae tempore et accusantium non id velit. Voluptates voluptatem sed dolor. Et omnis nisi et est saepe nemo.',
			description_es:
				'Quae tempore et accusantium non id velit. Voluptates voluptatem sed dolor. Et omnis nisi et est saepe nemo.',
		},
	},
	{
		title: 'Living It Forward',
		href: '/connect/living-it-forward',
		description:
			'Repellendus qui at. Voluptatibus similique pariatur tempore maiores ut aspernatur dicta minima voluptas. Optio et recusandae beatae voluptas neque quos et. Incidunt nihil quod rerum quisquam delectus est sed in. Nesciunt voluptas quidem explicabo ut ut.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'Living It Forward',
			title_es: 'Living It Forward',
			description_en:
				'Repellendus qui at. Voluptatibus similique pariatur tempore maiores ut aspernatur dicta minima voluptas. Optio et recusandae beatae voluptas neque quos et. Incidunt nihil quod rerum quisquam delectus est sed in. Nesciunt voluptas quidem explicabo ut ut.',
			description_es:
				'Repellendus qui at. Voluptatibus similique pariatur tempore maiores ut aspernatur dicta minima voluptas. Optio et recusandae beatae voluptas neque quos et. Incidunt nihil quod rerum quisquam delectus est sed in. Nesciunt voluptas quidem explicabo ut ut.',
		},
	},
	{
		title: 'Share stories',
		href: '/connect/share-your-story',
		description:
			'Exercitationem architecto fuga error eaque. Dolorum sed dolorum necessitatibus autem rerum et quod. Et nostrum ducimus illum soluta pariatur nisi error sed.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'Share stories',
			title_es: 'Comparta historias',
			description_en:
				'Exercitationem architecto fuga error eaque. Dolorum sed dolorum necessitatibus autem rerum et quod. Et nostrum ducimus illum soluta pariatur nisi error sed.',
			description_es:
				'Exercitationem architecto fuga error eaque. Dolorum sed dolorum necessitatibus autem rerum et quod. Et nostrum ducimus illum soluta pariatur nisi error sed.',
		},
	},
	{
		title: 'Peer support',
		href: '/connect/',
		description:
			'Accusamus est porro facere. At nisi corporis cumque qui distinctio. Iste exercitationem voluptatem et et sit. Accusantium laborum itaque aut. Earum expedita voluptatibus quaerat cupiditate qui dolor. Omnis est sed.',
		type: SEARCH_TYPE.HARDCODED,
		originalItem: {
			title_en: 'Peer support',
			title_es: 'Apoyo en pares',
			description_en:
				'Accusamus est porro facere. At nisi corporis cumque qui distinctio. Iste exercitationem voluptatem et et sit. Accusantium laborum itaque aut. Earum expedita voluptatibus quaerat cupiditate qui dolor. Omnis est sed.',
			description_es:
				'Accusamus est porro facere. At nisi corporis cumque qui distinctio. Iste exercitationem voluptatem et et sit. Accusantium laborum itaque aut. Earum expedita voluptatibus quaerat cupiditate qui dolor. Omnis est sed.',
		},
	},
];

export const stripHtmlTags = (html: string) => {
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = html;
	return tempDiv.textContent || tempDiv.innerText || '';
};
