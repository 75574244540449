/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilsService } from '@services/utils.service';

let apiLoaded = false;

@Component({
	selector: 'app-content-card',
	templateUrl: './content-card.component.html',
	styleUrls: ['./content-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

/**
 * Display Content Card Component
 * @param {element} ng-content child elements that the component will contain
 * @param {string} headerImg header image content
 * @param {string} img image content
 * @param {string} altText alt text for image
 * @param {string} title title of the content
 * @param {string} description description of the content
 * @param {string} videoUrl video url (optional)
 * @param {string} cta_one text for link button one (optional)
 * @param {string} cta_one_url url for link button two (optional)
 * @param {string} cta_two text for link button one
 * @param {string} cta_two_url url for link button one
 * @returns Content Card Component
 */
export class ContentCardComponent implements OnInit {
	// Inputs Step
	@Input() headerImg = '';
	@Input() img = '';
	@Input() altText = '';
	@Input() title = '';
	@Input() description = '';
	@Input() videoUrl = '';
	@Input() cta_one = '';
	@Input() cta_one_url = '';
	@Input() cta_two = '';
	@Input() cta_two_url = '';
	public localVideoUrl: any = null;
	public isYoutube = false;

	constructor(private utilsService: UtilsService) {}

	ngOnInit() {
		if (this.videoUrl) {
			const isYoutubeUrl = this.utilsService.findText(this.videoUrl, 'youtu');

			if (isYoutubeUrl) {
				const tempVideoID: any = this.extractVideoId(this.videoUrl);
				this.isYoutube = true;
				this.localVideoUrl = tempVideoID;
			} else {
				this.localVideoUrl = this.videoUrl;
			}
		}

		if (!apiLoaded) {
			// This code loads the IFrame Player API code asynchronously, according to the instructions at
			// https://developers.google.com/youtube/iframe_api_reference#Getting_Started
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			document.body.appendChild(tag);
			apiLoaded = true;
		}
	}

	extractVideoId(url: string): string | null {
		const urlParams = new URLSearchParams(url.slice(url.indexOf('?') + 1));
		const videoParam = urlParams.get('v');

		if (videoParam) {
			return videoParam;
		} else {
			const regex = /(?:\/|%3D|v=|vi=)([0-9A-Za-z_-]{11})(?:[%#?&]|$)/;
			const match = url.match(regex);

			if (match && match[1]) {
				return match[1];
			}
		}

		return null;
	}
}
