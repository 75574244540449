import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@services/utils.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public loginForm: FormGroup;
  isLoading = false;

  constructor(
    private router: Router,
    private utilsService: UtilsService,
    private authService: AuthService,
  ) {
    this.loginForm = new FormGroup({
			username: new FormControl('AnnaBanana92', [Validators.required, Validators.minLength(8)]),
			password: new FormControl('Unos2023!', [Validators.required]),
		});
  }

  get inputUsername() {
		return this.loginForm.get('username');
	}

	get inputPassword() {
		return this.loginForm.get('password');
	}

  validatePassword() {
		const pass = this.loginForm.get('password')?.value;

		return this.utilsService.validPassword(pass);
	}

  login() {
    this.isLoading = true;
    this.authService.login(this.loginForm.get('username')?.value, this.loginForm.get('password')?.value)
    .then(() => {
      this.isLoading = false;
      this.router.navigate(['']);
    });
  }
}
