import {
	Component,
	ContentChild,
	AfterContentInit,
	ElementRef,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { TranslatorService } from '@services/translator.service';

@Component({
	selector: 'app-history-card',
	templateUrl: './history-card.component.html',
	styleUrls: ['./history-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

/**
 * Display History Card Component
 * @param {boolean} wide if wide is true, the height of the component will be enlarged
 * @param {string} img image path to display
 * @param {string} altText alt text for image
 * @param {string} title component title
 * @param {string} info component info
 * @param {string} dropdownTitle dropdown title
 * @param {string} dropdownContent dropdown component
 * @returns History Card Component
 */
export class HistoryCardComponent implements AfterContentInit {
	@ContentChild('content') content: ElementRef | undefined;
	@Input() wide = false;
	@Input() img = '';
	@Input() altText = '';
	@Input() title = '';
	@Input() info = '';
	@Input() dropdownTitle = '';
	@Input() dropdownContent = '';
	public hasContent = false;
	constructor(private translator: TranslatorService) {
		translator.addLanguageChangeAction(() => {
			this.getDropdonwTitle();
			this.getDropdownContent();
		});
	}

	ngAfterContentInit() {
		if (
			this.content &&
			this.content.nativeElement.childNodes.trim().length !== 0
		) {
			this.hasContent = true;
		}
	}

	getDropdonwTitle() {
		return this.translator.get(this.dropdownTitle);
	}
	getDropdownContent() {
		return this.translator.get(this.dropdownContent);
	}
}
