import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-onboarding-card',
	templateUrl: './onboarding-card.component.html',
	styleUrls: ['./onboarding-card.component.scss'],
})
export class OnboardingCardComponent {
	@Input() title = '';
	@Input() description = '';
	@Input() hideCard = false;
}
