<div class="accordion-item qna">
	<h2 class="accordion-header" id="h-{{ key }}">
		<div
			class="d-flex align-items-center justify-content-between dropdown-toggle"
			data-bs-toggle="collapse"
			attr.data-bs-target="#{{ key }}"
			aria-expanded="false"
			attr.aria-controls="{{ key }}"
			type="button"
		>
			<p class="m-0">{{ getTranslationByKey(fieldsToTranslate.title) }}</p>
			<span
				class="accordion-button collapsed dropdown-icon"
				data-bs-toggle="collapse"
				attr.data-bs-target="#{{ key }}"
			>
			</span>
		</div>
	</h2>
	<div
		id="{{ key }}"
		class="accordion-collapse collapse dropdown"
		attr.aria-labelledby="h-{{ key }}"
		attr.data-bs-parent="#{{ accordionName }}"
	>
		<div class="accordion-body p-0">
			<ul class="p-0">
				<li
					class="dropdown-item"
					[innerHTML]="getTranslationByKey(fieldsToTranslate.description)"
				></li>
				<li class="dropdown-item text-center" *ngIf="acf?.video_url">
					<vg-player *ngIf="!isYoutube">
						<video
							[vgMedia]="$any(media)"
							#media
							id="singleVideo"
							preload="auto"
							controls
						>
							<source [src]="videoUrl" type="video/mp4" />
						</video>
					</vg-player>
					<youtube-player
						*ngIf="isYoutube"
						[videoId]="videoUrl"
					></youtube-player>
				</li>
				<li
					class="dropdown-item"
					*ngIf="getTranslationByKey(fieldsToTranslate.cta_one)"
				>
					<a
						type="button"
						class="btn btn-secondary custom-button"
						[href]="acf?.cta_one_url"
						target="_blank"
					>
						{{ getTranslationByKey(fieldsToTranslate.cta_one) }}
					</a>
				</li>
				<li
					class="dropdown-item"
					*ngIf="getTranslationByKey(fieldsToTranslate.cta_two)"
				>
					<a
						type="button"
						class="btn btn-secondary custom-button"
						[href]="acf?.cta_two_url"
						target="_blank"
					>
						{{ getTranslationByKey(fieldsToTranslate.cta_two) }}
					</a>
				</li>
			</ul>
		</div>
	</div>
</div>
