import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import Fuse from 'fuse.js';
import * as _ from 'lodash';

import { CmsService } from './cms.service';
import { TranslatorService } from './translator.service';
import { LocalStorageService } from './local-storage.service';

import { ConnectPost } from '@models/connect';
import { LearnQnA } from '@models/learn';
import {
	SEARCH_TYPE,
	SearchItemType,
	SuggestionItemType,
} from '@models/searchItem';
import { defaultSearchList, stripHtmlTags } from '../utils/search';

@Injectable({
	providedIn: 'root',
})
export class IndexedSearchService {
	private lastUpdated = new Date().getTime();

	private persistenSearchList: SearchItemType[] = [];
	private persistenSuggestionList: SuggestionItemType[] = [];

	public recentSearchList: string[] = [];

	private fuseSearch: Fuse<SearchItemType> | undefined;
	private fuseSuggestionSearch: Fuse<SuggestionItemType> | undefined;
	public posts: ConnectPost[] = [];

	constructor(
		private cmsService: CmsService,
		private localStorageService: LocalStorageService,
		private translator: TranslatorService,
	) {
		const list = this.localStorageService.get('recentSearchList') as
			| string[]
			| null;
		if (list) {
			this.recentSearchList = list as string[];
		}

		const lastUpdate =
			this.localStorageService.get('lastUpdated') || new Date().getDate();
		this.lastUpdated = Number(lastUpdate);
	}
	private filterUniqueItems(list: SearchItemType[]): SearchItemType[] {
		const uniqueItems: { [key: string]: boolean } = {}; // Objeto auxiliar para rastrear elementos únicos
		return list.filter(item => {
			if (!uniqueItems[item.description]) {
				uniqueItems[item.description] = true;
				return true;
			}
			return false;
		});
	}
	private filterUniqueSuggestionItems(list: SuggestionItemType[]) {
		const uniqueItems: { [key: string]: boolean } = {}; // Objeto auxiliar para rastrear elementos únicos
		return list.filter(item => {
			if (!uniqueItems[item.content]) {
				uniqueItems[item.content] = true;
				return true;
			}
			return false;
		});
	}

	private checkLastUpdate() {
		const currentTime = new Date().getTime();
		const oneHourInMillis = 60 * 60 * 1000;
		const shouldFetchData = currentTime - this.lastUpdated > oneHourInMillis;

		if (shouldFetchData) {
			this.lastUpdated = currentTime;
		}
		this.localStorageService.set('lastUpdated', this.lastUpdated);
		return shouldFetchData;
	}
	/**
	 * Builds and returns a list of persistent search items.
	 * The function retrieves the persistent search list from local storage,
	 * then maps each item, translating the title and description keys
	 * for localization, and returns the modified list.
	 *
	 * @returns {SearchItemType[]} List of persistent search items with translated titles and descriptions.
	 */
	private buildPersistentSearchList(): SearchItemType[] {
		const persistentList = this.localStorageService.get(
			'persistenSearchList',
		) as SearchItemType[];

		// Map each item, translating the title and description keys for localization.
		return this.filterUniqueItems(
			persistentList.map(item => ({
				...item,
				title: this.getTranslationByKeyForPersistentList('title', item),
				description: this.getTranslationByKeyForPersistentList(
					'description',
					item,
				),
			})),
		);
	}

	private buildPersistentSuggestionList(): SuggestionItemType[] {
		const persistentList = this.localStorageService.get(
			'persistenSuggestionList',
		) as SuggestionItemType[];

		// Map each item, translating the title and description keys for localization.
		return persistentList.map(item => ({
			...item,
			content: stripHtmlTags(
				this.getTranslationByKeyForPersistentSuggestList(item),
			),
		}));
	}

	/**
	 * Fetches CMS data, either from local storage if conditions allow,
	 * or from the API endpoint. It first checks if the data needs to be updated.
	 * If the local storage has a persistent list with elements, it uses that data.
	 * Otherwise, it fetches data from the API endpoint, processes and formats it,
	 * and then builds the search index.
	 *
	 * @returns
	 */
	public async fetchCMSData() {
		const shouldUpdate = this.checkLastUpdate();
		// Check if it can fetch and persistent list has elements.
		const persistentList = this.localStorageService.get('persistenSearchList');
		if (!shouldUpdate && persistentList && persistentList.length > 0) {
			// Use the persistent list and build the search list.
			const persistentListTranslated = this.buildPersistentSearchList();
			const persistenSuggestionTranlated = this.buildPersistentSuggestionList();
			this.buildIndex(persistentListTranslated, true);
			this.buildSuggestionIndexList(persistenSuggestionTranlated, true);
			return;
		}
		// Otherwise it gets data from endpoint
		let posts: ConnectPost[] = [];
		let learnQA: LearnQnA[] = [];
		await firstValueFrom(this.cmsService.getConnectPosts()).then(_posts => {
			posts = _posts;
		});
		await firstValueFrom(this.cmsService.getPosts()).then(_learnQA => {
			learnQA = _learnQA;
		});
		const {
			searchList: formatedConnectedPost,
			suggestListFormat: formatedSuggestionConnectedList,
		} = this.formatConnectedPost(posts);
		const {
			searchList: formatedLearnQA,
			suggestListFormat: formatedSuggestionLearnList,
		} = this.formatLearnQA(learnQA);
		this.buildIndex([...formatedConnectedPost, ...formatedLearnQA]);
		this.buildSuggestionIndexList([
			...formatedSuggestionConnectedList,
			...formatedSuggestionLearnList,
		]);
	}
	private getConnectUrl = (slug: string) => {
		if (slug === 'peer-support') {
			return '';
		}
		if (slug === 'share-stories') {
			return 'share-your-story';
		}
		return slug;
	};
	private formatConnectedPost(posts: ConnectPost[]): {
		searchList: SearchItemType[];
		suggestListFormat: SuggestionItemType[];
	} {
		const format: SearchItemType[] = [];
		const suggestListFormat: SuggestionItemType[] = [];
		posts.forEach(item => {
			suggestListFormat.push({
				content: this.getTranslationByKeyForPosts('title', item),
				href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
				type: SEARCH_TYPE.CONNECTED_POST,
				originalItem: item,
				field: 'title',
			});
			suggestListFormat.push({
				content: stripHtmlTags(
					this.getTranslationByKeyForPosts('description', item),
				),
				href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
				type: SEARCH_TYPE.CONNECTED_POST,
				originalItem: item,
				field: 'description',
			});
			format.push({
				title: this.getTranslationByKeyForPosts('title', item),
				description: this.getTranslationByKeyForPosts('description', item),
				href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
				type: SEARCH_TYPE.CONNECTED_POST,
				originalItem: item,
			});
			if (item.acf.cta_one_url) {
				format.push({
					title: this.getTranslationByKeyForPosts('title', item),
					description: this.getTranslationByKeyForPosts('cta_one', item),
					href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
				});
				suggestListFormat.push({
					content: this.getTranslationByKeyForPosts('cta_one', item),
					href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
					type: SEARCH_TYPE.CONNECTED_POST,
					originalItem: item,
					field: 'cta_one',
				});
			}
			if (item.acf.cta_two_url) {
				format.push({
					title: this.getTranslationByKeyForPosts('title', item),
					description: this.getTranslationByKeyForPosts('cta_two', item),
					href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
				});
				suggestListFormat.push({
					content: this.getTranslationByKeyForPosts('cta_two', item),
					href: `/connect/${this.getConnectUrl(item.acf.section.slug)}`,
					type: SEARCH_TYPE.CONNECTED_POST,
					originalItem: item,
					field: 'cta_two',
				});
			}
		});
		return {
			searchList: this.filterUniqueItems(format),
			suggestListFormat: this.filterUniqueSuggestionItems(suggestListFormat),
		};
	}
	private formatLearnQA(learnQA: LearnQnA[]): {
		searchList: SearchItemType[];
		suggestListFormat: SuggestionItemType[];
	} {
		const format: SearchItemType[] = [];
		const suggestListFormat: SuggestionItemType[] = [];
		learnQA.forEach(item => {
			format.push({
				title: this.getTranslationByKeyForPosts('title', item),
				description: this.getTranslationByKeyForPosts('description', item),
				href: `/learn/${item.acf.milestone.slug}`,
				type: SEARCH_TYPE.LEARN_QA,
				originalItem: item,
			});
			suggestListFormat.push({
				content: stripHtmlTags(this.getTranslationByKeyForPosts('title', item)),
				href: `/learn/${item.acf.milestone.slug}`,
				type: SEARCH_TYPE.LEARN_QA,
				originalItem: item,
				field: 'title',
			});
			suggestListFormat.push({
				content: stripHtmlTags(
					this.getTranslationByKeyForPosts('description', item),
				),
				href: `/learn/${item.acf.milestone.slug}`,
				type: SEARCH_TYPE.LEARN_QA,
				originalItem: item,
				field: 'description',
			});
			if (item.acf.cta_one_url) {
				format.push({
					title: this.getTranslationByKeyForPosts('title', item),
					description: this.getTranslationByKeyForPosts('cta_one', item),
					href: `/learn/${item.acf.milestone.slug}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
				});
				suggestListFormat.push({
					content: stripHtmlTags(
						this.getTranslationByKeyForPosts('cta_one', item),
					),
					href: `/learn/${item.acf.milestone.slug}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
					field: 'cta_one',
				});
			}
			if (item.acf.cta_two_url) {
				format.push({
					title: this.getTranslationByKeyForPosts('title', item),
					description: this.getTranslationByKeyForPosts('cta_two', item),
					href: `/learn/${item.acf.milestone.slug}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
				});
				suggestListFormat.push({
					content: this.getTranslationByKeyForPosts('cta_two', item),
					href: `/learn/${item.acf.milestone.slug}`,
					type: SEARCH_TYPE.CTA,
					originalItem: item,
					field: 'cta_two',
				});
			}
		});
		return {
			searchList: this.filterUniqueItems(format),
			suggestListFormat: this.filterUniqueSuggestionItems(suggestListFormat),
		};
	}
	/**
	 * Builds the search index based on the provided list of search items.
	 * Optionally, it can incorporate hardcoded items and store the search list in local storage.
	 *
	 * @param {SearchItemType[]} list - List of search items to build the search list from.
	 * @param {boolean} isPersistent - Indicates whether to incorporate hardcoded items.
	 * @returns void
	 */
	private buildIndex(list: SearchItemType[], isPersistent = false): void {
		let listWithHardcodedItems = [...list];
		if (!isPersistent) {
			listWithHardcodedItems = [...defaultSearchList, ...list];
		}
		this.persistenSearchList = listWithHardcodedItems;
		this.localStorageService.set('persistenSearchList', listWithHardcodedItems);
		this.fuseSearch = new Fuse(listWithHardcodedItems, {
			keys: ['title', 'description'],
			threshold: 0.2,
			findAllMatches: true,
			ignoreLocation: true,
		});
	}

	private buildSuggestionIndexList(
		list: SuggestionItemType[],
		isPersistent = false,
	): void {
		let listWithHardcodedItems = [...list];
		const suggestionFormat: SuggestionItemType[] = [];
		defaultSearchList.forEach(({ title, description, ...props }) => {
			suggestionFormat.push({
				content: title,
				field: 'title',
				...props,
			});
			suggestionFormat.push({
				content: description,
				field: 'description',
				...props,
			});
		});
		if (!isPersistent) {
			listWithHardcodedItems = [...suggestionFormat, ...list];
		}
		this.persistenSuggestionList = listWithHardcodedItems;
		this.localStorageService.set(
			'persistenSuggestionList',
			listWithHardcodedItems,
		);
		this.fuseSuggestionSearch = new Fuse(listWithHardcodedItems, {
			keys: ['content'],
			threshold: 0.2,
			findAllMatches: true,
			ignoreLocation: true,
		});
	}

	search(query: string) {
		if (!this.fuseSearch) {
			return [];
		}
		this.recentSearchList = Array.from(
			new Set<string>([query, ...this.recentSearchList]),
		).slice(0, 10);
		const result = this.fuseSearch.search(query);
		this.localStorageService.set('recentSearchList', this.recentSearchList);
		return result;
	}
	searchSuggestion(query: string) {
		if (!this.fuseSuggestionSearch) {
			return [];
		}
		const result = this.fuseSuggestionSearch.search(query);
		return result;
	}
	getTranslationByKeyForPosts(key: string, post: ConnectPost | LearnQnA) {
		const currentLang = this.translator.getCurrentLang();

		return _.get(post?.acf, `${key}_${currentLang}`, key) || '';
	}
	getTranslationByKeyForPersistentList(key: string, item: SearchItemType) {
		const currentLang = this.translator.getCurrentLang();
		if ('title_en' in item.originalItem) {
			return _.get(item, `${key}_${currentLang}`, key) || '';
		}
		return _.get(item?.originalItem?.acf, `${key}_${currentLang}`, key) || '';
	}
	getTranslationByKeyForPersistentSuggestList(item: SuggestionItemType) {
		const currentLang = this.translator.getCurrentLang();
		if ('title_en' in item.originalItem) {
			return (
				_.get(item.originalItem, `${item.field}_${currentLang}`, item.field) ||
				''
			);
		}
		return (
			_.get(
				item?.originalItem?.acf,
				`${item.field}_${currentLang}`,
				item.field,
			) || ''
		);
	}
}
