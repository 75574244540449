<h1>{{ 'onboarding.welcome.welcome' | translate }}</h1>

<app-onboarding-card
	title="<div class='onboarding-title-centered'><p>What language do you prefer?</p><i>¿Qué idioma prefieres?</i></div>"
></app-onboarding-card>

<div class="radio-toolbar">
	<ng-container *ngFor="let lang of translator.getSupportLangs()">
		<input
			type="radio"
			id="{{ lang }}"
			[(ngModel)]="langSelected"
			value="{{ lang }}"
			(change)="onChangeLang(lang)"
		/>
		<label for="{{ lang }}">
			<div *ngIf="!isSelectedLang(lang)" class="dot"></div>
			<span *ngIf="isSelectedLang(lang)" class="bi bi-check icon"></span>
			{{ getLabelLang(lang) }} </label
		><br />
	</ng-container>
</div>
<button
	type="button"
	class="btn btn-lg main-button base-button"
	(click)="navigateToSignUp()"
>
	{{ 'continue' | translate }}
</button>

<button
	type="button"
	class="btn btn-text btn-lg text-button base-button"
	(click)="navigateToWelcome()"
>
	{{ 'cancel' | translate }}
</button>
