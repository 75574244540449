<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div [id]="'task-' + index" class="container-fluid task">
	<div class="check" [class.active]="isChecked">
		<input
			[id]="'check-' + index"
			(click)="changeStatus()"
			[checked]="isChecked"
			class="form-check-input"
			type="checkbox"
		/>
	</div>
	<div class="info-content" (click)="openTask()">
		<h4 class="title" [class.open]="isOpen">{{ title }}</h4>
		<div
			class="body"
			[class.text-truncate]="!isOpen"
			[innerHTML]="description"
		></div>
	</div>
</div>
