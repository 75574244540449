/* eslint-disable @typescript-eslint/no-explicit-any */
// pagination.service.ts
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PaginationService<T> {
	private itemsPerPage = 4; // Número de elementos por página
	private items: T[] = []; // Aquí guardarás la lista completa de elementos
	currentIndex = 0;
	currentPage = 1;
	totalPages = 0;
	totalResults = 0;

	setItems(itemsList: T[]) {
		this.items = itemsList;
		this.currentIndex = 0; // Reiniciar el índice al establecer una nueva lista
		this.currentPage = 1;
		this.totalPages = Math.ceil(itemsList.length / this.itemsPerPage);
		this.totalResults = itemsList.length;
	}

	setItemsPerPage(amount: number) {
		this.itemsPerPage = amount;
	}

	getCurrentPageItems() {
		const start = this.currentIndex;
		const end = this.currentIndex + this.itemsPerPage;
		return this.items.slice(start, end);
	}
	getMorePageItems() {
		const end = this.currentIndex + this.itemsPerPage;
		return this.items.slice(0, end);
	}
	nextPage(): void {
		const maxIndex = this.items.length - this.itemsPerPage;
		this.currentIndex = Math.min(
			this.currentIndex + this.itemsPerPage,
			maxIndex,
		);
		this.currentPage = Math.min(this.currentPage + 1, this.totalPages);
	}

	prevPage(): void {
		this.currentIndex = Math.max(this.currentIndex - this.itemsPerPage, 0);
		this.currentPage = Math.max(this.currentPage - 1, 1);
	}

	goToLastPage(): void {
		const maxIndex = this.items.length - this.itemsPerPage;
		this.currentPage = this.totalPages;
		this.currentIndex = maxIndex > 0 ? maxIndex : 0;
	}

	goToFirstPage(): void {
		this.currentPage = 1;
		this.currentIndex = 0;
	}

	getIndex(item: any) {
		const findIndex = this.items.findIndex((i) => i === item );
		return findIndex;
	} 
}
