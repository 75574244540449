<app-box *ngIf="!hideCard">
	<div class="card px-4 pb-4">
		<figure class="img-card">
			<img src="/assets/images/nurse.svg" alt="image" />
		</figure>
		<b [innerHTML]="title | translate"></b>
		<span>
			{{ description | translate }}
		</span>
	</div>
</app-box>

<div class="card px-4 pb-4" *ngIf="hideCard" [class.hideCard]="hideCard">
	<figure class="img-card">
		<img src="/assets/images/nurse.svg" alt="image" />
	</figure>
</div>
