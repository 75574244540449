<footer class="container text-center align-items-center footer">
	<div class="row align-items-center">
		<div class="col">
			<div class="row align-items-center">
				<div class="col">
					<img
						class="logo"
						src="../../../assets/images/UNOS_Logo.svg"
						alt="UNOS Logo"
					/>
				</div>
			</div>
			<div>
				<span class="custom-link"
					>© {{ year }}
					<a
						href="https://unos.org"
						class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
						><b>{{ 'footer.unitedNetworkForOrganSharing' | translate }}</b>
					</a>
					<span>{{ 'footer.description' | translate }}</span>
					|
					<a
						target="_blank"
						href="https://www.guidestar.org/profile/54-1327878"
						class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
					>
						{{ 'footer.guidingStar' | translate }}
					</a>
					|
					<a
						href="https://unos.org/privacy-policy/"
						class="link-secondary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
						>{{ 'footer.legal' | translate }}</a
					></span
				>
			</div>
		</div>
	</div>
</footer>
