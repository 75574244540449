/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { CmsService } from '@services/cms.service';
import { LearnQnA } from '@models/learn';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as _ from 'lodash';
import { BreadcrumbService } from 'xng-breadcrumb';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslatorService } from '@services/translator.service';
import {
	getJourneyImg,
	learRoutes,
	learnBreadcrumb,
} from 'src/app/utils/constants';

@Component({
	selector: 'app-q-and-a',
	templateUrl: './q-and-a.component.html',
	styleUrls: ['./q-and-a.component.scss'],
})
export class QAndAComponent implements OnInit {
	public posts: LearnQnA[] = [];
	public slug: any = '';
	public title = '';
	public img = '';
	public deviceInfo = {
		isMobile: false,
		isDesktop: true,
		isTablet: false,
		isLandscape: false,
	};
	public journeyRoutes = learRoutes();
	public isLoading = true;
	public error = false;

	constructor(
		private cmsService: CmsService,
		private router: Router,
		private route: ActivatedRoute,
		private breadcrumbService: BreadcrumbService,
		private deviceService: DeviceDetectorService,
		private translator: TranslatorService,
	) {
		this.getDevice();
		translator.addLanguageChangeAction(() => {
			this.getDescription();
			this.getTitle();
		});
	}

	private extractPhoneNumber(phoneNumberString: string) {
		const phoneNumberRegex = /tel:\((\d+)\) (\d+)-(\d+)/;
		const matches = phoneNumberString.match(phoneNumberRegex);

		if (matches && matches.length === 4) {
			const areaCode = matches[1];
			const firstPart = matches[2];
			const secondPart = matches[3];
			const phoneNumber = `Phone: (${areaCode}) ${firstPart}-${secondPart}`;
			return { value: phoneNumber, isPhoneNumber: true };
		}
		return { value: phoneNumberString, isPhoneNumber: false };
	}

	private formatPostWithCTA(post: any = {}) {
		const { acf } = post;
		if (!acf || !this.deviceInfo.isDesktop) {
			return post;
		}

		const { cta_one, cta_one_url, cta_two, cta_two_url } = acf;
		const { isPhoneNumber: CTAOnePhoneNumber, value: ctaOne } =
			this.extractPhoneNumber(cta_one_url);
		const { isPhoneNumber: CTATwoPhoneNumber, value: ctaTwo } =
			this.extractPhoneNumber(cta_two_url);
		return {
			...post,
			acf: {
				...acf,
				cta_one: CTAOnePhoneNumber ? ctaOne : cta_one,
				cta_one_url: CTAOnePhoneNumber ? '#' : cta_one_url,
				cta_two: CTATwoPhoneNumber ? ctaTwo : cta_two,
				cta_two_url: CTATwoPhoneNumber ? '#' : cta_two_url,
			},
		};
	}

	private getDevice() {
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		/**
		 * the ngx-device-detector detecs an ipad as tablet and desktop so,
		 * we check if the device is tablet then is not a desktop device
		 * */

		const isDesktopDevice = this.deviceService.isDesktop() && !isTablet;
		const isLandscape = this.deviceService.orientation === 'landscape';

		this.deviceInfo = {
			isDesktop: isDesktopDevice,
			isMobile,
			isTablet,
			isLandscape,
		};
	}
	ngOnInit(): void {
		this.cmsService.getPosts().subscribe(
			posts => {
				this.posts = _.filter(
					posts,
					(item: any) => item.acf.milestone.slug === this.slug,
				).map(item => {
					return this.formatPostWithCTA(item);
				});

				this.isLoading = false;
			},
			(e: any) => {
				this.error = true;
				console.log(e);
			},
		);

		this.router.events.subscribe((event: any) => {
			if (
				event instanceof NavigationEnd ||
				event.routerEvent instanceof NavigationEnd
			) {
				this.slug = this.route.snapshot.paramMap.get('slug');
				this.breadcrumbService.set('learn/:slug', this.getTitle());
				this.cmsService.getPosts().subscribe(posts => {
					this.posts = _.filter(
						posts,
						(item: any) => item.acf.milestone.slug === this.slug,
					);
				});
			}
		});
	}

	redirectToPage(url: string) {
		this.router.navigate([url]);
	}

	getTitle() {
		switch (this.slug) {
			case 'considering-transplant':
				return `1. ${this.translator.get('learn.consideringTransplant')}`;
			case 'choosing-a-hospital':
				return `2. ${this.translator.get('learn.choosingHospital')}`;
			case 'getting-evaluated':
				return `3. ${this.translator.get('learn.gettingEvaluated')}`;
			case 'waiting-for-an-organ-offer':
				return `4. ${this.translator.get('learn.waitingOrgan')}`;
			case 'considering-a-living-donor':
				return `5. ${this.translator.get('learn.consideringDonor')}`;
			case 'accepting-an-offer-having-surgery':
				return `6. ${this.translator.get('learn.acceptingOffer')}`;
			case 'navigating-life-post-transplant':
				return `7. ${this.translator.get('learn.navigatingLife')}`;
			default:
				return '';
		}
	}

	getImage() {
		return getJourneyImg(this.slug);
	}
	getDescription() {
		return this.translator.get('journey.stepDescription');
	}
	getBreadcrumbLabel(breadcrumb: string) {
		const number = breadcrumb.split('.')[0];
		const translationKey = _.get(learnBreadcrumb, Number(number), null);
		const formatKey = translationKey
			? `${number}. ${this.translator.get(translationKey)}`
			: this.translator.get(breadcrumb);

		return formatKey;
	}
	getPostTitle(post: LearnQnA) {
		const currentLang = this.translator.getCurrentLang() as 'es' | 'en';
		return post.acf[`title_${currentLang}`];
	}
}
