import { Component, OnInit } from '@angular/core';
import { TranslatorService } from '@services/translator.service';
import { UtilsService } from 'src/app/services/utils.service';
import { myInfoRoutes } from 'src/app/utils/constants';

@Component({
	selector: 'app-tasks',
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
	public myInfoRoutes = myInfoRoutes();

	constructor(
		private utilsService: UtilsService,
		private translator: TranslatorService,
	) {}
	ngOnInit(): void {
		// TODO: Remove after POC - First Demo
		this.utilsService.replaceAllData([]);
		this.translator.addLanguageChangeAction(() => {
			this.getDescription();
		});
	}

	getChecklist() {
		return [
			{
				title: 'myTask.checkList1Title',
				description: 'myTask.checkList1Description',
			},
			{
				title: 'myTask.checkList2Title',
				description: 'myTask.checkList2Description',
			},
			{
				title: 'myTask.checkList3Title',
				description: 'myTask.checkList3Description',
			},
			{
				title: 'myTask.checkList4Title',
				description: 'myTask.checkList4Description',
			},
			{
				title: 'myTask.checkList5Title',
				description: 'myTask.checkList5Description',
			},
		];
	}
	getDescription() {
		return this.translator.get('myTask.description');
	}
}
