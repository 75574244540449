<!-- eslint-disable @angular-eslint/template/elements-content -->
<p *ngIf="hasMoreItems && !hideTotalResults" style="text-align: center">
	{{ 'pagination.totalResults' | translate }} <b>{{ totalResults }}</b>
</p>
<div *ngIf="hasMoreItems" class="p-4" [ngClass]="class">
	<div class="pagination-controls mt-3">
		<button
			tabindex="3"
			(click)="handlePressFirstPage()"
			[disabled]="currentPage === 1"
			type="button"
			class="button-track first-page"
		></button>
		<button
			tabindex="4"
			(click)="handlePressPrevPage()"
			[disabled]="currentPage === 1"
			type="button"
			class="button-track prev-page"
		></button>
		<div class="central-info remove-padding">
			<span class="pagination-info" [innerHTML]="paginationInfoLabel"></span>
		</div>
		<button
			tabindex="5"
			(click)="handlePressNextPage()"
			[disabled]="currentPage === totalPages"
			type="button"
			class="button-track next-page"
		></button>
		<button
			tabindex="6"
			(click)="handlePressLastPage()"
			[disabled]="currentPage === totalPages"
			type="button"
			class="button-track last-page"
		></button>
	</div>
</div>
