import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class TranslatorService implements OnDestroy {
	private langChangeSubscription: Subscription;
	private languageChangeActions: ((event: LangChangeEvent) => void)[] = [];

	constructor(
		private translateService: TranslateService,
		private localStorage: LocalStorageService,
	) {
		this.langChangeSubscription = this.translateService.onLangChange.subscribe(
			(event: LangChangeEvent) => {
				this.executeLanguageChangeActions(event);
			},
		);
	}

	ngOnDestroy(): void {
		this.langChangeSubscription.unsubscribe();
	}

	// Run this func when lang is changed
	private executeLanguageChangeActions(event: LangChangeEvent): void {
		if (this.languageChangeActions && this.languageChangeActions.length > 0) {
			this.languageChangeActions.forEach(action => action(event));
		}
	}

	addLanguageChangeAction(action: () => void): void {
		this.languageChangeActions.push(action);
	}
	get(key: string, params?: Record<string, string | number>) {
		return this.translateService.instant(key, params);
	}
	getNextLng() {
		const currentLangs = this.translateService.getLangs();
		const currentLang = this.getCurrentLang();
		const nextLang = currentLangs.filter(item => item !== currentLang)[0];
		return nextLang;
	}
	getSupportLangs() {
		return this.translateService.getLangs();
	}
	changeLang() {
		const nextLang = this.getNextLng();
		this.localStorage.set('lang', nextLang);
		this.translateService.use(nextLang);
	}
	getCurrentLang() {
		const currentLang = this.localStorage.get('lang') || 'en';
		return currentLang as 'es' | 'en';
	}
}
