<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container-fluid root-container" role="main">
	<xng-breadcrumb separator=" ">
		<ng-container *xngBreadcrumbItem="let breadcrumb">
			<ng-container>{{ getBreadcrumbLabel(breadcrumb) }}</ng-container>
		</ng-container>
	</xng-breadcrumb>

	<div
		class="container-fluid qna text-center grid-container grid-container-reverse"
		role="main"
	>
		<div class="row right-section">
			<div class="col remove-padding">
				<!-- Q & A - Title -->
				<div class="row justify-content-start">
					<div class="col text-center">
						<h2 class="title">
							<img
								(click)="redirectToPage('/learn')"
								src="../../../../assets/icons/chevron-left.svg"
								alt="Go Back"
							/>{{ getTitle() }}
						</h2>

						<app-info-card>
							<span [innerHTML]="getDescription()"></span>
						</app-info-card>
					</div>
				</div>

				<br />
				<div *ngIf="isLoading">
					<app-loading></app-loading>
				</div>
				<div *ngIf="!isLoading && !error">
					<div class="accordion accordion-flush" id="accordion-with-steps ">
						<!-- Q & A - Items -->
						<ng-container *ngFor="let post of posts; index as i">
							<app-qna-card
								[id]="i"
								[title]="getPostTitle(post)"
								[acf]="post.acf"
								[key]="'item-' + i"
								accordionName="accordion-with-steps"
							/>

							<br />
						</ng-container>
					</div>
				</div>

				<div
					*ngIf="!isLoading && error"
					class="d-flex flex-1 justify-content-center align-items-center"
				>
					<p>An unexpected error occureds</p>
				</div>
			</div>
		</div>

		<div class="left-section">
			<!-- Img Sections -->
			<div *ngIf="!isLoading && !error">
				<img [src]="getImage()" [alt]="getTitle()" class="step-image" />
			</div>
			<br />
			<!-- Nav Card -->
			<app-nav-card
				title="learn.title"
				[routes]="journeyRoutes"
				[showNumbers]="true"
			/>
		</div>
	</div>
</div>
