<div class="container-fluid relative" [class.remove-padding]="isBig">
	<div class="row position-relative">
		<div class="col-2 text-center reduce-left-padding" *ngIf="!isBig">
			<app-circular-step
				[isCurrent]="isCurrent"
				[isComplete]="isComplete"
				[isInactive]="isInactive"
				[isBig]="isBig"
				[isSmall]="true"
				[class]="class"
				[currentJourneyStep]="currentJourneyStep"
			>
				{{ step }}
			</app-circular-step>
		</div>

		<div class="col-3 text-center remove-padding" *ngIf="isBig">
			<div class="vertical-hr relative" [class.complete]="isComplete"></div>
			<app-circular-step
				[isCurrent]="isCurrent"
				[isComplete]="isComplete"
				[isInactive]="isInactive"
				[isBig]="isBig"
				[class]="class"
				[currentJourneyStep]="currentJourneyStep"
			>
				{{ step }}
			</app-circular-step>
			<div class="vertical-hr relative" [class.complete]="isComplete"></div>
		</div>
		<div
			class="col relative title remove-padding step-title-mobile"
			[class.current]="isCurrent"
			[class.big]="isBig"
		>
			<h2>{{ title | translate }}</h2>
			<span *ngIf="quote">{{ quote | translate }}</span>
		</div>
	</div>
	<div class="row relative">
		<div class="col-2 text-center reduce-left-padding" *ngIf="!isBig">
			<div class="vertical-hr" [class.complete]="isComplete"></div>
		</div>

		<div class="col-3 text-center remove-padding" *ngIf="isBig">
			<div class="vertical-hr" [class.complete]="isComplete"></div>
		</div>

		<div
			class="col text-center relative reduce-left-padding reduce-right-padding"
		>
			<div
				class="col relative title remove-padding step-title-desktop"
				[class.current]="isCurrent"
				[class.big]="isBig"
			>
				<h2>{{ title | translate }}</h2>
				<span *ngIf="quote">{{ quote | translate }}</span>
			</div>
			<ng-content></ng-content>
		</div>
	</div>
	<div *ngIf="!isLast" class="row footer">
		<div class="col-2 text-center reduce-left-padding" *ngIf="!isBig">
			<div class="vertical-hr" [class.complete]="isComplete">
				<hr />
			</div>
		</div>
		<div class="col-3 text-center remove-padding" *ngIf="isBig">
			<div class="vertical-hr" [class.complete]="isComplete">
				<hr />
			</div>
		</div>
		<div class="col reduce-left-padding" [class.bigDetail]="isBig"></div>
	</div>
</div>
