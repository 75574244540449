import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent {
	@Input() hasMoreItems = false;
	@Input() totalResults = 0;
	@Input() currentPage = 0;
	@Input() totalPages = 0;
	@Input() paginationInfoLabel = '';
	@Input() hideTotalResults = false;
	@Input() class = '';
	@Output() pressFirstPage = new EventEmitter();
	@Output() pressNextPage = new EventEmitter();
	@Output() pressPrevPage = new EventEmitter();
	@Output() pressLastPage = new EventEmitter();

	handlePressFirstPage() {
		this.pressFirstPage.emit();
	}
	handlePressPrevPage() {
		this.pressPrevPage.emit();
	}
	handlePressNextPage() {
		this.pressNextPage.emit();
	}
	handlePressLastPage() {
		this.pressLastPage.emit();
	}
}
