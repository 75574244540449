<header>
	<i>{{ 'transplantNavigator' | translate }}</i>
</header>

<div class="root">
	<h1>Welcome!</h1>
	<h1><i>¡Bienvenido!</i></h1>

	<app-onboarding-card [hideCard]="true" />

	<form *ngIf="loginForm" [formGroup]="loginForm" (ngSubmit)="login()">
		<div *ngIf="!isLoading" class="mb-3">
			<label for="username" class="form-label label">{{ 'login.username.label' | translate }}<span>*</span></label>
			<div class="input-group">
				<input
					type="text"
					class="form-control input"
					id="username"
					formControlName="username"
					placeholder="{{ 'login.email.placeholder' | translate }}"
				>
				<img *ngIf="inputUsername?.valid && (inputUsername?.dirty || inputUsername?.touched)" src="assets/icons/Complete Check Green.png" alt="Correct">
				<span *ngIf="inputUsername?.invalid && (inputUsername?.dirty || inputUsername?.touched)">x</span>
			</div>
		</div>
		
		<div *ngIf="!isLoading" class="mb-3">
			<label for="password" class="form-label label">{{ 'login.password.label' | translate }}<span>*</span></label>
			<div class="input-group">
				<input
					type="password"
					class="form-control input"
					id="password"
					formControlName="password"
					placeholder="{{ 'login.password.placeholder' | translate }}"
				>
				<img *ngIf="validatePassword()" src="assets/icons/Complete Check Green.png" alt="Correct">
				<span *ngIf="!validatePassword()">x</span>
			</div>
		</div>

		<br>

		<div *ngIf="isLoading">
			<app-loading></app-loading>
		</div>

		<button
			[disabled]="isLoading || inputUsername?.invalid || inputPassword?.invalid"
			type="submit"
			class="btn btn-lg main-button base-button slim-button"
		>
			{{ 'login.button' | translate }}
		</button>
	</form>

	<button
		type="button"
		class="btn btn-text btn-lg text-button base-button"
	>
		{{ 'login.link' | translate }}
	</button>
</div>

<app-footer></app-footer>
