<div class="container-fluid peer-support root-container" role="main">
	<div class="col remove-padding">
		<!-- Peer Support -->
		<div class="row justify-content-start content">
			<div class="col text-center">
				<h2 class="title">{{ 'connect.peerSupport' | translate }}</h2>
			</div>
		</div>

		<app-info-card>
			<span [innerHTML]="getDescription()"></span>
		</app-info-card>
	</div>

	<br />

	<div *ngIf="isLoading">
		<app-loading></app-loading>
	</div>
	<div *ngIf="!isLoading" class="grid-container">
		<!-- Posts -->
		<ng-container *ngFor="let post of gridPosts; index as i">
			<div>
				<div class="grid-item">
					<app-content-card
						[headerImg]="post.acf.headerimage"
						[altText]="getTranslationByKey('title', post)"
						[description]="getTranslationByKey('description', post)"
						[videoUrl]="post.acf.video_url"
						[cta_one]="getTranslationByKey('cta_one', post)"
						[cta_one_url]="post.acf.cta_one_url"
						[cta_two]="getTranslationByKey('cta_two', post)"
						[cta_two_url]="post.acf.cta_two_url"
						class="full"
					/>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="grid-container grid-footer">
		<div *ngIf="!!lastPost" class="grid-item grid-buttons">
			<app-content-card
				[headerImg]="lastPost.acf.headerimage"
				[altText]="getTranslationByKey('title', lastPost)"
				[description]="getTranslationByKey('description', lastPost)"
				[videoUrl]="lastPost.acf.video_url"
				[cta_one]="getTranslationByKey('cta_one', lastPost)"
				[cta_one_url]="lastPost.acf.cta_one_url"
				[cta_two]="getTranslationByKey('cta_two', lastPost)"
				[cta_two_url]="lastPost.acf.cta_two_url"
				class="full"
			/>
		</div>
		<!-- <div class="grid-item grid-buttons">
			<div>
				<div class="col remove-padding">
					<a
						type="button"
						class="btn btn-primary custom-button"
						href="https://transplantliving.org/community/support-groups/#state"
						target="_blank"
					>
						{{ 'connect.viewSupport' | translate }}
					</a>
				</div>
			</div>

			<br />

			<div>
				<div class="col remove-padding">
					<a
						type="button"
						class="btn btn-secondary custom-button"
						href="https://transplantliving.org/community/support-groups/"
						target="_blank"
					>
						{{ 'connect.learnMore' | translate }}
					</a>
				</div>
			</div>
		</div>
		<br /> -->

		<!-- Nav Card -->
		<app-nav-card
			title="connect.title"
			[routes]="peerSupportRoutes"
			class="grid-item grid-nav"
		/>
	</div>
</div>
