/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '@services/local-storage.service';
import { AlertsService } from '@services/alerts.service';
import {
	ActivatedRoute,
	NavigationEnd,
	Router,
	RouterState,
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';

declare global {
	interface Window {
		flcklr: any; // turn off type checking
	}
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	isLogouted = false;
	constructor(
		private renderer: Renderer2,
		public translate: TranslateService,
		public localStorage: LocalStorageService,
		private alertService: AlertsService,
		private router: Router,
		@Inject(DOCUMENT) private document: Document,
	) {
		translate.addLangs(['en', 'es']);
		const currentLang = localStorage.get('lang');
		if (currentLang) {
			translate.setDefaultLang(currentLang);
		} else {
			translate.setDefaultLang('en');
			localStorage.set('lang', 'en');
		}
		this.handleRouteEvents();
	}
	handleRouteEvents() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const title = this.getTitle(
					this.router.routerState,
					this.router.routerState.root,
				).join('-');
				gtag('event', 'page_view', {
					page_title: title,
					page_path: event.urlAfterRedirects,
					page_location: this.document.location.href,
				});
			}
		});
	}
	getTitle(state: RouterState, parent: ActivatedRoute): string[] {
		const data = [];
		if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
			data.push(parent.snapshot.data['title']);
		}
		if (state && parent && parent.firstChild) {
			data.push(...this.getTitle(state, parent.firstChild));
		}
		return data;
	}

	ngOnInit(): void {
		this.renderer.addClass(document.body, 'full-height');
		this.alertService.fetchAlerts();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				const currentRoute = event.url;
				// TODO: Temporary validation to perform onboarding tests.
				const isOnboardingScreen = currentRoute.includes('onboarding');
				if (isOnboardingScreen) {
					this.isLogouted = true;
				} else {
					const checkLogin: any = this.localStorage.get('isLogin')
						? JSON.parse(this.localStorage.get('isLogin'))
						: false;
					const isLoginScreen = currentRoute.includes('login');

					if (checkLogin && isLoginScreen) {
						this.router.navigate(['']);
					} else if (!checkLogin) {
						this.router.navigate(['/login']);
					}

					this.isLogouted = !checkLogin;
				}
			}
		});
	}
	switchLang(lang: string) {
		this.translate.use(lang);
	}
	showOnboardingButton() {
		const isLoggedIn = this.localStorage.get('isLogin');
		const hidenRoutes = ['onboarding'];
		if (hidenRoutes.some(item => this.router.url.includes(item))) {
			return false;
		}

		return !isLoggedIn && !environment.production;
	}
}
