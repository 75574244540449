<button
	(click)="redirectToPage('/alerts')"
	[class.alert]="activeAlert"
	[class.instantAlert]="instantAlert"
	[class.no-title]="noTitle"
	class="btn btn-outline-none text-center custom-button notification"
	type="button"
>
	<img
		class="d-block bell-icon"
		src="../../../assets/icons/Notification_Bell.svg"
		alt="Menu"
	/>
	<div class="notification-amount">{{ notifications }}</div>
	<span *ngIf="title" class="d-block">{{ title }}</span>
</button>
