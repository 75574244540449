<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container-fluid root-container alerts" role="main">
	<h3 class="title">{{ 'alerts.title' | translate }}</h3>

	<!-- Content -->
	<div
		class="container-fluid text-center grid-container grid-container-reverse"
		role="main"
	>
		<!-- Alerts - Inbox-->
		<div class="row left-section">
			<div class="col remove-padding">
				<app-info-card
					*ngIf="paginationService.getCurrentPageItems().length === 0"
				>
					{{ 'alerts.emptyList' | translate }}
				</app-info-card>

				<div
					id="mobile"
					*ngIf="paginationService.getCurrentPageItems().length > 0"
				>
					<div class="dropdown custom-dropdown">
						<button
							class="btn dropdown-toggle"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							{{ 'alerts.sort.title' | translate }}
						</button>
						<ul class="dropdown-menu">
							<li class="dropdown-item" (click)="sortByRead()">
								{{ 'alerts.sort.read' | translate }}
							</li>
							<li class="dropdown-item" (click)="sortByUnRead()">
								{{ 'alerts.sort.unread' | translate }}
							</li>
							<li class="dropdown-item" (click)="sortByAll()">
								{{ 'alerts.sort.all' | translate }}
							</li>
						</ul>
					</div>

					<br />

					<ng-container *ngFor="let item of getItems(); index as key">
						<div
							(click)="openAlert(item)"
							data-bs-toggle="modal"
							data-bs-target="#alertModal"
							[class.unread]="!item.read"
							class="alert-item"
							id="a-{{ key }}"
						>
							<h2>{{ item.title }}</h2>
							<span>{{ formatDate(item.date) }}</span>
						</div>

						<br />
					</ng-container>
				</div>

				<div
					id="desktop"
					*ngIf="paginationService.getCurrentPageItems().length > 0"
				>
					<div class="dropdown custom-dropdown">
						<button
							class="btn dropdown-toggle"
							type="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							{{ 'alerts.sort.title' | translate }}
						</button>
						<ul class="dropdown-menu">
							<li class="dropdown-item" (click)="sortByRead()">
								{{ 'alerts.sort.read' | translate }}
							</li>
							<li class="dropdown-item" (click)="sortByUnRead()">
								{{ 'alerts.sort.unread' | translate }}
							</li>
							<li class="dropdown-item" (click)="sortByAll()">
								{{ 'alerts.sort.all' | translate }}
							</li>
						</ul>
					</div>

					<br />

					<ng-container
						*ngFor="
							let item of paginationService.getCurrentPageItems();
							index as key
						"
					>
						<div
							(click)="openAlert(item, true)"
							[class.unread]="!item.read"
							class="alert-item"
							id="a-{{ key }}"
						>
							<h2>{{ item.title }}</h2>
							<span>{{ formatDate(item.date) }}</span>
						</div>

						<br />
					</ng-container>
				</div>

				<!-- Pagination Buttons -->
				<app-pagination
					[hasMoreItems]="paginationService.getMorePageItems().length > 0"
					[totalResults]="paginationService.totalResults"
					[currentPage]="paginationService.currentPage"
					[totalPages]="paginationService.totalPages"
					[paginationInfoLabel]="getPagePaginationLabel()"
					(pressFirstPage)="paginationService.goToFirstPage()"
					(pressPrevPage)="paginationService.prevPage()"
					(pressNextPage)="paginationService.nextPage()"
					(pressLastPage)="paginationService.goToLastPage()"
				></app-pagination>

				<div *ngIf="showToolsButton()" class="tools-footer">
					<button type="button" class="btn tool-button" (click)="deleteAll()">
						{{ 'alerts.tools.delete' | translate }}
					</button>

					<button type="button" class="btn tool-button" (click)="restoreAll()">
						{{ 'alerts.tools.restore' | translate }}
					</button>
				</div>
			</div>
		</div>

		<!-- Alerts - Notification / Nav-->
		<div class="right-section">
			<!-- Read Notification - Desktop -->
			<div class="card" *ngIf="openDesktopAlert">
				<div class="card-header">
					<h5 class="card-title">{{ alertObject.title }}</h5>
					<span>{{ formatDate(alertObject.date) }}</span>
				</div>
				<div
					class="card-body"
					[innerHTML]="alertObject.body || alertObject.title"
				></div>
				<div class="card-footer">
					<button
						type="button"
						class="btn btn-secondary"
						(click)="onRemoveAlert(alertObject)"
					>
						{{ 'alerts.modal.deleteButton' | translate }}
					</button>
					<button type="button" class="btn btn-primary" (click)="closeAlert()">
						{{ 'alerts.modal.closeButton' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>

	<!-- Modal -->
	<div
		class="modal fade"
		id="alertModal"
		aria-labelledby="exampleModalLabel"
		aria-hidden="true"
	>
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content">
				<div class="modal-header">
					<button
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
					<h1 class="modal-title fs-5" id="exampleModalLabel">
						{{ alertObject.title }}
					</h1>
					<span>{{ formatDate(alertObject.date) }}</span>
				</div>
				<div
					class="modal-body"
					[innerHTML]="alertObject.body || alertObject.title"
				></div>
				<div class="modal-footer">
					<button
						type="button"
						class="btn btn-secondary"
						data-bs-dismiss="modal"
						(click)="onRemoveAlert(alertObject)"
					>
						{{ 'alerts.modal.deleteButton' | translate }}
					</button>
					<button type="button" class="btn btn-primary" data-bs-dismiss="modal">
						{{ 'alerts.modal.closeButton' | translate }}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Footer -->
<app-footer></app-footer>
