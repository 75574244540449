import { Component } from '@angular/core';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
	constructor(public state: OnboardingService) {}
	nextStep() {
		this.state.setStep(SCREEN_NAME.LANG);
	}
}
