import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CmsService } from '@services/cms.service';
import { ConnectPost } from '@models/connect';
import { connectRoutes } from 'src/app/utils/constants';
import { TranslatorService } from '@services/translator.service';

@Component({
	selector: 'app-share-your-story',
	templateUrl: './share-your-story.component.html',
	styleUrls: ['./share-your-story.component.scss'],
})
export class ShareYourStoryComponent implements OnInit {
	public posts: ConnectPost[] = [];
	public peerSupportRoutes = connectRoutes();
	fieldsToTranslate = {
		title: 'title',
		description: 'description',
		cta_one: 'cta_one',
		cta_two: 'cta_two',
	};
	constructor(
		private cmsService: CmsService,
		private translator: TranslatorService,
	) {
		this.translator.addLanguageChangeAction(() => {
			const keyToUpdate = Object.values(this.fieldsToTranslate);
			this.getDescription();
			this.posts.map(post => {
				keyToUpdate.map(key => this.getTranslationByKey(key, post));
			});
		});
	}

	ngOnInit(): void {
		this.cmsService.getConnectPosts().subscribe(posts => {
			this.posts = posts;
			this.posts = _.filter(
				posts,
				(item: ConnectPost) => item.acf.section.slug === 'share-stories',
			);
		});
	}
	getDescription() {
		return this.translator.get('shareStories.description');
	}
	getTranslationByKey(key: string, post: ConnectPost) {
		const currentLang = this.translator.getCurrentLang();
		return _.get(post.acf, `${key}_${currentLang}`, key) || '';
	}
}
