import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilsService } from '@services/utils.service';
import { Router } from '@angular/router';
import { UnosService } from '@services/unos.service';
import { Patient } from '@models/patient';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-status',
	templateUrl: './status.component.html',
	styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy {
	public greeting = '';
	public lastUpdate = '';
	public active = true;
	public statusDescription = '';
	public tooltipMessage = '';
	public statusDetails = '';
	public transplantCenter = '';
	public patient: Patient | undefined;
	public myInfoRoutes = [
		{
			title: 'myInfo.myStatus',
			link: '/my-info',
			exact: true,
		},
		{
			title: 'myInfo.myTask',
			link: '/my-info/tasks',
			exact: false,
		},
		{
			title: 'myInfo.myWaitTime',
			link: '/my-info/wait-time',
			exact: false,
		},
		{
			title: 'myInfo.myMedicalHistory',
			link: '/my-info/medical-history',
			exact: false,
		},
	];
	private langChangeSubscription: Subscription;

	constructor(
		private utilsService: UtilsService,
		private unosService: UnosService,
		private router: Router,
		private translator: TranslateService,
	) {
		this.langChangeSubscription = this.translator.onLangChange.subscribe(() => {
			this.getTooltip();
		});
	}
	ngOnDestroy(): void {
		this.langChangeSubscription.unsubscribe();
	}

	ngOnInit(): void {
		this.unosService.getClientInfo().subscribe(patient => {
			this.patient = patient;
			this.patient.firstname = this.utilsService.extractFirstName(patient.name);
			localStorage.setItem('profileName', patient.name);
			// get registration based on patient into
			if (this.patient.registrations.length) {
				const regId = this.utilsService.getRandomArrayValue(
					this.patient.registrations,
				);
				this.unosService.getRegistrationById(regId).subscribe(reg => {
					const tempDate = this.utilsService.formatDate(
						reg.lastUpdatedDate,
						'MM/DD/YY hh:mma [EST]',
					);
					this.active = reg.active;
					this.lastUpdate = tempDate;
					this.statusDescription = reg.statusDescription;
					this.statusDetails = reg.organ;
					this.getTooltip();

					// after getting a registration, then we get the center and history
					this.unosService
						.getCenterById(reg.transplantCenterId)
						.subscribe(center => {
							this.transplantCenter = center.entireName;
						});
				});
			}
		});
	}

	redirectToPage(url: string) {
		this.router.navigate([url]);
	}

	generateGreeting(): string {
		return this.utilsService.generateGreeting();
	}

	getStatusImg() {
		if (this.active) {
			return './assets/icons/Active.svg';
		} else if (this.utilsService.findText(this.statusDescription, 'removed')) {
			return './assets/icons/Removed.svg';
		} else {
			return './assets/icons/Temporarily inactive.svg';
		}
	}

	getClass() {
		if (this.active) {
			return 'active';
		} else {
			return 'inactive';
		}
	}

	getTooltip() {
		let tooltip = '';
		if (this.active) {
			if (this.utilsService.findText(this.statusDescription, 'urgent')) {
				tooltip = 'myInfo.tooltip.urgent';
			} else {
				tooltip = 'myInfo.tooltip.noUrgent';
			}
		} else {
			if (this.utilsService.findText(this.statusDescription, 'removed')) {
				tooltip = 'myInfo.tooltip.removed';
			} else {
				tooltip = 'myInfo.tooltip.temporarily';
			}
		}
		this.tooltipMessage = this.translator.instant(tooltip);

		setTimeout(() => {
			// Minimum waiting time before activating tooltip and it can be activated correctly with its dynamic text
			this.utilsService.enableTooltip();
		}, 200);
	}

	getSteps() {
		return [
			{
				step: 1,
				title: 'learn.consideringTransplant',
				isCurrent: false,
				isComplete: true,
				isInactive: false,
				details: {},
			},
			{
				step: 2,
				title: 'learn.choosingHospital',
				isCurrent: false,
				isComplete: true,
				isInactive: false,
				details: {},
			},
			{
				step: 3,
				title: 'learn.gettingEvaluated',
				isCurrent: false,
				isComplete: true,
				isInactive: false,
				details: {},
			},
			{
				step: 4,
				title: 'learn.waitingOrgan',
				isCurrent: true,
				isComplete: false,
				isInactive: false,
				details: {
					image: './assets/images/Transplant journey illustrations/Waiting.svg',
					textButton: 'learn.accessResourcesWhileWaiting',
				},
			},
			{
				step: 5,
				title: 'learn.consideringDonor',
				isCurrent: false,
				isComplete: false,
				isInactive: true,
				details: {},
			},
			{
				step: 6,
				title: 'learn.acceptingOffer',
				isCurrent: false,
				isComplete: false,
				isInactive: true,
				details: {},
			},
			{
				step: 7,
				title: 'learn.navigatingLife',
				isCurrent: false,
				isComplete: false,
				isInactive: true,
				details: {},
			},
		];
	}
	getLastUpdatedLabel() {
		return this.translator.instant('lastUpdated', { date: this.lastUpdate });
	}
}
