export const STEPS = [
	{
		id: '1',
		title: 'onboarding.steps.1.title',
	},
	{
		id: '2',
		title: 'onboarding.steps.2.title',
		description: 'onboarding.steps.2.description',
	},
	{
		id: '3',
		title: 'onboarding.steps.3.title',
		description: 'onboarding.steps.3.description',
	},
	{
		id: '4',
		title: 'onboarding.steps.4.title',
		description: 'onboarding.steps.4.description',
	},
	{
		id: '5',
		title: 'onboarding.steps.5.title',
		description: 'onboarding.steps.5.description',
	},
	{
		id: '6',
		title: 'onboarding.steps.6.title',
		description: 'onboarding.steps.6.description',
	},
];

export const STEP_ONE_OPTIONS = [
	{
		title: 'onboarding.steps.1.options1.title1',
		description: 'onboarding.steps.1.options1.description1',
		value: 'patient',
	},
	{
		title: 'onboarding.steps.1.options1.title2',
		description: 'onboarding.steps.1.options1.description2',
		value: 'donor',
	},
	{
		title: 'onboarding.steps.1.options1.title3',
		description: 'onboarding.steps.1.options1.description3',
		value: 'caregiver',
	},
];

export const STEP_FOUR_OPTIONS = [
	{
		title: 'onboarding.steps.4.options1.title1',
		value: 'yes',
	},
	{
		title: 'onboarding.steps.4.options1.title2',
		value: 'no',
	},
];

export const STEP_FIVE_OPTIONS = [
	{
		title: 'onboarding.steps.5.options1.title',
		value: 'phone',
	},
	{
		title: 'onboarding.steps.5.options2.title',
		value: 'text',
	},
	{
		title: 'onboarding.steps.5.options3.title',
		value: 'email',
	},
	{
		title: 'onboarding.steps.5.options4.title',
		value: 'notification',
	},
];

export const STEP_SIX_OPTIONS = [
	{
		title: 'onboarding.steps.6.option1',
		value: 'mental-health',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option2',
		value: 'nutrition',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option3',
		value: 'transplant-center',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option4',
		value: 'finalcial-support',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option5',
		value: 'give-back',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option6',
		value: 'after-transplant',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option7',
		value: 'people-color-transplant',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option8',
		value: 'transportation-support',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option9',
		value: 'connect-people',
		selected: false,
		order: -1,
	},
	{
		title: 'onboarding.steps.6.option10',
		value: 'finding-donor',
		selected: false,
		order: -1,
	},
];
