<div class="history-card">
	<app-box>
		<div class="content" [class.wide]="wide">
			<div class="icon">
				<img [src]="img" [alt]="altText" />
			</div>
			<div class="info">
				<ng-content />
				<h4 *ngIf="!hasContent">{{ title | translate }}</h4>
				<div *ngIf="!hasContent" [innerHTML]="info"></div>
			</div>
		</div>
	</app-box>
	<div class="dropdown">
		<button
			class="btn btn-secondary dropdown-toggle"
			type="button"
			data-bs-toggle="dropdown"
			aria-expanded="false"
			[innerHTML]="getDropdonwTitle()"
		></button>
		<ul class="dropdown-menu">
			<li class="dropdown-item" [innerHTML]="getDropdownContent()"></li>
		</ul>
	</div>
</div>
