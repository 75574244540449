import {
	Component,
	Input,
	OnInit,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Component({
	selector: 'app-circular-step',
	templateUrl: './circular-step.component.html',
	styleUrls: ['./circular-step.component.scss'],
})

/**
 * Display Circular Step Component
 * @param {element} ng-content child elements that the component will contain
 * @param {boolean} isCurrent if the step is current, specific styles for this step will be shown
 * @param {boolean} isComplete if the step is complete, specific styles for this step will be shown
 * @param {boolean} isInactive if the step is inactive, specific styles for this step will be shown
 * @param {boolean} isBig if the step is big, specific styles for this step will be shown
 * @returns Circular Step Component
 */
export class CircularStepComponent implements OnInit, OnChanges {
	@Input() isCurrent = false;
	@Input() isComplete = false;
	@Input() isInactive = true;
	@Input() isBig = false;
	@Input() isSmall = false;
	@Input() showNumber = false;
	@Input() currentJourneyStep = false;
	public class = '';

	ngOnInit() {
		this.class = `step${this.getStatus()}${this.isBig ? ' large' : ' small'} ${
			this.isSmall && 'small-adjusment'
		}`;
	}

	ngOnChanges(changes: SimpleChanges): void {
		// If there is a change in the current element, change the class
		if (changes['isCurrent']) {
			if (changes['isCurrent'].currentValue) {
				this.class = `step current large`;
			} else {
				this.class = `step inactive large`;
			}
		}
	}

	getStatus(): string {
		let tempClass = '';
		if (this.isCurrent) {
			tempClass = ` current  ${this.isSmall && 'small-adjusment'}`;
		} else if (this.isComplete) {
			tempClass = ` complete  ${this.isSmall && 'small-adjusment'}`;
		} else if (this.isInactive) {
			tempClass = ` inactive  ${this.isSmall && 'small-adjusment'}`;
		}

		return tempClass;
	}
}
