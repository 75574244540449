<div class="container">
	<h1>{{ 'onboarding.steps.final.title' | translate }}</h1>
	<app-onboarding-card
		title="onboarding.steps.final.cardTitle"
	></app-onboarding-card>
</div>
<a
	href="#"
	type="button"
	class="btn btn-lg main-button base-button"
	(click)="navigateToWelcome()"
>
	{{ 'onboarding.steps.final.continue' | translate }}
</a>
<button
	type="button"
	class="btn btn-outline-primary btn-lg outline-button base-button"
>
	{{ 'onboarding.steps.final.takeGuide' | translate }}
</button>
