import {
	Component,
	ElementRef,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslatorService } from '@services/translator.service';
import { UnosService } from '@services/unos.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { getJourneyImg } from 'src/app/utils/constants';

@Component({
	selector: 'app-journey',
	templateUrl: './journey.component.html',
	styleUrls: ['./journey.component.scss'],
})
export class JourneyComponent implements OnInit {
	@ViewChild('itemList') itemList: ElementRef | undefined;
	public isElementVisible = false;
	currentJourney = -1;
	journeyStage = 0;
	threshold = 100;
	deviceInfo = {
		isMobile: false,
		isDesktop: true,
		isTablet: false,
		isLandscape: false,
	};

	steps = [
		{
			title: 'learn.consideringTransplant',
			quote: 'journey.quote1',
			textButton: 'journey.textButton1',
			imageURL: getJourneyImg('considering-transplant'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/considering-transplant'),
		},
		{
			title: 'learn.choosingHospital',
			quote: 'journey.quote2',
			textButton: 'journey.textButton2',
			imageURL: getJourneyImg('choosing-a-hospital'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/choosing-a-hospital'),
		},
		{
			title: 'learn.gettingEvaluated',
			quote: 'journey.quote3',
			textButton: 'journey.textButton3',
			imageURL: getJourneyImg('getting-evaluated'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/getting-evaluated'),
		},
		{
			title: 'learn.waitingOrgan',
			quote: 'journey.quote4',
			textButton: 'journey.textButton4',
			imageURL: getJourneyImg('waiting-for-an-organ-offer'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/waiting-for-an-organ-offer'),
		},
		{
			title: 'learn.consideringDonor',
			quote: 'journey.quote5',
			textButton: 'journey.textButton5',
			imageURL: getJourneyImg('considering-a-living-donor'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/considering-a-living-donor'),
		},
		{
			title: 'learn.acceptingOffer',
			quote: 'journey.quote6',
			textButton: 'journey.textButton6',
			imageURL: getJourneyImg('accepting-an-offer-having-surgery'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/accepting-an-offer-having-surgery'),
		},
		{
			title: 'learn.navigatingLife',
			quote: 'journey.quote7',
			textButton: 'journey.textButton7',
			imageURL: getJourneyImg('navigating-life-post-transplant'),
			isCompleted: false,
			redirectToPageProps: () =>
				this.redirectToPage('/learn/navigating-life-post-transplant'),
		},
	];

	constructor(
		private router: Router,
		private deviceService: DeviceDetectorService,
		private translator: TranslatorService,
		private unosService: UnosService,
	) {
		this.epicFunction();
		translator.addLanguageChangeAction(() => {
			this.getDescription();
		});
	}
	ngOnInit(): void {
		this.unosService.getClientInfo().subscribe(patient => {
			this.journeyStage = patient.journeyStage;
		});
	}

	epicFunction() {
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		/**
		 * the ngx-device-detector detecs an ipad as tablet and desktop so,
		 * we check if the device is tablet then is not a desktop device
		 * */

		const isDesktopDevice = this.deviceService.isDesktop() && !isTablet;
		const isLandscape = this.deviceService.orientation === 'landscape';

		this.deviceInfo = {
			isDesktop: isDesktopDevice,
			isMobile,
			isTablet,
			isLandscape,
		};
		if (!isLandscape && isTablet) {
			this.threshold = 200;
		}
	}
	redirectToPage(url: string) {
		this.router.navigate([url]);
	}

	getCurrent(step: number) {
		return this.currentJourney === step;
	}

	// If the element is in view of the user, show the circular step as active
	onHover(step: number) {
		if (!this.deviceInfo.isDesktop) {
			return;
		}

		this.currentJourney = step;
	}
	onScrollEnd() {
		const scrollTop =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop;
		const scrollHeight =
			document.documentElement.scrollHeight || document.body.scrollHeight;
		const windowHeight =
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight;

		return {
			scrollTop,
			isLastScroll: scrollTop + windowHeight >= scrollHeight,
		};
	}
	@HostListener('window:scroll', [])
	onScroll(): void {
		// if device isDesktop then does not listen the scroll event
		if (this.deviceInfo.isDesktop) {
			return;
		}

		const { isLastScroll, scrollTop } = this.onScrollEnd();
		// if device does not have more space to scroll then the last item is selected
		if (isLastScroll) {
			this.currentJourney = this.steps.length;
			return;
		}
		if (scrollTop < 100) {
			this.currentJourney = -1;
			return;
		}
		// else we listen for the item is located on the middle of the screen
		const elements = this.itemList?.nativeElement.children;
		if (elements) {
			const viewportHeight = window.innerHeight;
			const viewportMiddle = viewportHeight / 2;
			let closestDistance = Number.MAX_SAFE_INTEGER;
			let foundIndex = -1;

			for (let i = 0; i < elements.length; i++) {
				const rect = elements[i].getBoundingClientRect();
				const distance = Math.abs(rect.top + rect.height / 2 - viewportMiddle);
				if (distance < closestDistance) {
					closestDistance = distance;
					foundIndex = i;
				}
			}

			this.currentJourney = foundIndex + 1;
		}
	}
	getDescription() {
		return this.translator.get('journey.description');
	}
	getSteps() {
		return this.steps.map((items, step) => ({
			...items,
			isCompleted: step + 1 < this.currentJourney,
		}));
	}
}
