import { Component } from '@angular/core';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';

@Component({
	selector: 'app-complete',
	templateUrl: './complete.component.html',
	styleUrls: ['./complete.component.scss'],
})
export class CompleteComponent {
	constructor(private state: OnboardingService) {}
	navigateToWelcome() {
		this.state.setStep(SCREEN_NAME.WELCOME);
	}
}
