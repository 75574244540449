<ul class="nav nav-underline nav-justified">
	<div class="transplant"></div>
	<ng-container *ngFor="let route of routes; index as i">
		<li class="nav-item d-flex position-relative">
			<a
				class="nav-link nav-link-centered"
				[routerLink]="[route.routerLink]"
				routerLinkActive="active"
				href="{{ route.routerLink }}"
				>{{ route.title | translate }}
				<i class="bi bi-chevron-down chevron"></i>
			</a>
			<ul class="dropdown-menu">
				<ng-container *ngFor="let subroute of route.subRoutes; index as j">
					<li>
						<a
							class="dropdown-item"
							routerLinkActive="active"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="{{ subroute.routerLink }}"
							>{{ route.bulletsEnabled && j !== 0 ? j + '. ' : ''
							}}{{ subroute.title | translate }}</a
						>
					</li>
				</ng-container>
			</ul>
		</li>
	</ng-container>

	<li class="nav-item d-flex justify-content-center position-relative">
		<a
			class="nav-link nav-link-centered"
			[routerLink]="['/search']"
			routerLinkActive="active"
			href="/"
			>{{ 'search.title' | translate }}</a
		>
	</li>
</ul>
