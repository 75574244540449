<app-box>
	<div class="content" [class.active]="active">
		<div class="status" [class.active]="active">
			<img *ngIf="active" src="../../../assets/icons/Active.svg" alt="Active" />
			<img
				*ngIf="!active"
				src="../../../assets/icons/Temporarily inactive.svg"
				alt="Inactive"
			/>
		</div>
		<div class="info">
			<h4>{{ status | translate }}</h4>
			<span *ngIf="reason">{{ reason }}</span>
			<span>{{ lastUpdate }}</span>
		</div>
	</div>
</app-box>
