import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-card-with-title',
	templateUrl: './card-with-title.component.html',
	styleUrls: ['./card-with-title.component.css'],
})

/**
 * Display Button Component
 * @param {element} ng-content child elements that the component will contain
 * @param {string} title card title
 * @param {string} subTitle card sub title
 * @returns Card With Title Component
 */

export class CardWithTitleComponent {
	// Inputs
	@Input() title = 'Without title';
	@Input() subTitle = '';
}
