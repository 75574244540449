/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
declare let bootstrap: any;

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	private storageKey = 'unos';
	private tooltipList = new Array<any>();

	enableTooltip() {
		// Bootstrap tooltip initialization
		const tooltipTriggerList = [].slice.call(
			document.querySelectorAll('[data-bs-toggle="tooltip"]'),
		);

		const tooltipListNewTooltips = tooltipTriggerList.map(tooltipTriggerEl => {
			return new bootstrap.Tooltip(tooltipTriggerEl);
		});
		this.tooltipList.push(...tooltipListNewTooltips);
	}

	hideAllTooltips() {
		this.tooltipList;
		for (const tooltip of this.tooltipList) {
			tooltip.dispose();
		}
		this.tooltipList = new Array<any>();
	}

	generateGreeting(): string {
		const currentHour = moment().hour();
		let greeting = '';

		if (currentHour >= 5 && currentHour < 12) {
			greeting = 'goodMorning';
		} else if (currentHour >= 12 && currentHour < 18) {
			greeting = 'goodAfternoon';
		} else {
			greeting = 'goodEvening';
		}

		return greeting;
	}

	// get all data
	getAllData(): unknown[] {
		const dataString = localStorage.getItem(this.storageKey);
		if (dataString) {
			return JSON.parse(dataString);
		} else {
			return [];
		}
	}

	// Get a data by index
	getData(index: number): unknown {
		const data = this.getAllData();
		return data[index];
	}

	// Add a new data
	addData(item: unknown): void {
		const data = this.getAllData();
		data.push(item);
		localStorage.setItem(this.storageKey, JSON.stringify(data));
	}

	// Update an existing data by index
	updateData(index: number, newItem: unknown): void {
		const data = this.getAllData();
		if (index >= 0 && index < data.length) {
			data[index] = newItem;
			localStorage.setItem(this.storageKey, JSON.stringify(data));
		}
	}

	// Replace all data
	replaceAllData(data: unknown): void {
		localStorage.setItem(this.storageKey, JSON.stringify(data));
	}

	// Delete a data by index
	deleteData(index: number): void {
		const data = this.getAllData();
		if (index >= 0 && index < data.length) {
			data.splice(index, 1);
			localStorage.setItem(this.storageKey, JSON.stringify(data));
		}
	}

	onTriggerCall(phoneNumber: string): void {
		const link = document.createElement('a');
		link.href = `tel:${phoneNumber}`;
		link.click();
	}

	extractFirstName(fullName: string): string {
		const names = fullName.split(' ');
		const firstName = names[0];
		return firstName;
	}

	formatDate = (date: string = '', format: string = 'MMM Do YYYY') => {
		return moment(date).format(format);
	};

	formatTimestampToDate = (date: any, format = 'MMM Do YYYY') => {
		return moment.unix(date).format(format);
	};

	findText(text: string, fragment: string) {
		return text.toLowerCase().includes(fragment.toLowerCase());
	}

	getRandomArrayValue(array: number[]): number {
		const randomIndex = Math.floor(Math.random() * array.length);
		const randomValue = array[randomIndex];
		return randomValue;
	}

	/**
	 * Validates an email address using regular expression.
	 * @param email - The email address to be validated.
	 * @returns True if the email address is valid, otherwise false.
	 */
	validateEmail = (email: string) => {
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(email.toLowerCase());
	};

	/**
	 * Validates an password using regular expression.
	 * Details:
	 * - One capital letter
	 * - One number
	 * - Minimum 8 characters
	 * - Maximum 16 characters
	 * @param pass - The password to be validated.
	 * @returns True if the password is valid, otherwise false.
	 */
	validPassword = (pass: string) => {
		const passRegex = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
		return passRegex.test(pass);
	};
}
