import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

// Routings
import { MyInfoRoutingModule } from './screens/my-info/my-info.routing';
import { LearnRoutingModule } from './screens/learn/learn.routing';
import { ConnectRoutingModule } from './screens/connect/connect.routing';
import { SearchPageRoutingModule } from './screens/search-page/search-page.routing';

// Components
import { LearnComponent } from './screens/learn/learn.component';
import { ConnectComponent } from './screens/connect/connect.component';
import { AlertsComponent } from './screens/alerts/alerts.component';
import { OnboardingComponent } from './screens/onboarding/onboarding.component';
import { LoginComponent } from './screens/login/login.component';

// Routes
const routes: Routes = [
	{ path: '', redirectTo: 'my-info', pathMatch: 'full' },
	{ path: 'learn', component: LearnComponent },
	{ path: 'connect', component: ConnectComponent },
	{ path: 'alerts', component: AlertsComponent },
	{ path: 'onboarding', component: OnboardingComponent },
	{ path: 'login', component: LoginComponent },
];

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
		MyInfoRoutingModule,
		LearnRoutingModule,
		ConnectRoutingModule,
		SearchPageRoutingModule,
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
