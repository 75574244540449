import { Component } from '@angular/core';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';
import { TranslatorService } from '@services/translator.service';

@Component({
	selector: 'app-language',
	templateUrl: './language.component.html',
	styleUrls: ['./language.component.scss'],
})
export class LanguageComponent {
	language = {
		en: 'English',
		es: 'Spanish (Español)',
	};
	langSelected = '';

	constructor(
		public translator: TranslatorService,
		private state: OnboardingService,
	) {
		this.langSelected = this.translator.getCurrentLang();
	}

	getLabelLang(key: string) {
		return this.language[key as 'en' | 'es'] || '';
	}
	isSelectedLang(key: string) {
		return key === this.langSelected;
	}
	onChangeLang(key: string) {
		this.langSelected = key;
	}
	navigateToSignUp() {
		this.state.setStep(SCREEN_NAME.SIGN_UP);
		if (this.langSelected !== this.translator.getCurrentLang()) {
			setTimeout(() => {
				this.translator.changeLang();
			}, 300);
		}
	}
	navigateToWelcome() {
		this.state.setStep(SCREEN_NAME.WELCOME);
	}
}
