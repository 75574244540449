<!-- search.component.html -->
<div class="root-container">
	<div class="sheet">
		<div class="card search-left">
			<div class="search-bar">
				<app-search-box
					[query]="query"
					[showBackButton]="showBackButton()"
					(changeQuery)="onChangeQuery($event)"
					(pressSearchButton)="onSearch()"
					(pressBackButton)="onPressBack()"
				>
					<ul class="suggestion-container" *ngIf="suggestions.length > 0">
						<li *ngFor="let suggest of suggestions" class="suggestion-item">
							<div
								tabindex="0"
								(keydown)="onSelectSuggestion(suggest.item.content)"
								(click)="onSelectSuggestion(suggest.item.content)"
								[innerHTML]="formatText(suggest.item.content)"
							></div>
						</li></ul
				></app-search-box>
			</div>
			<!-- Result Section -->
			<div *ngIf="showResultsSection()" class="search-result">
				<h2 class="text-center mb-4 mt-4">
					{{ 'search.searchResults' | translate }}
				</h2>
				<!-- Results items -->
				<div class="d-flex flex-column" style="flex: 1">
					<ul class="search-result-list">
						<li
							class="search-result-item"
							*ngFor="let result of paginationService.getCurrentPageItems()"
						>
							<a href="{{ result.item.href }}">
								{{ result.item.title }}
							</a>
							<div
								*ngIf="isHTML(result.item.description)"
								[innerHTML]="result.item.description"
							></div>
							<p *ngIf="!isHTML(result.item.description)">
								{{ result.item.description }}
							</p>
						</li>
					</ul>

					<!-- Pagination Buttons -->
					<app-pagination
						[hasMoreItems]="paginationService.getMorePageItems().length > 0"
						[totalResults]="paginationService.totalResults"
						[currentPage]="paginationService.currentPage"
						[totalPages]="paginationService.totalPages"
						[paginationInfoLabel]="getPagePaginationLabel()"
						(pressFirstPage)="paginationService.goToFirstPage()"
						(pressPrevPage)="paginationService.prevPage()"
						(pressNextPage)="paginationService.nextPage()"
						(pressLastPage)="paginationService.goToLastPage()"
					></app-pagination>
				</div>
				<!-- Initial State - Empty-->
				<div class="empty-search-list" *ngIf="!pressedSearch">
					<p>{{ 'search.empty' | translate }}</p>
				</div>
				<!-- No search results -->
				<div class="empty-search-list" *ngIf="IsSearchEmpty()">
					<p>{{ 'search.noResults' | translate }}</p>
				</div>
			</div>
		</div>

		<!-- Recent Section -->
		<div *ngIf="showRecentSection()" class="search-recent">
			<div class="card">
				<h2 class="text-center mb-4">
					{{ 'search.recentSearches' | translate }}
				</h2>
				<ul *ngIf="getRecentSearch().length > 0" class="list-search-recent">
					<ng-container *ngFor="let item of getRecentSearch(); index as i">
						<li>
							<span class="recentSearches-item">{{
								formatRecentSearch(item)
							}}</span>
							<button class="btn btn-outline" (click)="setQuery(item)">
								{{ 'search.viewResults' | translate }}
							</button>
						</li>
					</ng-container>
					<button
						*ngIf="getShowButton()"
						(click)="onShowAll()"
						class="btn btn-secondary custom-button mt-4"
					>
						{{ 'search.viewMoreRecent' | translate }}
					</button>
				</ul>
				<div class="text-center" *ngIf="getRecentSearch().length === 0">
					<p>{{ 'search.recentEmpty' | translate }}</p>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
</div>
