/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Alert } from '@models/alert';
import { LocalStorageService } from './local-storage.service';
import * as _ from 'lodash';

const exampleAlerts: Alert[] = [
	{
		id: "01076c76-c0c1-412d-9c14-0c9ff6f64d2b",
		title: 'Test 1',
		body: '<p>Porta suspendisse taciti hac dapibus ridiculus libero hendrerit aenean erat.</p><p>Viverra, donec posuere taciti sociosqu et nascetur elementum maecenas in.</p>',
		read: false,
		date: 1692110941
	},
	{
		id: "2baeddff-4709-4b3b-9a04-1e9a14d52147",
		title: 'Test 2',
		body: '<p>Consectetur potenti gravida nostra viverra commodo integer elementum arcu mi.</p><p>Curabitur fusce maecenas taciti vestibulum conubia amet, torquent eu molestie.</p>',
		read: true,
		date: 1692046141
	},
	{
		id: "2453fb48-55d8-4560-a2d9-cf61eb522732",
		title: 'Test 3',
		body: '<p>Parturient nisl malesuada mus rhoncus litora habitasse nulla maecenas vitae.</p><p>Magnis penatibus dolor a sem justo platea eget, lacinia felis!</p>',
		read: false,
		date: 1692024541
	},
	{
		id: "d254b5d2-b973-4f90-aec0-02949e618759",
		title: 'Test 4',
		body: '<p>Nascetur conubia nisl sagittis tincidunt elementum molestie, torquent lacinia ante.</p><p>Euismod ultricies cum sem, quisque senectus platea donec nullam massa.</p>',
		read: false,
		date: 1691938141
	},
	{
		id: "52a47565-934c-4509-8748-7d02d150dad1",
		title: 'Test 5',
		body: '<p>Porta suspendisse taciti hac dapibus ridiculus libero hendrerit aenean erat.</p><p>Viverra, donec posuere taciti sociosqu et nascetur elementum maecenas in.</p>',
		read: false,
		date: 1691869741
	},
	{
		id: "b7353485-b1a1-4608-ae10-d4f55f0c51dc",
		title: 'Test 6',
		body: '<p>Consectetur potenti gravida nostra viverra commodo integer elementum arcu mi.</p><p>Curabitur fusce maecenas taciti vestibulum conubia amet, torquent eu molestie.</p>',
		read: true,
		date: 1691851741
	},
	{
		id: "cf40ee1b-1242-47d3-b280-43ab66be8ed5",
		title: 'Test 7',
		body: '<p>Parturient nisl malesuada mus rhoncus litora habitasse nulla maecenas vitae.</p><p>Magnis penatibus dolor a sem justo platea eget, lacinia felis!</p>',
		read: false,
		date: 1691768941
	},
	{
		id: "fea89fda-4d2e-4e28-b517-72a186ad765c",
		title: 'Test 8',
		body: '<p>Nascetur conubia nisl sagittis tincidunt elementum molestie, torquent lacinia ante.</p><p>Euismod ultricies cum sem, quisque senectus platea donec nullam massa.</p>',
		read: false,
		date: 1691761741
	},
	{
		id: "94202836-c1ac-47d2-850f-b92224a3ac02",
		title: 'Test 9',
		body: '<p>Porta suspendisse taciti hac dapibus ridiculus libero hendrerit aenean erat.</p><p>Viverra, donec posuere taciti sociosqu et nascetur elementum maecenas in.</p>',
		read: false,
		date: 1691693341
	},
	{
		id: "0403a635-ad39-497a-9f8e-b5bc63737ae3",
		title: 'Test 10',
		body: '',
		read: true,
		date: 1691678941
	},
	{
		id: "099ecf80-62a6-43ab-9a3c-3e089a22f095",
		title: 'Test 11',
		body: '<p>Parturient nisl malesuada mus rhoncus litora habitasse nulla maecenas vitae.</p><p>Magnis penatibus dolor a sem justo platea eget, lacinia felis!</p>',
		read: false,
		date: 1691592541
	},
	{
		id: "7c0825c6-c017-40d5-9b1f-dedd4d2479af",
		title: 'Test 12',
		body: '<p>Nascetur conubia nisl sagittis tincidunt elementum molestie, torquent lacinia ante.</p><p>Euismod ultricies cum sem, quisque senectus platea donec nullam massa.</p>',
		read: false,
		date: 1691588941
	},
	{
		id: "cddda6b1-0ba5-4df1-8403-186adf370969",
		title: 'Test 13',
		body: '<p>Porta suspendisse taciti hac dapibus ridiculus libero hendrerit aenean erat.</p><p>Viverra, donec posuere taciti sociosqu et nascetur elementum maecenas in.</p>',
		read: false,
		date: 1691516941
	},
	{
		id: "f967a649-5a19-49fd-9a42-791f570a9e38",
		title: 'Test 14',
		body: '<p>Consectetur potenti gravida nostra viverra commodo integer elementum arcu mi.</p><p>Curabitur fusce maecenas taciti vestibulum conubia amet, torquent eu molestie.</p>',
		read: true,
		date: 1691516941
	},
	{
		id: "2bd56b7d-e486-44a0-a29b-cecc3a4c4fcc",
		title: 'Test 15',
		body: '<p>Parturient nisl malesuada mus rhoncus litora habitasse nulla maecenas vitae.</p><p>Magnis penatibus dolor a sem justo platea eget, lacinia felis!</p>',
		read: false,
		date: 1691502541
	},
];

@Injectable({
	providedIn: 'root',
})

export class AlertsService {
	private lastUpdated = new Date().getTime();
	private persistenAlerts: Alert[] = [];
	private alertsAmount = new BehaviorSubject<number | null>(0);

	constructor(
		private localStorageService: LocalStorageService,
	) {
		this.alertsAmount.next(Number(this.localStorageService.get('alertsAmount')));
		window.addEventListener('storage', (event: any) => {
      if (event.key === 'alertsAmount') {
        this.alertsAmount.next(event.newValue);
      }
    });

		let lastUpdate: any = this.localStorageService.get('lastAlertsUpdated');
		if (lastUpdate === null) {
			lastUpdate = new Date().getTime();
		}

		this.lastUpdated = Number(lastUpdate);
	}

	getAlertsAmount(): Observable<number | null> {
    return this.alertsAmount.asObservable();
  }

	private checkLastUpdate() {
		const currentTime = new Date().getTime();
		const oneHourInMillis = 60 * 60 * 1000;
		const shouldFetchData = currentTime - this.lastUpdated > oneHourInMillis;

		if (shouldFetchData) {
			this.lastUpdated = currentTime;
		}

		this.localStorageService.set('lastAlertsUpdated', this.lastUpdated);

		return shouldFetchData;
	}

	public async fetchAlerts() {
		const shouldUpdate = this.checkLastUpdate();
		// Check if it can fetch and persistent data has elements =>
		// it uses the persistent alerts data
		const persistentData = this.localStorageService.get('persistenAlerts');

		if (
			!shouldUpdate &&
			persistentData &&
			persistentData.length > 0
		) {
			this.buildIndex(persistentData, true);
			return;
		}
		// Otherwise it gets data from endpoint
		// TODO: Integrate Endpoint or service to get alerts
		this.buildIndex([...exampleAlerts]);
	}

	private buildIndex(alerts: Alert[], isPersistent = false) {
		let data = [...alerts];
		if (!isPersistent) {
			data = [...this.persistenAlerts, ...alerts];
		}
		this.persistenAlerts = data;
		const alertsFilter = _.filter(data, (item: Alert) => !item.read);
		this.localStorageService.set('alertsAmount', alertsFilter.length || 0);
		this.localStorageService.set('persistenAlerts', data);
		this.alertsAmount.next(Number(this.localStorageService.get('alertsAmount')));
	}

	getAlerts() {
		return this.persistenAlerts;
	}

	markRead(id: string) {
		const index = this.persistenAlerts.findIndex(item => item.id === id);
		if (index >= 0) {
			this.persistenAlerts[index].read = true
		}
		this.buildIndex([...this.persistenAlerts], true);
	}
	removeAlert(alert: Alert) {
		const updatedValues = [...this.persistenAlerts].filter(item => item.id !== alert.id)
		this.buildIndex(updatedValues, true);
	}

	restoreAll() {
		this.buildIndex([...exampleAlerts]);
	}

	deleteAll() {
		this.buildIndex([], true);
	}
}