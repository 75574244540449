import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-details-step-grid',
	templateUrl: './details-step-grid.component.html',
	styleUrls: ['./details-step-grid.component.scss'],
})
export class DetailsStepGridComponent implements OnInit {
	// Inputs Step
	@Input() title = '';
	@Input() quote = '';
	@Input() imageUrl = '';
	@Input() imageAlt = '';
	@Input() textButton = '';
	@Input() hrefButton = '';
	@Input() step = 1;
	@Input() currentJourneyStep = false;
	@Input() showNumber = true;
	@Output() pressButton = new EventEmitter();

	// Inputs Circular Step
	@Input() isCurrent = false;
	@Input() isComplete = false;
	@Input() isInactive = true;
	@Input() isBig = false;
	@Input() isLast = false;
	@Input() disabledSeparator = false;
	class = '';
	ngOnInit(): void {
		if (this.disabledSeparator) {
			this.class += '';
		} else {
			this.class += ' circle';
		}
	}
	handlePressButton() {
		this.pressButton.emit();
	}
}
