import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { LearnQnA } from '@models/learn';
import { ConnectPost } from '@models/connect';

@Injectable({
	providedIn: 'root',
})
export class CmsService {
	constructor(private http: HttpClient, private auth: AuthService) {}

	getPosts(): Observable<LearnQnA[]> {
		return this.http.get<LearnQnA[]>(
			`${environment.cmsBaseUrl}/learn?acf_format=standard&_fields=title,acf,type,status&per_page=100&orderby=date&order=asc`,
			{
				headers: this.auth.getCmsAuth(),
			},
		);
	}

	getConnectPosts(): Observable<ConnectPost[]> {
		return this.http.get<ConnectPost[]>(
			`${environment.cmsBaseUrl}/connect?acf_format=standard&_fields=title,acf,type,status&per_page=100&orderby=date&order=asc`,
			{
				headers: this.auth.getCmsAuth(),
			},
		);
	}
}
