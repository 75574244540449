// search-box.component.ts

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-search-box',
	templateUrl: './search-box.component.html',
	styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent {
	@Input() query = '';
	@Input() showBackButton = false;
	@Output() changeQuery = new EventEmitter<string>();
	@Output() pressSearchButton = new EventEmitter();
	@Output() pressBackButton = new EventEmitter();
	public showDropdown = false;
	onChangeInput() {
		this.changeQuery.emit(this.query);
	}
	handlePressSearchButton() {
		this.pressSearchButton.emit();
	}

	handleResetQuery() {
		this.query = '';
		this.onChangeInput();
	}
	onKeyUp(event: any) {
		if (event.keyCode === 13) {
			this.handlePressSearchButton();
		}
	}
	handleBackButton() {
		this.pressBackButton.emit();
	}
	handleOnBlur() {
		setTimeout(() => {
			this.showDropdown = false;
		}, 200);
	}
}
