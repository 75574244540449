/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';
import { formatSSNFromEvent } from 'src/app/ssn-format.directive';
import {
	STEPS,
	STEP_ONE_OPTIONS,
	STEP_FOUR_OPTIONS,
	STEP_FIVE_OPTIONS,
	STEP_SIX_OPTIONS,
} from 'src/app/utils/onboarding';

@Component({
	selector: 'app-steps',
	templateUrl: './steps.component.html',
	styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
	constructor(public state: OnboardingService) {}

	steps = STEPS;
	totalSteps = this.steps.length;
	currentStep = 1;
	stepOneOptions = STEP_ONE_OPTIONS;
	stepFourOptions = STEP_FOUR_OPTIONS;
	stepFiveOptions = STEP_FIVE_OPTIONS;
	stepSixOptions = STEP_SIX_OPTIONS;
	public myForm: FormGroup = new FormGroup({
		originalSSN: new FormControl('123-12-1234', [
			Validators.required,
			Validators.minLength(11),
		]),
		confirmSSN: new FormControl('123-12-1234', [
			Validators.required,
			Validators.minLength(11),
		]),
		dateOfBirth: new FormControl('', [Validators.required]),
		confirmDateOfBirth: new FormControl('', [Validators.required]),
		firstName: new FormControl('', [Validators.required]),
		transplantType: new FormControl('', [Validators.required]),
		reviewList: new FormControl('', [Validators.required]),
		phoneAlert: new FormControl('', []),
		textAlert: new FormControl('', []),
		emailAlert: new FormControl('', []),
		notificationAlert: new FormControl('', []),
	});
	interests = STEP_SIX_OPTIONS;
	selectedInterests: FormControl[] = [];
	availableOptions = STEP_SIX_OPTIONS;

	ngOnInit() {
		for (let i = 0; i < this.interests.length; i++) {
			this.selectedInterests.push(new FormControl(-1));
		}
	}

	nextStep() {
		if (this.validateIfButtonContinueIsDisabled()) {
			return;
		}
		if (this.currentStep === this.totalSteps) {
			this.state.setStep(SCREEN_NAME.FINAL);
			return;
		}
		this.currentStep = Math.min(this.totalSteps, this.currentStep + 1);
	}
	prevStep() {
		if (this.currentStep === 1) {
			this.state.setStep(SCREEN_NAME.SIGN_UP);
			return;
		}
		this.currentStep = Math.max(0, this.currentStep - 1);
	}
	getCurrentInfo() {
		return this.steps[this.currentStep - 1];
	}
	setFirstName(firstName: string) {
		this.myForm?.get('firstName')?.setValue(firstName);
	}
	onChangeTransplantType(value: string) {
		this.myForm?.get('transplantType')?.setValue(value);
	}
	isSelectedTransplantType(value: string) {
		const data = this.myForm?.get('transplantType')?.value;
		return data === value;
	}
	onChangeReviewList(value: string) {
		this.myForm?.get('reviewList')?.setValue(value);
	}
	isSelectedReviewList(value: string) {
		const data = this.myForm?.get('reviewList')?.value;
		return data === value;
	}
	onChangePhoneAlert(value: string) {
		const data = this.myForm?.get('phoneAlert')?.value;
		this.myForm?.get('phoneAlert')?.setValue(data === value ? '' : value);
	}
	isSelectedPhoneAlert(value: string) {
		const data = this.myForm?.get('phoneAlert')?.value;
		return data === value;
	}
	onChangTextAlert(value: string) {
		const data = this.myForm?.get('textAlert')?.value;
		this.myForm?.get('textAlert')?.setValue(data === value ? '' : value);
	}
	isSelectedTextAlert(value: string) {
		const data = this.myForm?.get('textAlert')?.value;
		return data === value;
	}
	onChangEmailAlert(value: string) {
		const data = this.myForm?.get('emailAlert')?.value;
		this.myForm?.get('emailAlert')?.setValue(data === value ? '' : value);
	}
	isSelectedEmailAlert(value: string) {
		const data = this.myForm?.get('emailAlert')?.value;
		return data === value;
	}
	onChangNotificationAlert(value: string) {
		const data = this.myForm?.get('notificationAlert')?.value;
		this.myForm
			?.get('notificationAlert')
			?.setValue(data === value ? '' : value);
	}
	isSelectedNotificationAlert(value: string) {
		const data = this.myForm?.get('notificationAlert')?.value;
		return data === value;
	}
	selectAllStep5() {
		this.myForm?.get('phoneAlert')?.setValue(this.stepFiveOptions[0].value);
		this.myForm?.get('textAlert')?.setValue(this.stepFiveOptions[1].value);
		this.myForm?.get('emailAlert')?.setValue(this.stepFiveOptions[2].value);
		this.myForm
			?.get('notificationAlert')
			?.setValue(this.stepFiveOptions[3].value);
	}
	pressCancel() {
		this.state.setStep(SCREEN_NAME.WELCOME);
	}
	validateSNN() {
		const _SSNValue = this.myForm?.get('originalSSN');
		const _confirmSSN = this.myForm?.get('confirmSSN');
		if (_confirmSSN?.value === '') {
			return null;
		}
		return _SSNValue?.value === _confirmSSN?.value;
	}
	validateDateBirth() {
		const _dateOfBirth = this.myForm?.get('dateOfBirth');
		const _confirmDateOfBirth = this.myForm?.get('confirmDateOfBirth');
		if (_confirmDateOfBirth?.value === '') {
			return null;
		}
		return _dateOfBirth?.value === _confirmDateOfBirth?.value;
	}
	formatSSN(event: any, field = 'originalSSN', mocked = false) {
		this.myForm
			?.get(field)
			?.setValue(mocked ? '123-12-1234' : formatSSNFromEvent(event));
	}
	validateIfButtonContinueIsDisabled() {
		if (this.currentStep === 1) {
			const _firstName = this.myForm?.get('firstName');
			const _transplantType = this.myForm?.get('transplantType');
			return !(_firstName?.valid && _transplantType?.valid);
		}

		if (this.currentStep === 2) {
			const _SSNValue = this.myForm?.get('originalSSN');
			const _confirmSSN = this.myForm?.get('confirmSSN');
			const _dateOfBirth = this.myForm?.get('dateOfBirth');
			const _confirmDateOfBirth = this.myForm?.get('confirmDateOfBirth');
			if (
				!_SSNValue?.valid ||
				!_confirmSSN?.valid ||
				!_dateOfBirth?.valid ||
				!_confirmDateOfBirth?.valid
			) {
				return true;
			}
			const SNNIsEqual = _SSNValue.value === _confirmSSN.value;
			const dateIsEqual = _dateOfBirth.value === _confirmDateOfBirth.value;

			return !(SNNIsEqual && dateIsEqual);
		}
		return false;
	}
	onChangeInterest(event: Event, index: number) {
		const selectedValue = (event.target as HTMLSelectElement).value;
		this.selectedInterests[index].setValue(selectedValue);
		const updatedSelectedInterest = this.selectedInterests.map(
			item => item.value,
		);
		this.availableOptions = this.availableOptions.map(item => {
			const itemIsSelected = updatedSelectedInterest.findIndex(
				updatedItem => updatedItem === item.value,
			);
			return { ...item, selected: itemIsSelected !== -1 };
		});
	}
	getLabeByPlace(index: number) {
		const prefix = 'onboarding.steps.6.';

		if (index === 0) {
			return prefix + 'first';
		}
		if (index === 1) {
			return prefix + 'second';
		}
		return prefix + 'third';
	}
}
