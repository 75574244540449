<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="root-container">
<xng-breadcrumb separator=" ">
	<ng-container *xngBreadcrumbItem="let breadcrumb">
		<ng-container>{{ breadcrumb | translate }}</ng-container>
	</ng-container>
</xng-breadcrumb>

<div class="container-fluid" role="main">
	<div class="row">
		<div class="col remove-padding">
			<!-- My Medical History -->
			<div class="row justify-content-start content">
				<div class="col text-center">
					<h2 class="title">{{ 'myInfo.myMedicalHistory' | translate }}</h2>
					<span class="subTitle">{{ getLastUpdatedLabel() }}</span>
				</div>
			</div>

			<hr />

			<br />

			<div class="row">
				<app-history-card
					img="../../../assets/icons/Medical history_Kidney.svg"
					altText="Transplant"
					dropdownTitle="myMedicalHistory.dropdownTitle1"
					dropdownContent="myMedicalHistory.dropdownContent1"
				>
					<h3>{{ 'myMedicalHistory.iAmACandidateFor' | translate }}</h3>
					<span>{{
						'myMedicalHistory.transplant'
							| translate : { organ: registration?.organ || 'Kidney' }
					}}</span>
				</app-history-card>
			</div>

			<br />

			<div class="row">
				<app-history-card
					[wide]="true"
					img="../../../assets/icons/Medical history_Hospital.svg"
					altText="Hospital"
					dropdownTitle="myMedicalHistory.dropdownTitle2"
					dropdownContent="myMedicalHistory.dropdownContent2"
				>
					<h4>{{ 'myMedicalHistory.waitList' | translate }}</h4>
					<div class="hospital-info">
						<span >{{
							center?.entireName || 'transplantCenter' | translate
						}}</span>
						<h4 (click)="onTriggerCall(center?.mainPhone)">
							<img
								src="../../../../assets/icons/phone-icon.svg"
								alt="Hospital Phone"
							/>
							{{ center?.mainPhone }}
						</h4>
					</div>
				</app-history-card>
			</div>

			<br />

			<div class="row">
				<app-history-card
					img="../../../assets/icons/Medical history_blood type.svg"
					altText="Blood type"
					title="myMedicalHistory.title3"
					info="<span class='subtitle'>{{ patient?.bloodType }}</span>"
					dropdownTitle="myMedicalHistory.dropdownTitle3"
					dropdownContent="myMedicalHistory.dropdownContent3"
				/>
			</div>

			<br />

			<div class="row">
				<app-history-card
					img="../../../assets/icons/Medical history_Height.svg"
					altText="BMI"
					dropdownTitle="myMedicalHistory.dropdownTitle4"
					dropdownContent="myMedicalHistory.dropdownContent4"
				>
					<div class="bmi-info">
						<div class="row">
							<h4>{{ 'height' | translate }}</h4>
							<span>{{ cmToFnI(registration?.height) }}</span>
						</div>

						<div class="row">
							<h4>{{ 'weight' | translate }}</h4>
							<span>{{ registration?.weight }} lbs</span>
						</div>

						<div style="margin-top: 6px;">
							<h4>
								{{ 'BMI' | translate }}
								<button
									class="button-tooltip"
									aria-label="Info Button"
									data-bs-html="true"
									data-bs-placement="top"
									data-bs-toggle="tooltip"
									data-bs-title="<a href='https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/index.html' target='_blank'><b>BMI</b> (Body Mass Index)</a> is tool for estimating a person’s amount of body fat. BMI is not a measure of your health, but a BMI over 30 may increase your risk of complications  from a transplant."
									data-bs-custom-class="custom-tooltip"
								>
									<i class="bi bi-question-circle"></i>
								</button>
							</h4>
							<span>32</span>
						</div>
					</div>
				</app-history-card>
			</div>

			<br />

			<div class="row">
				<app-history-card
					img="../../../assets/icons/Medical history_Diabetes status.svg"
					altText="Diabetes"
					dropdownTitle="myMedicalHistory.dropdownTitle5"
					dropdownContent="myMedicalHistory.dropdownContent5"
				>
					<h4>Current diabetes status</h4>
					<span *ngIf="!patient?.hasDiabetes">{{
						'myMedicalHistory.noDiabetes' | translate
					}}</span>
					<span *ngIf="patient?.hasDiabetes">{{
						'myMedicalHistory.hasDiabetes' | translate
					}}</span>
				</app-history-card>
			</div>

			<br />

			<div class="row">
				<app-history-card
					[wide]="true"
					img="../../../assets/icons/Medical history_dialysis.svg"
					altText="Dialysis"
					dropdownTitle="myMedicalHistory.dropdownTitle6"
					dropdownContent="myMedicalHistory.dropdownContent6"
				>
					<h4>{{ 'myMedicalHistory.administeredDialysis' | translate }}</h4>
					<span *ngIf="patient?.onDialysis">{{ 'yes' | translate }}</span>
					<span *ngIf="!patient?.onDialysis">{{ 'no' | translate }}</span>
					<h4 *ngIf="patient?.onDialysis">
						{{ 'myMedicalHistory.dateOfInitiation' | translate }}
					</h4>
					<span *ngIf="patient?.onDialysis">{{
						patient?.dialysisStartDate
					}}</span>
				</app-history-card>
			</div>

			<br />
			<br />

			<!-- Nav Card -->
			<app-nav-card title="myInfo.title" [routes]="myInfoRoutes" />

			<br />
		</div>
	</div>
</div>
</div>