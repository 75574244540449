import { Component, OnInit } from '@angular/core';
import {
	OnboardingService,
	SCREEN_NAME,
} from '@services/states/onboardingState.service';

@Component({
	selector: 'app-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
	constructor(public state: OnboardingService) {}
	ngOnInit(): void {
		this.state.getDataFromStore();
	}
	isWelcomePage() {
		return this.state.step === SCREEN_NAME.WELCOME;
	}
	isLanguagePage() {
		return this.state.step === SCREEN_NAME.LANG;
	}
	isSignUpPage() {
		return this.state.step === SCREEN_NAME.SIGN_UP;
	}
	isStepPage() {
		return this.state.step === SCREEN_NAME.STEPS;
	}
	isFinalPage() {
		return this.state.step === SCREEN_NAME.FINAL;
	}
	reset() {
		this.state.setStep(SCREEN_NAME.WELCOME);
	}
}
