/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { TranslatorService } from '@services/translator.service';

let apiLoaded = false;

@Component({
	selector: 'app-qna-card',
	templateUrl: './qna-card.component.html',
	styleUrls: ['./qna-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

/**
 * Display QnA Card Component
 * @param {string} title component title
 * @param {object} acf object with information for the card (Info, video url, cta title, cta url)
 * @returns QnA Card Component
 */
export class QnaCardComponent implements OnInit {
	@Input() title = '';
	@Input() acf: any = {};
	@Input() key = '';
	@Input() accordionName = '';

	public videoUrl: any = null;
	public isYoutube = false;
	fieldsToTranslate = {
		title: 'title',
		description: 'description',
		cta_one: 'cta_one',
		cta_two: 'cta_two',
	};
	constructor(private translator: TranslatorService) {
		const keyToUpdate = Object.values(this.fieldsToTranslate);
		keyToUpdate.map(key => this.getTranslationByKey(key));
	}

	ngOnInit() {
		if (this.acf?.video_url) {
			const isYoutubeUrl = this.findText(this.acf.video_url, 'youtu');

			if (isYoutubeUrl) {
				const tempVideoID: any = this.extractVideoId(this.acf.video_url);
				this.isYoutube = true;
				this.videoUrl = tempVideoID;
			} else {
				this.videoUrl = this.acf.video_url;
			}
		}

		if (!apiLoaded) {
			// This code loads the IFrame Player API code asynchronously, according to the instructions at
			// https://developers.google.com/youtube/iframe_api_reference#Getting_Started
			const tag = document.createElement('script');
			tag.src = 'https://www.youtube.com/iframe_api';
			document.body.appendChild(tag);
			apiLoaded = true;
		}
	}

	extractVideoId(url: string) {
		const regex = /[?&]v=([^&#]*)|\/([^\/]+)$/;
		const match = url.match(regex);
		return match && (match[1] || match[2]);
	}

	findText(text: string, fragment: string) {
		return text.toLowerCase().includes(fragment.toLowerCase());
	}
	getTranslationByKey(key: string) {
		const currentLang = this.translator.getCurrentLang();
		return this.acf[`${key}_${currentLang}`] || this.acf[key] || '';
	}
}
