<div class="root-container">
<xng-breadcrumb separator=" ">
	<ng-container *xngBreadcrumbItem="let breadcrumb">
		<ng-container>{{ breadcrumb | translate }}</ng-container>
	</ng-container>
</xng-breadcrumb>

<div class="container-fluid" role="main">
	<div class="row">
		<div class="col text-center remove-padding">
			<h2 class="title">{{ 'myInfo.myTask' | translate }}</h2>

			<app-info-card>
				<span [innerHTML]="getDescription()"></span>
			</app-info-card>
		</div>
	</div>

	<br />

	<!-- Checklist -->
	<div class="row">
		<div class="col remove-padding">
			<app-task
				[index]="0"
				title="{{ getChecklist()[0].title | translate }}"
				description="{{ getChecklist()[0].description | translate }}"
			>
			</app-task>

			<br />

			<app-task
				[index]="1"
				title="{{ getChecklist()[1].title | translate }}"
				description="{{ getChecklist()[1].description | translate }}"
			>
			</app-task>

			<br />

			<app-task
				[index]="2"
				title="{{ getChecklist()[2].title | translate }}"
				description="{{ getChecklist()[2].description | translate }}"
			>
			</app-task>

			<br />

			<app-task
				[index]="3"
				title="{{ getChecklist()[3].title | translate }}"
				description="{{ getChecklist()[3].description | translate }}"
			>
			</app-task>

			<br />

			<app-task
				[index]="4"
				title="{{ getChecklist()[4].title | translate }}"
				description="{{ getChecklist()[4].description | translate }}"
			>
			</app-task>

			<br />
		</div>
	</div>

	<div class="row d-none">
		<div class="col text-center add-task">
			<img src="../../../../assets/icons/plus-circle.png" alt="Add Task" />
			<span>Add a task to checklist</span>
		</div>
	</div>

	<br />

	<!-- Nav Card -->
	<app-nav-card title="myInfo.title" [routes]="myInfoRoutes" />

	<br />
</div>
</div>