import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslatorService } from '../translator.service';

@Injectable({
	providedIn: 'root',
})
export class OnboardingService {
	userName = '';
	typeOfTransplant = '';
	step = SCREEN_NAME.WELCOME;

	constructor(
		private store: LocalStorageService,
		public translator: TranslatorService,
	) {}

	getDataFromStore() {
		this.userName = this.store.get(FIELDS.USER_NAME);
		this.typeOfTransplant = this.store.get(FIELDS.TYPE_OF_TRANSPLANT);
		this.step = this.store.get('screen') || SCREEN_NAME.WELCOME;
	}

	setStep(step: SCREEN_NAME) {
		this.step = step;
		this.store.set('screen', step);
	}

	getLangs() {
		return this.translator.getSupportLangs();
	}

	onSaveStepOne({
		typeOfTransplant,
		userName,
	}: {
		userName: string;
		typeOfTransplant: string;
	}) {
		this.store.set(FIELDS.USER_NAME, userName);
		this.store.set(FIELDS.TYPE_OF_TRANSPLANT, typeOfTransplant);
	}
}

export const enum SCREEN_NAME {
	WELCOME = 'WELCOME',
	LANG = 'LANG',
	SIGN_UP = 'SIGN_UP',
	STEPS = 'STEPS',
	FINAL = 'FINAL',
}
const enum FIELDS {
	USER_NAME = 'userName',
	TYPE_OF_TRANSPLANT = 'typeOfTransplant',
	STEPS = 'steps',
}
