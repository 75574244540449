/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[appSsnFormat]',
})
export class SsnFormatDirective {
	constructor(private el: ElementRef) {}

	@HostListener('input', ['$event'])
	onInput(event: any) {
		event.target.value = formatSSNFromEvent(event);
	}
}

export const formatSSNFromEvent = (event: any) => {
	let ssnValue = event?.target?.value;

	ssnValue = ssnValue.replace(/\D/g, '');

	if (ssnValue.length >= 3) {
		ssnValue = ssnValue.substring(0, 3) + '-' + ssnValue.substring(3);
	}
	if (ssnValue.length >= 6) {
		ssnValue = ssnValue.substring(0, 6) + '-' + ssnValue.substring(6, 10);
	}

	if (event?.inputType === 'deleteContentBackward' && ssnValue.endsWith('-')) {
		ssnValue = ssnValue.slice(0, -1);
	}

	return ssnValue;
};
