<div class="container-fluid">
	<div class="row">
		<div class="col-2 text-center reduce-left-padding">
			<app-circular-step
				[isCurrent]="isCurrent"
				[isComplete]="isComplete"
				[isInactive]="isInactive"
				[isSmall]="true"
			>
				{{ step }}
			</app-circular-step>
		</div>
		<div class="col title reduce-left-padding">
			{{ title | translate }}
		</div>
	</div>
	<div class="row" *ngIf="!isLast">
		<div class="col-2 text-center reduce-left-padding">
			<div class="vertical-hr" [class.complete]="isComplete"></div>
		</div>
	</div>
</div>
