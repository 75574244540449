import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-simple-step',
	templateUrl: './simple-step.component.html',
	styleUrls: ['./simple-step.component.css'],
})

/**
 * Display Simple Step Component
 * @param {string} title component title
 * @param {number} step step number
 * @param {boolean} isCurrent if the step is current, specific styles for this step will be shown
 * @param {boolean} isComplete if the step is complete, specific styles for this step will be shown
 * @param {boolean} isInactive if the step is inactive, specific styles for this step will be shown
 * @param {boolean} isBig if the step is big, specific styles for this step will be shown
 * @param {boolean} isLast if the step is the last, specific styles for this step will be shown
 * @returns Simple Step Component
 */

export class SimpleStepComponent {
	// Inputs Step
	@Input() title = '';
	@Input() step = 1;
	// Inputs Circular Step
	@Input() isCurrent = false;
	@Input() isComplete = false;
	@Input() isInactive = true;
	@Input() isBig = false;
	@Input() isLast = false;
}
