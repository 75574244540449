import { Component, OnDestroy } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { TranslatorService } from '@services/translator.service';
import { connectRoutes } from 'src/app/utils/constants';

@Component({
	selector: 'app-living-it-forward',
	templateUrl: './living-it-forward.component.html',
	styleUrls: ['./living-it-forward.component.scss'],
})
export class LivingItForwardComponent implements AfterViewInit, OnDestroy {
	public peerSupportRoutes = connectRoutes();

	public flocklerEmbed: any;
	private flocklerId = '188d98d37c10457be51855bcab56c234';

	constructor(private translator: TranslatorService) {
		translator.addLanguageChangeAction(() => {
			this.getDescription();
		});
	}
	ngAfterViewInit(): void {
		if (window.flcklr) {
			this.flocklerEmbed = window.flcklr.Embeds.create(
				window.flcklr.EmbedConfigs[this.flocklerId],
			);
		}
	}

	ngOnDestroy(): void {
		if (this.flocklerEmbed) {
			this.flocklerEmbed.destroy();
			this.flocklerEmbed = null;
		}
	}
	getDescription() {
		return this.translator.get('livingForward.description');
	}
}
