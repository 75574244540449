import { Component } from '@angular/core';

@Component({
	selector: 'app-box',
	templateUrl: './box.component.html',
	styleUrls: ['./box.component.scss'],
})

/**
 * Display Box Component
 * @param {element} ng-content child elements that the component will contain
 * @returns Box Component
 */

export class BoxComponent {}
