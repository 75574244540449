<app-card-with-title [title]="title">
	<!-- Routes -->
	<ng-container *ngFor="let item of routes; index as i">
		<div class="row" [ngClass]="{ 'mb-4': isLastElement(i) }">
			<div class="col">
				<a
					[routerLink]="item.link"
					routerLinkActive="active"
					[routerLinkActiveOptions]="{ exact: item.exact }"
					class="btn custom-button"
				>
					<div class="row">
						<div class="col-10">
							<span
								>{{ showNumbers && i !== 0 ? i + '. ' : ''
								}}{{ item?.alternativeTitle || item.title | translate }}</span
							>
						</div>
						<div class="col text-center">
							<img
								src="../../../assets/icons/chevron-right.png"
								[alt]="item.title"
							/>
						</div>
					</div>
				</a>
			</div>
		</div>

		<hr *ngIf="!isLastElement(i)" />
	</ng-container>
</app-card-with-title>
