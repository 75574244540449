/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.scss'],
	encapsulation: ViewEncapsulation.None,
})

/**
 * Display Task Component
 * @param {number} index help with the interaction and configuration of ADA
 * @param {string} title component title
 * @param {string} description component description
 * @returns Task Component
 */

export class TaskComponent implements OnInit {
	// Inputs Task
	@Input() index = 0;
	@Input() title = '';
	@Input() description = '';
	public isChecked: boolean = false;
	public isOpen: boolean = false;

	constructor(private utilsService: UtilsService) {}

	ngOnInit(): void {
		const tempData: any = this.utilsService.getAllData();
		const foundIndex = tempData.findIndex(
			(item: any) => item.task === this.title,
		);

		if (foundIndex !== -1) {
			this.isChecked = true;
		}
	}

	changeStatus() {
		const data: any = this.utilsService.getAllData();
		const foundIndex = data.findIndex((item: any) => item.task === this.title);
		this.isChecked = !this.isChecked;
		if (this.isChecked) {
			this.utilsService.addData({ task: this.title });
		} else {
			this.utilsService.deleteData(foundIndex);
		}
	}

	openTask() {
		this.isOpen = !this.isOpen;
	}
}
