<div
	[ngClass]="{
		complete: isComplete,
		remove: showNumber,
		'complete-hover': isCurrent,
		'current-step': currentJourneyStep
	}"
	[class]="class"
>
	<div *ngIf="isComplete && !showNumber">
		<i class="bi bi-check"></i>
	</div>
	<div *ngIf="!isComplete || showNumber">
		<ng-content></ng-content>
	</div>
</div>
