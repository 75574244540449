import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-status-card',
	templateUrl: './status-card.component.html',
	styleUrls: ['./status-card.component.css'],
})

/**
 * Display Simple Step Component
 * @param {boolean} active if active is true, display the specific styles for active and display the corresponding icon
 * @param {string} status name
 * @param {string} reason reason for status
 * @param {string} lastUpdate last update date
 * @returns Simple Step Component
 */

export class StatusCardComponent {
	@Input() active = false;
	@Input() status = '';
	@Input() reason = '';
	@Input() lastUpdate = '';
}
