import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-details-step',
	templateUrl: './details-step.component.html',
	styleUrls: ['./details-step.component.scss'],
})

/**
 * Display Details Step Component
 * @param {element} ng-content child elements that the component will contain
 * @param {string} title step title
 * @param {string} quote step quote
 * @param {number} step step number
 * @param {boolean} isCurrent if the step is current, specific styles for this step will be shown
 * @param {boolean} isComplete if the step is complete, specific styles for this step will be shown
 * @param {boolean} isInactive if the step is inactive, specific styles for this step will be shown
 * @param {boolean} isBig if the step is big, specific styles for this step will be shown
 * @param {boolean} isLast if the step is the last, specific styles for this step will be shown
 * @returns Details Step Component
 */
export class DetailsStepComponent implements OnInit {
	// Inputs Step
	@Input() title = '';
	@Input() quote = '';
	@Input() step = 1;
	// Inputs Circular Step
	@Input() isCurrent = false;
	@Input() isComplete = false;
	@Input() isInactive = true;
	@Input() isBig = false;
	@Input() isLast = false;
	@Input() disabledSeparator = false;
	@Input() currentJourneyStep = false;
	class = '';
	ngOnInit(): void {
		if (this.disabledSeparator) {
			this.class += '';
		} else {
			this.class += ' circle';
		}
	}
}
