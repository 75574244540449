<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<header class="fixed-header">
	<nav id="nav" class="header w-100">
		<div class="header-content">
			<div class="mental-info">
				<a routerLink="/my-info" style="text-decoration: none">
					<span class="title"
						>{{ 'transplant' | translate }}<br />{{
							'navigator' | translate
						}}</span
					>
				</a>
			</div>
			<div class="right-desktop">
				<div class="support-btn">
					<img
						class="d-block icon"
						src="../assets/icons/Navigation_Phone Support.svg"
						alt="Support Menu"
					/>
					<div class="indicator"></div>
					<ul class="dropdown-menu-support">
						<li class="nav-item">
							<span class="nav-link">{{
								'header.patienSupoort' | translate
							}}</span>
						</li>

						<li class="nav-item">
							<a
								href="tel:(888) 894-6361"
								type="button"
								disabled
								type="button"
								class="btn btn-light white-button"
								disabled
							>
								{{ 'call' | translate }} (888) 894-6361
							</a>
						</li>

						<li class="nav-item">
							<a
								href="mailto:patientservices@unos.org"
								type="button"
								class="btn btn-light white-button"
								disabled
							>
								{{ 'email' | translate }} patientservices@unos.org
							</a>
						</li>

						<li class="nav-item">
							<span class="nav-link mental-healt">{{
								'header.mentalhealt' | translate
							}}</span>
						</li>

						<li class="nav-item">
							<a
								href="tel:988"
								type="button"
								class="btn btn-light white-button"
								disabled
							>
								{{ 'header.callOrText' | translate }} 988
							</a>
						</li>
					</ul>
				</div>
				<div class="alert-container">
					<app-alert-bell [notifications]="notificationsAmount" />
				</div>

				<div
					class="nav-item dropdown dropdown-desktop"
					data-bs-toggle="dropdown"
					data-target=".profile-content"
					[class.active]="isProfileActive"
				>
					<a
						#dropdownDesktop
						class="nav-link profile-content"
						href="#"
						role="button"
						data-bs-toggle="dropdown"
						data-bs-target="#profileDropdown"
						aria-expanded="false"
						(click)="toggleProfile()"
					>
						<span class="profile-circle">{{ profileAvatar }}</span
						>{{ 'header.profile' | translate }}
					</a>
					<ul class="dropdown-menu" id="profileDropdown">
						<li>
							<a class="dropdown-item" href="#">{{
								'header.preferences' | translate
							}}</a>
						</li>
						<li>
							<a class="dropdown-item" href="#">{{
								'header.notifications' | translate
							}}</a>
						</li>
						<li>
							<a class="dropdown-item" href="#">{{
								'header.privacyPolicy' | translate
							}}</a>
						</li>
						<li>
							<a class="dropdown-item" href="#">{{
								'header.signInSecurity' | translate
							}}</a>
						</li>
						<li>
							<a class="dropdown-item" (click)="rotateFakeUser()">{{
								'header.rotateUser' | translate
							}}</a>
						</li>
						<li>
							<a class="dropdown-item" (click)="changeLang()">{{
								getTranslationLabel()
							}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
	<nav id="mobile-nav" class="navbar bg-body-tertiary fixed-top header">
		<div class="container-fluid header-content">
			<a routerLink="/my-info" style="text-decoration: none">
				<span class="title"
					>{{ 'transplant' | translate }}<br />{{
						'navigator' | translate
					}}</span
				>
			</a>

			<div class="rightActions">
				<button
					class="btn btn-outline-none custom-button"
					(click)="toggleSupportClass()"
					[class.active]="isSupportActive"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#support"
					aria-label="Toggle navigation"
				>
					<img
						class="d-block support-icon adjustment-icon"
						src="../../../assets/icons/Navigation_Phone Support.svg"
						alt="Support Menu"
					/>
				</button>
				<app-alert-bell [notifications]="notificationsAmount" title="" />

				<button
					class="btn btn-outline-none text-center custom-button"
					(click)="toggleMenuClass()"
					[class.active]="isMenuActive"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#menu"
					aria-label="Toggle navigation"
				>
					<img
						class="d-block menu-icon adjustment-icon"
						src="../../../assets/icons/Navigation_Menu.svg"
						alt="Menu"
					/>
				</button>
			</div>
		</div>

		<div
			class="offcanvas offcanvas-end menu"
			id="menu"
			aria-labelledby="aria-menu"
		>
			<div class="offcanvas-header" style="z-index: 400">
				<h5 class="offcanvas-title" id="menu-offcanvas-title">
					{{ 'menu' | translate }}
				</h5>

				<button
					class="btn btn-outline-none text-center close-button"
					(click)="toggleMenuClass()"
					type="button"
					data-bs-dismiss="offcanvas"
				>
					<i class="bi bi-x-lg d-block menu-icon"></i>
				</button>
			</div>

			<div class="offcanvas-body">
				<ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
					<hr />
					<li class="nav-item dropdown">
						<a
							class="nav-link dropdown-toggle"
							href="#"
							role="button"
							data-bs-toggle="dropdown"
							data-bs-target="#profileDropdown"
							aria-expanded="false"
						>
							<span class="profile-circle">{{ profileAvatar }}</span
							>{{ 'header.profile' | translate }}
						</a>
						<ul class="dropdown-menu" id="profileDropdown">
							<li>
								<a class="dropdown-item" href="#">{{
									'header.preferences' | translate
								}}</a>
							</li>
							<li>
								<a class="dropdown-item" href="#">{{
									'header.notifications' | translate
								}}</a>
							</li>
							<li>
								<a class="dropdown-item" href="#">{{
									'header.privacyPolicy' | translate
								}}</a>
							</li>
							<li>
								<a class="dropdown-item" href="#">{{
									'header.signInSecurity' | translate
								}}</a>
							</li>
							<li>
								<a class="dropdown-item" (click)="rotateFakeUser()">{{
									'header.rotateUser' | translate
								}}</a>
							</li>
							<li>
								<a class="dropdown-item" (click)="changeLang()">{{
									getTranslationLabel()
								}}</a>
							</li>
						</ul>
					</li>

					<hr />
					<ng-container *ngFor="let route of routes; index as i">
						<li class="nav-item dropdown">
							<a
								class="nav-link dropdown-toggle"
								href="#"
								role="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								{{ route.alternativeTitle || route.title | translate }}
							</a>
							<ul class="dropdown-menu">
								<ng-container
									*ngFor="let subrouteRoute of route.subRoutes; index as j"
								>
									<li>
										<a
											class="dropdown-item"
											[routerLink]="subrouteRoute.routerLink"
											data-bs-dismiss="offcanvas"
											>{{ route.bulletsEnabled && j !== 0 ? j + '. ' : ''
											}}{{ subrouteRoute.title | translate }}</a
										>
									</li>
								</ng-container>
							</ul>
						</li>
						<hr />
					</ng-container>
					<li>
						<a
							class="nav-link"
							href="/search"
							[routerLink]="['/search']"
							role="button"
							data-bs-dismiss="offcanvas"
						>
							{{ 'search.title' | translate }}
						</a>
					</li>
					<hr />
					<li class="nav-item">
						<a
							class="nav-link"
							href="#"
							(click)="logout()"
							data-bs-dismiss="offcanvas"
							>{{ 'logout' | translate }}</a
						>
					</li>
				</ul>
			</div>
		</div>

		<div
			class="offcanvas offcanvas-start menu"
			id="support"
			aria-labelledby="aria-support"
		>
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="support-offcanvas-title">
					{{ 'header.support' | translate }}
				</h5>

				<button
					class="btn btn-outline-none text-center close-button"
					(click)="toggleSupportClass()"
					type="button"
					data-bs-dismiss="offcanvas"
				>
					<i class="bi bi-x-lg d-block menu-icon"></i>
				</button>
			</div>

			<div class="offcanvas-body">
				<ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
					<hr />
					<li class="nav-item">
						<span class="nav-link">{{
							'header.patienSupoort' | translate
						}}</span>
					</li>

					<li class="nav-item">
						<span class="nav-link small">{{
							'header.callOrEmail' | translate
						}}</span>
					</li>

					<li class="nav-item">
						<a
							class="btn btn-light white-button py-4"
							href="tel:(888) 894-6361"
							type="button"
							disabled
						>
							{{ 'call' | translate }} (888) 894-6361
						</a>
					</li>

					<br />

					<li class="nav-item">
						<a
							type="button"
							class="btn btn-light white-button py-4"
							disabled
							href="mailto:patientservices@unos.org"
							disabled
						>
							{{ 'email' | translate }} patientservices@unos.org
						</a>
					</li>

					<br />

					<hr />

					<li class="nav-item">
						<span class="nav-link mental-healt">{{
							'header.mentalhealt' | translate
						}}</span>
					</li>

					<li class="nav-item">
						<a
							href="tel:988"
							type="button"
							class="btn btn-light white-button py-4"
							disabled
						>
							{{ 'call' | translate }} 988
						</a>
					</li>

					<br />

					<li class="nav-item">
						<a
							href="sms:988"
							type="button"
							class="btn btn-light white-button py-4"
							disabled
						>
							{{ 'text' | translate }} 988
						</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>

	<app-navbar></app-navbar>
</header>
