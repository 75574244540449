<div style="display: flex" class="box content">
	<div
		class="col text-center d-flex flex-column align-items-center flex-fill py-4"
	>
		<img *ngIf="headerImg" [src]="headerImg" [alt]="altText" />

		<img *ngIf="img" [src]="img" [alt]="altText" />

		<h1 *ngIf="title" class="mb-4">{{ title }}</h1>

		<div [innerHTML]="description" class="mb-4 flex-fill"></div>

		<!-- <br /> -->

		<div *ngIf="localVideoUrl" class="video-content mb-4">
			<vg-player *ngIf="!isYoutube">
				<video
					[vgMedia]="$any(media)"
					#media
					id="singleVideo"
					preload="auto"
					controls
					class="video"
				>
					<source [src]="localVideoUrl" type="video/mp4" />
				</video>
			</vg-player>

			<youtube-player
				*ngIf="isYoutube"
				[videoId]="localVideoUrl"
				[width]="290.4"
				[height]="161.41"
				class="video"
			></youtube-player>
		</div>

		<!-- <br /> -->

		<a
			*ngIf="cta_one"
			type="button"
			class="btn btn-secondary custom-button"
			[href]="cta_one_url"
			target="_blank"
			[ngClass]="{ 'mb-4': !!cta_two }"
		>
			{{ cta_one }}
		</a>

		<!-- <br *ngIf="cta_two" /> -->
		<!-- <br *ngIf="cta_two" /> -->

		<a
			*ngIf="cta_two"
			type="button"
			class="btn btn-secondary custom-button"
			[href]="cta_two_url"
			target="_blank"
		>
			{{ cta_two }}
		</a>

		<!-- <br /><br /> -->
	</div>
</div>
