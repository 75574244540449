import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	get(key: string) {
		try {
			const val = localStorage.getItem(key);
			return JSON.parse(val || '');
		} catch (error) {
			return null;
		}
	}
	set(key: string, value: any) {
		try {
			localStorage.setItem(key, JSON.stringify(value));
			return true;
		} catch (error) {
			return false;
		}
	}
}
