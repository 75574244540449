<div class="root-container">
	<xng-breadcrumb separator=" ">
		<ng-container *xngBreadcrumbItem="let breadcrumb">
			<ng-container>{{ breadcrumb | translate }}</ng-container>
		</ng-container>
	</xng-breadcrumb>
	<div class="container-fluid share-your-story grid-container" role="main">
		<div class="left-section">
			<div class="row">
				<div class="col remove-padding">
					<!-- Peer Support -->
					<div class="row justify-content-start content">
						<div class="col text-center">
							<h2 class="title">{{ 'connect.livingForward' | translate }}</h2>
						</div>
					</div>

					<app-info-card>
						<span [innerHTML]="getDescription()"></span>
					</app-info-card>
				</div>
			</div>
			<br />

			<div class="row">
				<div class="col remove-padding">
					<app-box>
						<img
							class="flockler-header"
							src="../../../../assets/images/LivingItForward_Logo.png"
							alt="#LivingItForward"
						/>

						<br />

						<div class="container-fluid" role="main">
							<div id="flockler-embed-188d98d37c10457be51855bcab56c234"></div>

							<br /><br />

							<div class="row">
								<div class="col">
									<a
										type="button"
										class="btn btn-secondary custom-button"
										href="https://livingitforward.org/stories/"
										target="_blank"
									>
										{{ 'livingForward.viewLivingForwardWall' | translate }}
									</a>
								</div>
							</div>
						</div>
					</app-box>
				</div>
			</div>

			<br /><br />
		</div>

		<!-- Nav Card -->
		<div class="right-section">
			<app-nav-card title="connect.title" [routes]="peerSupportRoutes" />
		</div>
	</div>
</div>
