import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-total-wait-time',
	templateUrl: './total-wait-time.component.html',
	styleUrls: ['./total-wait-time.component.scss'],
})

/**
 * Display Total Wait Time Component
 * @param {string} institute transplat center name
 * @param {number} years years on hold info
 * @param {number} months months on hold info
 * @param {number} days days on hold info
 * @returns Total Wait Time Component
 */

export class TotalWaitTimeComponent {
	@Input() institute = '';
	@Input() years = '';
	@Input() months = '';
	@Input() days = '';
}
