<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="progress-container">
	<button
		class="btn chevron-left-btn"
		aria-label="Back button"
		(click)="prevStep()"
	>
		<i class="bi bi-chevron-left"></i>
	</button>
	<div class="custom-progress">
		<app-progress-bar
			[maxValue]="totalSteps"
			[percentage]="currentStep"
			style="flex: 1"
		></app-progress-bar>
		<div class="step-container">
			<span class="step-label">
				{{
					'onboarding.steps.stepOf'
						| translate : { current: currentStep, total: totalSteps }
				}}
			</span>
		</div>
	</div>
</div>

<div class="step-card">
	<figure>
		<img alt="nurse" src="assets/images/nurse.svg" />
	</figure>
	<div class="content">
		<span class="title" [innerHTML]="getCurrentInfo().title | translate">
		</span>
		<span
			*ngIf="!!getCurrentInfo().description"
			class="description"
			[innerHTML]="getCurrentInfo().description! | translate"
		>
		</span>
	</div>
</div>
<form [formGroup]="myForm!">
	<div *ngIf="currentStep === 1">
		<label
			for="firstName"
			class="form-label label"
			[ngClass]="{ required: false }"
			>{{ 'onboarding.steps.1.inputName' | translate }}</label
		>
		<input
			type="text"
			class="form-control input"
			id="firstName"
			placeholder="{{ 'onboarding.steps.1.placeHolderInputName' | translate }}"
			formControlName="firstName"
			[ngClass]="{ invalid: false, }"
		/>

		<div class="separator"></div>
		<p
			class="question-section"
			[innerHTML]="'onboarding.steps.1.question1' | translate"
		></p>
		<div class="radio-toolbar">
			<ng-container *ngFor="let option of stepOneOptions">
				<input
					type="radio"
					id="{{ option.value }}"
					[checked]="isSelectedTransplantType(option.value)"
					value="{{ option.value }}"
					(change)="onChangeTransplantType(option.value)"
				/>
				<label for="{{ option.value }}">
					<div
						*ngIf="!isSelectedTransplantType(option.value)"
						class="dot"
					></div>
					<span
						*ngIf="isSelectedTransplantType(option.value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ option.title | translate }}</span>
						<span class="description">{{
							option.description | translate
						}}</span>
					</div> </label
				><br />
			</ng-container>
		</div>
	</div>

	<div *ngIf="currentStep === 2" class="container-step-2">
		<div class="item">
			<label
				for="originalSSN"
				class="form-label label"
				[ngClass]="{ required: true }"
				>{{ 'onboarding.steps.2.ssn' | translate }}</label
			>
			<input
				id="originalSSN"
				type="text"
				class="form-control input"
				minlength="11"
				placeholder="{{ '*********' | translate }}"
				formControlName="originalSSN"
				(input)="formatSSN($event, 'originalSSN', true)"
				appSsnFormat
			/>
			<p>###-##-####</p>
		</div>
		<div class="item">
			<label
				for="confirmSSN"
				class="form-label label"
				[ngClass]="{ required: true }"
				>{{ 'onboarding.steps.2.confirmSSN' | translate }}</label
			>
			<input
				id="confirmSSN"
				type="text"
				class="form-control input"
				minlength="11"
				placeholder="{{ '*********' | translate }}"
				formControlName="confirmSSN"
				(input)="formatSSN($event, 'confirmSSN', true)"
				appSsnFormat
			/>
			<div
				class="feedback"
				[ngClass]="{
					'valid-icon': validateSNN() === true,
					'invalid-icon': validateSNN() === false
				}"
			></div>

			<p>###-##-####</p>
		</div>
		<div class="separator separator-2"></div>

		<div class="item">
			<label
				for="confirmDateOfBirth"
				class="form-label label"
				[ngClass]="{ required: true }"
				>{{ 'onboarding.steps.2.confirmDate' | translate }}</label
			>
			<input
				id="confirmDateOfBirth"
				type="date"
				class="form-control input"
				formControlName="confirmDateOfBirth"
			/>
			<div
				class="feedback adjustment-date"
				[ngClass]="{
					'valid-icon': validateDateBirth() === true,
					'invalid-icon': validateDateBirth() === false
				}"
			></div>
			<p>MM/DD/YYYY</p>
		</div>
		<div class="item">
			<label
				for="dateOfBirth"
				class="form-label label"
				[ngClass]="{ required: true }"
				>{{ 'onboarding.steps.2.date' | translate }}</label
			>
			<input
				id="dateOfBirth"
				type="date"
				class="form-control input"
				formControlName="dateOfBirth"
				[ngClass]="{ invalid: false, }"
			/>

			<p>MM/DD/YYYY</p>
		</div>
	</div>

	<div *ngIf="currentStep === 3" class="container-step-3">
		<app-box>
			<div class="info-card">
				<div class="row">
					<span class="title">{{
						'onboarding.steps.3.info.name' | translate
					}}</span>
					<span class="info">Anna Smith</span>
				</div>

				<div class="row">
					<div class="col-2">
						<span class="title">{{
							'onboarding.steps.3.info.age' | translate
						}}</span>
						<span class="info">40</span>
					</div>

					<div class="col-10">
						<span class="title">{{
							'onboarding.steps.3.info.location' | translate
						}}</span>
						<span class="info">Washington, DC</span>
					</div>
				</div>

				<div class="row">
					<span class="title">{{
						'onboarding.steps.3.info.center' | translate
					}}</span>
					<span class="info"
						>Medstar Georgetown Transplant Institute (DCGU)</span
					>
				</div>

				<div class="row">
					<span class="title">{{
						'onboarding.steps.3.info.coordinator' | translate
					}}</span>
					<span class="info">Alex Chaimberlain</span>
				</div>
			</div>
		</app-box>

		<app-box>
			<div class="info-card">
				<div class="row">
					<div class="col">
						<span class="title">{{
							'onboarding.steps.3.info.organ' | translate
						}}</span>
						<span class="info">Kidney</span>
					</div>

					<div class="col">
						<span class="title">{{
							'onboarding.steps.3.info.status' | translate
						}}</span>
						<span class="info">Active (1)</span>
					</div>
				</div>

				<div class="row">
					<span class="title">{{
						'onboarding.steps.3.info.time' | translate
					}}</span>
					<span class="info">10 days, 3 hours</span>
				</div>
			</div>
		</app-box>
	</div>

	<div *ngIf="currentStep === 4">
		<div class="radio-toolbar">
			<ng-container *ngFor="let option of stepFourOptions">
				<input
					type="radio"
					id="{{ option.value }}"
					[checked]="isSelectedReviewList(option.value)"
					value="{{ option.value }}"
					(change)="onChangeReviewList(option.value)"
				/>
				<label for="{{ option.value }}">
					<div *ngIf="!isSelectedReviewList(option.value)" class="dot"></div>
					<span
						*ngIf="isSelectedReviewList(option.value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ option.title | translate }}</span>
					</div> </label
				><br />
			</ng-container>
		</div>
	</div>

	<div *ngIf="currentStep === 5">
		<div class="radio-toolbar">
			<div class="radio-toolbar">
				<input
					type="radio"
					id="{{ stepFiveOptions[0].value }}"
					[checked]="isSelectedPhoneAlert(stepFiveOptions[0].value)"
					value="{{ stepFiveOptions[0].value }}"
					(click)="onChangePhoneAlert(stepFiveOptions[0].value)"
				/>
				<label for="{{ stepFiveOptions[0].value }}">
					<div
						*ngIf="!isSelectedPhoneAlert(stepFiveOptions[0].value)"
						class="dot"
					></div>
					<span
						*ngIf="isSelectedPhoneAlert(stepFiveOptions[0].value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ stepFiveOptions[0].title | translate }}</span>
					</div>
				</label>

				<br />

				<input
					type="radio"
					id="{{ stepFiveOptions[1].value }}"
					[checked]="isSelectedTextAlert(stepFiveOptions[1].value)"
					value="{{ stepFiveOptions[1].value }}"
					(click)="onChangTextAlert(stepFiveOptions[1].value)"
				/>
				<label for="{{ stepFiveOptions[1].value }}">
					<div
						*ngIf="!isSelectedTextAlert(stepFiveOptions[1].value)"
						class="dot"
					></div>
					<span
						*ngIf="isSelectedTextAlert(stepFiveOptions[1].value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ stepFiveOptions[1].title | translate }}</span>
					</div>
				</label>

				<br />

				<input
					type="radio"
					id="{{ stepFiveOptions[2].value }}"
					[checked]="isSelectedEmailAlert(stepFiveOptions[2].value)"
					value="{{ stepFiveOptions[2].value }}"
					(click)="onChangEmailAlert(stepFiveOptions[2].value)"
				/>
				<label for="{{ stepFiveOptions[2].value }}">
					<div
						*ngIf="!isSelectedEmailAlert(stepFiveOptions[2].value)"
						class="dot"
					></div>
					<span
						*ngIf="isSelectedEmailAlert(stepFiveOptions[2].value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ stepFiveOptions[2].title | translate }}</span>
					</div>
				</label>

				<br />

				<input
					type="radio"
					id="{{ stepFiveOptions[3].value }}"
					[checked]="isSelectedNotificationAlert(stepFiveOptions[3].value)"
					value="{{ stepFiveOptions[3].value }}"
					(click)="onChangNotificationAlert(stepFiveOptions[3].value)"
				/>
				<label for="{{ stepFiveOptions[3].value }}">
					<div
						*ngIf="!isSelectedNotificationAlert(stepFiveOptions[3].value)"
						class="dot"
					></div>
					<span
						*ngIf="isSelectedNotificationAlert(stepFiveOptions[3].value)"
						class="bi bi-check icon"
					></span>
					<div class="radio-description">
						<span>{{ stepFiveOptions[3].title | translate }}</span>
					</div>
				</label>

				<br />
			</div>
		</div>

		<br />
	</div>
	<div *ngIf="currentStep === 6">
		<div class="radio-toolbar">
			<div
				class="selector-root"
				*ngFor="let selector of selectedInterests; let i = index"
			>
				<span class="prefix">{{ 'onboarding.steps.6.' + i | translate }}</span>
				<span *ngIf="selector.value != '-1'" class="bi bi-check sufix"></span>

				<select
					class="selector-container"
					[formControl]="selector"
					(change)="onChangeInterest($event, i)"
					[ngClass]="{ 'first-option': selector.value == '-1' }"
				>
					<option [value]="-1" selected>
						{{ 'select' | translate }}
					</option>
					<option
						*ngFor="let option of availableOptions"
						[value]="option.value"
						[disabled]="option.selected"
					>
						{{ option.title | translate }}
					</option>
				</select>
			</div>
		</div>
	</div>
</form>

<button
	*ngIf="currentStep !== 3"
	type="button"
	class="btn btn-lg main-button base-button slim-button"
	(click)="nextStep()"
	[disabled]="validateIfButtonContinueIsDisabled()"
>
	{{ 'continue' | translate }}
</button>

<button
	*ngIf="currentStep === 3"
	type="button"
	class="btn btn-lg main-button base-button slim-button"
	(click)="nextStep()"
	[disabled]="validateIfButtonContinueIsDisabled()"
>
	{{ 'onboarding.steps.3.button-primary' | translate }}
</button>

<button
	type="button"
	class="btn btn-text btn-lg text-button base-button slim-button"
	(click)="pressCancel()"
>
	{{ 'cancel' | translate }}
</button>
