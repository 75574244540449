<app-box>
	<div class="row justify-content-start content">
		<div class="col text-center">
			<h2 class="title">{{ title | translate }}</h2>
			<span *ngIf="subTitle" class="subTitle">{{ subTitle | translate }}</span>
		</div>
	</div>

	<hr />

	<div class="row justify-content-start">
		<div class="col text-center">
			<ng-content></ng-content>
		</div>
	</div>
</app-box>
