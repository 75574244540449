import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { PaginationService } from '@services/pagination.service';
import { UtilsService } from '@services/utils.service';
import { Alert } from '@models/alert';
import { AlertsService } from '@services/alerts.service';
import { TranslatorService } from '@services/translator.service';
import { alertsRoutes } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})

export class AlertsComponent implements OnInit{
	public globalRoutes = alertsRoutes();
	public alertObject: Alert = {
		title: '',
		body: '',
		read: false,
		date: 0,
		id: ""
	};
	public openDesktopAlert = false;
	filterType: "ALL" | "READ" | "UNREAD" = "ALL";

	constructor(
		public alertService: AlertsService,
		private utilsService: UtilsService,
		public paginationService: PaginationService<Alert>,
		private translator: TranslatorService
	) {
		this.paginationService.setItems([]);
		this.paginationService.setItemsPerPage(10);
	}

	ngOnInit(): void {
		this.alertService.fetchAlerts();
		const tempList = this.alertService.getAlerts();
		this.paginationService.setItems(tempList);
		const isDesktop = window.innerWidth > 768;

		if (tempList.length > 0 && isDesktop) {
			this.openAlert(tempList[0], isDesktop)
		}
	}

	sortByRead() {
		const tempList = this.alertService.getAlerts();
		this.paginationService.setItems(
			tempList.filter((item: Alert) => item.read),
		);
		this.filterType = 'READ'
	}

	sortByUnRead() {
		const tempList = this.alertService.getAlerts();
		this.paginationService.setItems(
			tempList.filter((item: Alert) => !item.read),
		);
		this.filterType = 'UNREAD'
	}

	sortByAll() {
		this.paginationService.setItems(this.alertService.getAlerts());
		this.filterType = 'ALL'
	}

	getItems() {
		return this.paginationService.getCurrentPageItems();
	}

	openAlert(item: Alert, isDektop = false) {
		this.alertObject = item;
		this.alertService.markRead(item.id);
		this.openDesktopAlert = isDektop;
		if (isDektop) {
			window.scroll({ 
				top: 0, 
				left: 0, 
				behavior: 'smooth' 
			});
		}
	}

	closeAlert() {
		this.openDesktopAlert = false;
	}

	formatDate(date: number) {
		return this.utilsService.formatTimestampToDate(date,'MM/DD/YY hh:mma [EST]')
	}

	getPagePaginationLabel() {
		return this.translator.get('pagination.page', {
			current: this.paginationService.currentPage,
			total: this.paginationService.totalPages,
		});
	}

	onRemoveAlert(alert:Alert) {
		this.alertService.removeAlert(alert);
		this.paginationService.setItems(this.alertService.getAlerts());
		this.openDesktopAlert = false;
	}

	showToolsButton() {
		return !environment.production
	}

	restoreAll() {
		this.alertService.restoreAll();
		const tempList = this.alertService.getAlerts();
		this.paginationService.setItems(tempList);
	}

	deleteAll() {
		this.paginationService.setItems([]);
		this.openDesktopAlert = false;
		this.alertService.deleteAll();
	}
}
